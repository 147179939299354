import React from 'react'

function Card_Analytics({num,image,name,langmiamed}) {
    return (
        <div className="card ">
            <div className="card-head">
                <h2>{num}</h2>
                <img src={image} />
            </div>
            <div className="card-progress">
                <div className={`desc_card ${langmiamed == 'ar' ? "textRight" : ""}`}>{name}</div>
            </div>
        </div>
    )
}

export default Card_Analytics