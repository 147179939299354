import React, { useEffect } from 'react'
import { loadGeneralStatistics } from '../../../Redux/Actions/ActionStatistics';
import { useSelector } from 'react-redux';
import Card_Analytics from './Card_Analytics';
import medRep from '../../../images//new side icon/people.png'
import prod from '../../../images/new side icon/cube.png'
import delegation from '../../../images/new side icon/profile-user.png'

function Page_General_Statistics({langmiamed,translate,dispatch}) {
    const generalStatic = useSelector(state => state.statisticss.generalStatic)
    useEffect(() => {
        dispatch(loadGeneralStatistics())
    }, []);
    return (
        <div className="analytics" dir={langmiamed == 'ar' ? "rtl" : "ltr"}>
            <Card_Analytics
                num={generalStatic.users_count}
                image={medRep}
                name={translate('total_user')}
                langmiamed={langmiamed}
            />
            <Card_Analytics
                num={generalStatic.marketing_tools_count}
                image={prod}
                name={translate('total_product')}
                langmiamed={langmiamed}
            />
            <Card_Analytics
                num={generalStatic.medicine_samples_count}
                image={prod}
                name={translate('total_files')}
                langmiamed={langmiamed}
            />
            <Card_Analytics
                num={generalStatic.medical_reps_count}
                image={delegation}
                name={translate('total_delegate')}
                langmiamed={langmiamed}
            />
        </div>
    )
}

export default Page_General_Statistics