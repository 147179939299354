import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { loadMedicalRep } from "../../Redux/Actions/ActionMedicalRep";
import { loadProduct } from "../../Redux/Actions/ActionProduct";
import { sendTargetOrders } from "../../Redux/Actions/ActionTargetOrders";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import InnerLoader from "../General/InnerLoader";
import Page_Header from "../General/Page_Header";
import "./Politics.css";
import "./targetOrders.css";
import { TargetModal } from "./targetOrdersModal";

import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import trash from "./Trash.svg";

function TargetOrders() {
  const [t, i18n] = useTranslation();
  let { dispatch, navigate } = AbstructVar();
  const [openInternet, setopenInternet] = useState(false);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("langmiamed"));
    dispatch(loadProduct(navigate, setopenInternet));
    dispatch(loadMedicalRep(navigate, setopenInternet));
  }, []);

  const { product, loadingproduct, medicalRep, loadingmedicalRep } =
    useSelectorCustom();

  const initialProductItem = [
    {
      med_rep_id: "",
      products: [
        {
          product_id: "",
          quantity: "",
        },
      ],
    },
  ];

  const [productItem, setProductItem] = useState(initialProductItem);

  const AddFields = () => {
    let newfield = {
      med_rep_id: "",
      products: [
        {
          product_id: "",
          quantity: "",
        },
      ],
    };
    setProductItem([...productItem, newfield]);
  };

  const AddProductFields = (index) => {
    let data = [...productItem];
    data[index].products.push({
      product_id: "",
      quantity: "",
    });
    setProductItem(data);
  };

  const handleFormChange = (index, subIndex, event) => {
    let data = [...productItem];
    const { name, value } = event.target;

    if (name === "med_rep_id") {
      data[index][name] = value;
    } else {
      data[index].products[subIndex][name] = value;
    }

    setProductItem(data);
    formik.setFieldValue("TargetProduct", data);
  };

  const handelRemove = (index) => {
    let RemoveData = [...productItem];
    RemoveData.splice(index, 1);
    setProductItem(RemoveData);
    formik.setFieldValue("TargetProduct", RemoveData);
  };

  const handelProductRemove = (index, subIndex) => {
    let RemoveData = [...productItem];
    RemoveData[index].products.splice(subIndex, 1);
    setProductItem(RemoveData);
    formik.setFieldValue("TargetProduct", RemoveData);
  };

  const handleFileChange = (event) => {
    formik.setFieldValue("file", event.currentTarget.files[0]);
  };

  const formik = useFormik({
    initialValues: {
      month: "",
      TargetProduct: initialProductItem,
      file: null,
    },
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      // formData.append("month", values.month);
      // values.TargetProduct.forEach((item, index) => {
      //   formData.append(`TargetProduct[${index}][med_rep_id]`, item.med_rep_id);
      //   item.products.forEach((product, subIndex) => {
      //     formData.append(
      //       `TargetProduct[${index}][products][${subIndex}][product_id]`,
      //       product.product_id
      //     );
      //     formData.append(
      //       `TargetProduct[${index}][products][${subIndex}][quantity]`,
      //       product.quantity
      //     );
      //   });
      // });
      if (values.file) {
        formData.append("file", values.file);
        formData.append("month", values.month);
      }
      else{
        formData.append("month", values.month);
        values.TargetProduct.forEach((item, index) => {
          formData.append(`TargetProduct[${index}][med_rep_id]`, item.med_rep_id);
          item.products.forEach((product, subIndex) => {
            formData.append(
              `TargetProduct[${index}][products][${subIndex}][product_id]`,
              product.product_id
            );
            formData.append(
              `TargetProduct[${index}][products][${subIndex}][quantity]`,
              product.quantity
            );
          });
        });
      }
      dispatch(sendTargetOrders(formData, setOpen, setLoad));
      resetForm();
      setProductItem(initialProductItem);
      formik.setFieldValue("month", ""); 
      formik.setFieldValue("file", null);

      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    },
  });

  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: (i + 1).toString(),
  }));

  const isRtl = localStorage.getItem("langmiamed") === "ar";

  return (
    <>
      {loadingmedicalRep || loadingproduct ? (
        <InnerLoader />
      ) : (
        <div className={isRtl ? "main-content_ar" : "main-content_en"}>
          <main>
            <Page_Header
              langmiamed={localStorage.getItem("langmiamed")}
              header={t("targetorders")}
              path={`${t("home")} / ${t("targetorders")}`}
            />
            <div className="page-content">
              <form
                style={{ width: "100%", direction: isRtl ? "rtl" : "ltr" }}
                onSubmit={formik.handleSubmit}
              >
                <h5 className="text-2xl mt-5 font-semibold mb-3">
                  {t("chooseMonth") + ":"}
                </h5>
                <Select
                  options={
                    monthOptions
                  }
                  placeholder={t("Select a month")}
                  value={monthOptions.find(option => option.value === formik.values.month) || ""}

                  className="font_family_arr"
                  onChange={(e) => formik.setFieldValue("month", e.value)}
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      color: "gray",
                      textAlign: isRtl ? "right" : "left",
                      fontFamily: "Open Sans, sans-serif",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      textAlign: isRtl ? "right" : "left",
                    }),
                    control: (provided) => ({
                      ...provided,
                      fontFamily: "Open Sans, sans-serif",
                    }),
                  }}
                />
                <h5 className="text-2xl mt-5 font-semibold mb-3">
                  {t("chooseFile") + ":"}
                </h5>
                <div className="input-group mb-3">
                  <input
                    name="file"
                    onChange={handleFileChange}
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                    ref={fileInputRef}
                  />
                </div>

                {/* or */}
                <div class="container mt-5">
                  <div class="row">
                    <div class="col-12">
                      <div class="hr-text">{t('or')}</div>
                    </div>
                  </div>
                </div>

                {productItem.map((input, index) => {
                  return (
                    <div key={index}>
                      <h5 className="text-2xl mt-5 font-semibold mb-3">
                        {t("chooseName") + " " + (index + 1) + ":"}
                      </h5>
                      <div
                        className="d-flex  justify-content-center align-items-center gap-2 "
                        style={{ flexDirection: "column" }}
                      >
                        <select
                          className="form-control"
                          value={input.med_rep_id}
                          onChange={(event) =>
                            handleFormChange(index, 0, event)
                          }
                          name="med_rep_id"
                          placeholder={t("names")}
                        >
                          <option value="">{t("names")}</option>
                          {medicalRep?.map((s) => {
                            return (
                              <option key={s.id} value={s.id}>
                                {s.name}
                              </option>
                            );
                          })}
                        </select>
                        <div
                          className="mt-2 d-flex justify-content-center align-items-center gap-2"
                          style={{ flexDirection: "column", width: "100%" }}
                        >
                          {input.products.map((productItem, subIndex) => (
                            <div
                              key={subIndex}
                              className="d-flex align-items-center gap-3 mt-2"
                              style={{ width: "inherit" }}
                            >
                              <select
                                className="form-control"
                                value={productItem.product_id}
                                onChange={(event) =>
                                  handleFormChange(index, subIndex, event)
                                }
                                name="product_id"
                                placeholder={t("selectProducts")}
                              >
                                <option value="">{t("selectProducts")}</option>
                                {Array.isArray(product) &&
                                  product.map((s) => {
                                    return (
                                      <option key={s.id} value={s.id}>
                                        {s.name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <input
                                type="text"
                                className="form-control"
                                name="quantity"
                                placeholder={t("selectQuantity")}
                                value={productItem.quantity}
                                onChange={(event) =>
                                  handleFormChange(index, subIndex, event)
                                }
                              />
                              {input.products.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handelProductRemove(index, subIndex)
                                  }
                                  className="btn btn-danger text-white"
                                >
                                  <img src={trash} alt="trash" />
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => AddProductFields(index)}
                            className="btn btn-[] text-white mt-3 mb-2"
                            style={{ background: "#5263A4" }}
                          >
                            +
                          </button>

                          {productItem.length > 1 && (
                            <button
                              type="button"
                              onClick={() => handelRemove(index)}
                              className="btn btn-danger text-white"
                            >
                              <img src={trash} alt="trash" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="mt-2 d-flex justify-content-center ">
                  <button
                    type="button"
                    onClick={AddFields}
                    className="btn  text-white"
                    style={{ background: "#5263A4" }}
                  >
                    {t("AddNew")}
                  </button>
                </div>
                <div className="my-2 text-center btn-holder">
                  <button
                    onClick={() => setOpen(true)}
                    type="button"
                    className="mt-2"
                  >
                    {t("Submit")}
                  </button>
                </div>
                <TargetModal
                  open={open}
                  setOpen={setOpen}
                  handleSubmit={formik.handleSubmit}
                  load={load}
                />
              </form>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default TargetOrders;

// import React, { useState, useEffect } from "react";
// import "./targetOrders.css";
// import { useTranslation } from "react-i18next";
// import Page_Header from "../General/Page_Header";
// import "./Politics.css";
// import Select from "react-select";
// import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
// import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
// import { loadProduct } from "../../Redux/Actions/ActionProduct";
// import { loadMedicalRep } from "../../Redux/Actions/ActionMedicalRep";
// import DatePicker from "react-datepicker";
// import InnerLoader from "../General/InnerLoader";
// import moment from "moment";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "react-datepicker/dist/react-datepicker.css";
// import { GoPlusCircle } from "react-icons/go";
// import { sendTargetOrders } from "../../Redux/Actions/ActionTargetOrders";
// import { FaArrowCircleUp, FaArrowCircleDown } from "react-icons/fa";
// import { TargetModal } from "./targetOrdersModal";
// import nofilterr from "../../images/icons/nofilter.png";
// import { toast } from "react-toastify";
// function TargetOrders() {
//   const [t, i18n] = useTranslation();
//   let { dispatch, navigate, langmiamed } = AbstructVar();
//   const selectStyles = {
//     placeholder: (provided) => ({
//       ...provided,
//       color: "gray",
//       textAlign: langmiamed === "ar" ? "right" : "left",
//       fontFamily: "Open Sans, sans-serif",
//     }),
//     menu: (provided) => ({
//       ...provided,
//       textAlign: langmiamed === "ar" ? "right" : "left",
//     }),
//     control: (provided) => ({
//       ...provided,
//       fontFamily: "Open Sans, sans-serif", // Set font family for the select component
//     }),
//   };

//   const [open, setOpen] = React.useState(false);
//   const monthOptions = Array.from({ length: 12 }, (_, i) => ({
//     value: i + 1,
//     label: (i + 1).toString(),
//   }));
//   // State Declarations
//   const [openInternet, setopenInternet] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [date, setDate] = useState("");
//   const [numVisibleColumns, setNumVisibleColumns] = useState(1);

//   const initalOrder = {
//     column: 1,
//     repId: "",
//     productId: "",
//     qty: "",
//   };
//   const [targetOrders, setTargetOrders] = useState([]);
//   const [productId, setProductId] = useState("");
//   const [load, setLoad] = useState(false);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [ps, setPs] = useState([]);

//   const [currentRepId, setCurrentRepId] = useState([]);
//   const [currentProductsId, setCurrentProductsId] = useState([]);

//   const handleChange = (option) => {
//     setSelectedOption(option);
//   };
//   const { product, loadingproduct, medicalRep, loadingmedicalRep } =
//     useSelectorCustom();

//   // useEffect hooks
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     i18n.changeLanguage(localStorage.getItem("langmiamed"));
//     dispatch(loadProduct(navigate, setopenInternet));
//     dispatch(loadMedicalRep(navigate, setopenInternet));
//   }, []);

//   useEffect(() => {
//     setProducts(
//       product.map((p) => {
//         return {
//           label: p.name,
//           value: p.id,
//         };
//       })
//     );
//   }, [product]);

//   // Function Declarations
//   const handleAddColumns = () => {
//     setNumVisibleColumns((prevNumVisibleColumns) => prevNumVisibleColumns + 1);
//     setTargetOrders((prevOrders) => [
//       ...prevOrders,
//       { productId: "", repId: "", qty: "", column: numVisibleColumns + 1 },
//     ]);
//   };
//   const [targetValues, setTargetValues] = useState({});

//   const handleSubmit = () => {
//     if (!selectedOption?.value) {
//       toast.error(t("Please Choose Date"));
//       return;
//     }
//     if (!targetOrders || targetOrders?.length === 0) {
//       toast.error(t("Please Choose Products"));
//       return;
//     }

//     const uploadedArray = targetOrders
//       .filter(
//         (option) =>
//           option.repId !== "" && option.qty !== "" && option.productId !== ""
//       )
//       .map(({ column, ...rest }) => rest);

//     const formData = new FormData();
//     formData.append("date", selectedOption?.value);
//     formData.append("targetOrders", JSON.stringify(uploadedArray));

//     dispatch(
//       sendTargetOrders(
//         formData,
//         setOpen,
//         setLoad,
//         setTargetOrders,
//         setSelectedOption,
//         setTargetValues
//       )
//     );
//   };

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({
//       top: document.documentElement.scrollHeight,
//       behavior: "smooth",
//     });
//   };
//   const [scrollUp, setScrollUp] = useState(true);

//   const scroll = () => {
//     if (scrollUp) {
//       scrollToTop();
//     } else {
//       scrollToBottom();
//     }
//     setScrollUp(!scrollUp);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const isAtTop = window.scrollY === 0;
//       const isAtBottom =
//         window.innerHeight + window.scrollY >=
//         document.documentElement.scrollHeight;

//       if (isAtTop) {
//         setScrollUp(false);
//       } else if (isAtBottom) {
//         setScrollUp(true);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   //
//   // https://miamed.peaklinkdemo.com/api/medical-reps

// // https://miamed.peaklinkdemo.com/api/products

//   return (
//     <>
//       {loadingmedicalRep || loadingproduct ? (
//         <InnerLoader />
//       ) : (
//         <div
//           className={
//             langmiamed === "ar" ? "main-content_ar" : "main-content_en"
//           }
//         >
//           <main>
//             <Page_Header
//               langmiamed={langmiamed}
//               header={t("targetorders")}
//               path={`${t("home")} / ${t("targetorders")}`}
//             />
//             <div className="page-content">
//               <div
//                 className="my-3 date-add-holder"
//                 data-toggle="tooltip"
//                 data-placement="top"
//                 title={t("Add new product")}
//               >
//                 <div style={{ display: "flex" }}>
//                   <GoPlusCircle
//                     style={{ cursor: "pointer", color: "#5263a4" }}
//                     size={24}
//                     onClick={handleAddColumns}
//                   />
//                 </div>

//                 <Select
//                   value={selectedOption}
//                   onChange={handleChange}
//                   options={monthOptions}
//                   placeholder={t("Select a month")}
//                   className="font_family_arr"
//                   styles={{
//                     placeholder: (provided) => ({
//                       ...provided,
//                       color: "gray",
//                       textAlign: langmiamed === "ar" ? "right" : "left",
//                       fontFamily: "Open Sans, sans-serif",
//                     }),
//                     menu: (provided) => ({
//                       ...provided,
//                       textAlign: langmiamed === "ar" ? "right" : "left",
//                     }),
//                     control: (provided) => ({
//                       ...provided,
//                       fontFamily: "Open Sans, sans-serif",
//                     }),
//                   }}
//                 />

//                 <div className="tooltip fade top" role="tooltip">
//                   <div className="tooltip-inner">Add Columns</div>
//                 </div>
//               </div>
//               <div
//                 className="table-container"
//                 style={{
//                   direction: langmiamed === "ar" ? "rtl" : "ltr",
//                   width: "1100px",
//                 }}
//               >
//                 <table
//                   className={`table table-bordered ${
//                     numVisibleColumns > 3 ? "table-overflow" : ""
//                   }`}
//                 >
//                   <thead>
//                     <tr>
//                       <th
//                         style={{
//                           width: "5%",
//                           fontFamily: "GE SS TWO !important",
//                           color: "gray",
//                           fontWeight: "1000",
//                           fontSize: "18px",
//                         }}
//                         className="font_family_arr"
//                       >
//                         <p className="d-flex">{t("Name")}</p>
//                       </th>
//                       {Array.from({ length: numVisibleColumns }, (_, i) => (
//                         <th className="column" key={i + 1}>
//                           <Select
//                             options={products}
//                             placeholder={t("Choose a product")}
//                             styles={selectStyles}
//                             // value={targetOrders.find(
//                             //   (order) =>
//                             //     order.column === numVisibleColumns && order.productId === productId
//                             // )}

//                             onChange={(selectedProduct) => {
//                               // check if productId is exsist
//                               const updatedOptions = products.filter(
//                                 (option) =>
//                                   option.value !== selectedProduct.value
//                               );
//                               setProducts(updatedOptions);

//                               setProductId(selectedProduct.value);

//                               setTargetOrders((prev) => {
//                                 const existingOrder = prev.find(
//                                   (order) =>
//                                     order.productId == selectedProduct.value &&
//                                     order.column == numVisibleColumns + 1
//                                 );

//                                 if (existingOrder) {
//                                   return prev.map((item) =>
//                                     item.productId === selectedProduct.value &&
//                                     item.column === numVisibleColumns
//                                       ? {
//                                           ...item,
//                                           productId: selectedProduct.value,
//                                         }
//                                       : item
//                                   );
//                                 } else {
//                                   return [
//                                     ...prev,
//                                     {
//                                       ...initalOrder,
//                                       productId: selectedProduct.value,
//                                     },
//                                   ];
//                                 }
//                               });

//                               setTargetOrders((prev) =>
//                                 prev.map((item) =>
//                                   item.column === numVisibleColumns
//                                     ? {
//                                         ...item,
//                                         productId: selectedProduct.value,
//                                       }
//                                     : item
//                                 )
//                               );
//                             }}
//                           />
//                         </th>
//                       ))}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {medicalRep.map((rep) => (
//                       <tr key={rep.id}>
//                         <td className="font-global">{rep.name}</td>
//                         {Array.from({ length: numVisibleColumns }, (_, i) => (
//                           <td key={i + 1}>
//                             <input
//                               type="number"
//                               className="form-control"
//                               value={
//                                 targetOrders?.find(
//                                   (order) =>
//                                     order.repId === rep.id &&
//                                     order.column === numVisibleColumns
//                                 )?.qty ?? ""
//                               }
//                               onChange={(e) => {
//                                 const { value } = e.target;

//                                 setTargetOrders((prev) => {
//                                   const existingOrder = prev?.find(
//                                     (order) =>
//                                       order.repId == rep.id &&
//                                       order.column == numVisibleColumns
//                                   );
//                                   if (existingOrder) {
//                                     return prev.map((item) =>
//                                       item.repId === rep.id
//                                         ? { ...item, qty: value }
//                                         : item
//                                     );
//                                   } else {
//                                     return [
//                                       ...(prev ?? []), // Ensure prev is an array
//                                       {
//                                         ...initalOrder,
//                                         productId: productId,
//                                         repId: rep.id,
//                                         qty: value,
//                                         column: numVisibleColumns,
//                                       },
//                                     ];
//                                   }
//                                 });
//                               }}
//                               placeholder={
//                                 langmiamed === "ar"
//                                   ? `${t("Enter")} ${t("target")} ${rep.name}`
//                                   : `${t("Enter the target of")} ${rep.name}`
//                               }
//                             />
//                           </td>
//                         ))}
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//               <div className="my-2 text-center btn-holder">
//                 <button onClick={() => setOpen(true)} className="mt-2">
//                   {t("Submit")}
//                 </button>
//               </div>
//             </div>
//           </main>
//           <button
//             className="scroll"
//             style={langmiamed === "ar" ? { left: "30px" } : { right: "20px" }}
//             onClick={scroll}
//           >
//             {scrollUp ? <FaArrowCircleUp /> : <FaArrowCircleDown />}
//           </button>
//           <TargetModal
//             open={open}
//             setOpen={setOpen}
//             handleSubmit={handleSubmit}
//             load={load}
//           ></TargetModal>
//         </div>
//       )}
//     </>
//   );
// }

// export default TargetOrders;
