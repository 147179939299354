import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  loadFilterMedicalRep,
  loadMedicalRep,
} from "../../Redux/Actions/ActionMedicalRep";
import nofilterr from "../../images/icons/nofilter.png";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { VariableDelegates } from "./VariableDelegates";
import { VariableTargetGroup } from "../TargetGroup/VariableTargetGroup";

function FilterDelegates({ setopenInternet, navigate, t, langmiamed }) {
  let dispatch = useDispatch();
  const { cities, loadingCities, governorates, loadinGovernorate } =
    useSelectorCustom();
  const { LoadCitiesCustom, LoadGovernoratesCustom } = FunctionsLoading();
  const { state, setState, handleChangeDelegates, setLoad } =
    VariableDelegates();
  const [isEmpty, setisEmpty] = useState(false);
  const filter = (event) => {
    event.preventDefault();
    dispatch(loadFilterMedicalRep(state.city_id, state.search_value, setLoad));
  };
  const Unfilter = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      search_value: "",
    }));
    setisEmpty(true);
    dispatch(loadMedicalRep(navigate, setopenInternet));
  };
  const { handleChangeTargetGroup } = VariableTargetGroup();
  return (
    <div
      className={`targetfilter ${langmiamed == "ar" ? "padding_right_10" : ""}`}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <div className="oneFilter">
        <input
          value={state.search_value}
          placeholder={`${t("search")} ...`}
          onChange={(e) =>
            handleChangeDelegates(e.target.value, "search_value")
          }
        />
      </div>
      <div className="oneFilter">
        <SelectWithSearch
          load={loadingCities}
          typeCss={true}
          title={t("cities")}
          array={cities}
          setId={(value) => handleChangeDelegates(value, "city_id")}
          funb={LoadCitiesCustom}
          isEmpty={isEmpty}
          type="cities"
        />
      </div>
      <div className="oneFilter width_23p">
        <SelectWithSearch
          load={loadinGovernorate}
          typeCss={true}
          title={t("Regions")}
          array={governorates}
          setId={(value) => handleChangeDelegates(value, "city_id")}
          funb={LoadGovernoratesCustom}
          isEmpty={isEmpty}
          func={LoadCitiesCustom}
          type="Regions"
        />
      </div>
      <button className="targetfilter_button" onClick={filter}>
        {state.Load ? "Loading ..." : `${t("filter")}`}
      </button>
      <img
        onClick={Unfilter}
        title={t("unfilter")}
        className="targetfilter_button_img"
        src={nofilterr}
      />
    </div>
  );
}

export default FilterDelegates;
