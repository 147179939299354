import React, { useState, useEffect } from 'react'
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { declassfiyy, emptyClass, loadClassBySpec } from '../../Redux/Actions/ActionConfigProduct';
import InnerLoader from '../General/InnerLoader';
import ReactLoading from 'react-loading';
import { CheckFont } from '../General/GeneralComponent/CheckLang';

function ViewClassBySpec({ specId, open, setOpen }) {
    let dispatch = useDispatch();
    const classificationByspec = useSelector(state => state.configProduct.classificationByspec)
    const loadingClass = useSelector(state => state.configProduct.loadingClass)
    const [Load, setLoad] = useState('')
    const [delId, setdelId] = useState('')
    useEffect(() => {
        dispatch(loadClassBySpec(specId))
    }, []);
    const deleteClassfiy = (id, ID,Fid) => {
        setdelId(Fid)
        dispatch(declassfiyy(id, setLoad, null, ID))
    }
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
                dispatch(emptyClass())
            }}
            center
            classNames={{
                modal: "configPop",
            }}
            focusTrapped={false}
        >
            <div className='div_configPop' >
                <div className='config_row'>
                    <div className='config_info' >
                        {
                            loadingClass == false ? (
                                classificationByspec.slice().reverse().map((ss) => {
                                    return (
                                        <div className='allsetting_icon'>
                                            <div className='setting_icon' key={ss.id} >
                                                <i className="las la-caret-square-right"></i>
                                                <span className='name_spec' style={{fontFamily: CheckFont(ss.name)}} >{ss.name}</span>
                                            </div>
                                            {
                                                Load ? (
                                                    delId === ss.id ?
                                                        <ReactLoading type="spinningBubbles" color="#9699c2" height={'8%'} width={'8%'} /> :
                                                        <i className="las la-trash-alt timee" onClick={() => deleteClassfiy(ss.pivot.id, ss.pivot.specialization_id, ss.id)} ></i>
                                                ) : <i className="las la-trash-alt timee" onClick={() => deleteClassfiy(ss.pivot.id, ss.pivot.specialization_id, ss.id)} ></i>
                                            }
                                        </div>
                                    )
                                })) : <div className='loadSetting' ><InnerLoader /></div>
                        }
                    </div>
                </div>
                <div className='config_line' ></div>
            </div>
        </RModal>
    )
}

export default ViewClassBySpec