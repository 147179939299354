import React, { useEffect } from 'react'
import './Layout.css'
import useClickOutside from '../../useClickOutside';
import imgg from '../../images/favicon.png'
import { Link } from 'react-router-dom';
import { loadNotification } from '../../Redux/Actions/ActionAuth';
import InnerLoader from '../General/InnerLoader';
import moment from 'moment';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { CheckFont } from '../General/GeneralComponent/CheckLang';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';

function Notify({ setNotify, langmiamed }) {
  let { dispatch,navigate} = AbstructVar()
  let{translate} = AbstructLang()
  const {notify,loadingNotify} = useSelectorCustom()
  useEffect(() => {
    dispatch(loadNotification())
  }, [])
  let domNode = useClickOutside(() => {
    setNotify(false);
  });
  const ToDetails = (work_plan_id, task_id,service) => {
    if (work_plan_id !== null && task_id !== null && service === "WorkPlanVisit" ) {
        navigate(`/work-plans/${work_plan_id}/task/${task_id}`)
    }
    if(service === "MedicalRepVacation"){
        navigate('/vacation')
    }
    if(service === "TargetGroup"){
        navigate('/target-group')
    }
    if(service === "ExternalVisit"){
        navigate('/external-visites')
    }
}
  return (
    <div className={langmiamed == "ar" ? 'notify notifyL' : 'notify notifyR'} ref={domNode} >
      <h5 className={langmiamed === 'ar' ? "textRight":"textLeft"} >{translate('Notification')}</h5>
      {
        loadingNotify == false ? (
          notify.slice(0, 5).map((nn) => {
            return (
              <div className='row_notify' key={nn.id} onClick={() => ToDetails(nn.work_plan_id, nn.work_plan_task_id,nn.service)} >
                <img className={langmiamed === 'ar' ?"margin_left_20" : "margin_right_20"} src={imgg} />
                <div className='row_notify_info' >
                  <span style={{fontFamily: CheckFont(nn.title)}} className={`title_notify ${langmiamed === 'ar' ? "textRight":"textLeft"} `} >{nn.title}</span>
                  <span style={{fontFamily: CheckFont(nn.body)}} className={`date_notify ${langmiamed === 'ar' ? "textRight":"textLeft"}`}>{nn.body}</span>
                  <span className='date_notify1'>{moment(nn.created_at).format("YYYY-MM-DD")} / {moment(nn.created_at).format("HH:mm A")}</span>
                </div>
              </div>
            )
          })) : <div className='row_notify_load' ><InnerLoader /></div>
      }
      <div className='div_more' >
        <span><Link to="/notifications">{translate('view_more')}</Link></span>
      </div>
    </div>
  )
}

export default Notify