import * as types from "../ActionType";

const initState = {
    product: [],
    loadingproduct: true
}

const ProductReducer = (state = initState, action) => {
    switch (action.type) {
        case types.ADD_PRODUCT:
            return { ...state, loadingproduct: true }
        case types.ADD_MARKETINGtOOL:
            return { ...state, loadingproduct: true }
        case types.GET_PRODUCT:
            return { ...state, product: action.payload, loadingproduct: false }
        case types.DELETE_PRODUCT:
            return { ...state, loadingproduct: true }
        case types.DELETE_TOOL:
            return { ...state, loadingproduct: true }
        case types.EDIT_PRODUCT:
            return { ...state, loadingproduct: true }
        default:
            return state;
    }
};

export default ProductReducer;
