import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { loadTargetType } from '../../../Redux/Actions/ActionTargetGroup';
import SearchSelect from '../../General/SelectSearch';
import { loadReportType } from '../../../Redux/Actions/ActionReportType';
import { FiltervisitsByMed, FundownloadvisitsByMed } from '../../../Redux/Actions/ActionMedicalRep';

function FilterVisitsByMedRep({ id, t, langmiamed, setopenInternet }) {
    let dispatch = useDispatch();
    let navigate = useNavigate()
    const [Load, setLoad] = useState('')
    const [Load1, setLoad1] = useState('')
    const [is_site_match, setis_site_match] = useState(null)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const target_types = useSelector(state => state.target.target_types)
    const loadingtarget_type = useSelector(state => state.target.loadingtarget_type)
    const reportType = useSelector(state => state.report_type.reportType)
    const loadingReportType = useSelector(state => state.report_type.loadingReportType)
    useEffect(() => {
        dispatch(loadTargetType())
        dispatch(loadReportType())
    }, [])
    const handleChange = (range) => {
        const [startDate, endDate] = range;
        setStartDate(startDate);
        setEndDate(endDate);
    };
    useEffect(() => {
        setFilteredtype(target_types)
    }, [target_types])
    const [opentype, setopentype] = useState(false)
    const [searchValue3, setSearchValuetype] = useState("");
    const [target_type_id, setSearchValuetarget_type_id] = useState("");
    const [filteredtype, setFilteredtype] = useState([]);

    const handleInputChangetype = (e) => {
        const inputValue = e.target.value;
        setSearchValuetype(inputValue);
        const filtered = target_types.filter((item) =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredtype(filtered);
    };

    const handleOptionClicktype = (item) => {
        setSearchValuetype(item.name);
        setSearchValuetarget_type_id(item.id);
        setopentype(false);
    };
    useEffect(() => {
        setFilteredrepotype(reportType)
    }, [reportType])
    const [openrepotype, setopenrepotype] = useState(false)
    const [searchValuerepotype, setSearchValuerepotype] = useState("");
    const [report_type_id, setSearchValuereport_type_id] = useState("");
    const [filteredrepotype, setFilteredrepotype] = useState([]);

    const handleInputChangerepotype = (e) => {
        const inputValue = e.target.value;
        setSearchValuerepotype(inputValue);
        const filtered = reportType.filter((item) =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredrepotype(filtered);
    };

    const handleOptionClickrepotype = (item) => {
        setSearchValuerepotype(item.name);
        setSearchValuereport_type_id(item.id);
        setopenrepotype(false);
    };
    const filter = event => {
        event.preventDefault()
        dispatch(FiltervisitsByMed(id, startDate, endDate, target_type_id, report_type_id, is_site_match, setLoad))
    }
    const exportToExcel = event => {
        event.preventDefault()
        dispatch(FundownloadvisitsByMed(id, startDate, endDate, target_type_id, report_type_id, is_site_match, setLoad1))
    };
    return (
        <div className={`targetfilter ${langmiamed == 'ar' ? "padding_right_10" : ""}`} dir={langmiamed == "ar" ? "rtl" : "ltr"} >
            <div className='oneFilter' >
                <DatePicker
                    selected={startDate}
                    onChange={handleChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText={t('selectDate')}
                />
            </div>
            <div className='oneFilter' >
                <SearchSelect
                    openCity={opentype}
                    setopenCity={setopentype}
                    searchValue1={searchValue3}
                    handleInputChange={handleInputChangetype}
                    filteredCategories={filteredtype}
                    handleOptionClick={handleOptionClicktype}
                    load={loadingtarget_type}
                    typeCss={true}
                    title={t('target_type')}
                />
            </div>
            <div className='oneFilter' >
                <SearchSelect
                    openCity={openrepotype}
                    setopenCity={setopenrepotype}
                    searchValue1={searchValuerepotype}
                    handleInputChange={handleInputChangerepotype}
                    filteredCategories={filteredrepotype}
                    handleOptionClick={handleOptionClickrepotype}
                    load={loadingReportType}
                    typeCss={true}
                    title={t('reportType')}
                />
            </div>
            <div className='oneFilter' onChange={(e) => setis_site_match(e.target.value)} >
                <select>
                    <option disabled="disabled" selected="selected">-- {t('match')} or {t('notmatch')} --</option>
                    <option value={1} >{t('match')}</option>
                    <option value={0} >{t('notmatch')}</option>
                </select>
            </div>
            <button className='targetfilter_button' onClick={filter} >
                {
                    Load ? "Loading ..." : `${t('filter')}`
                }
            </button>
            <button className='targetfilter_button' onClick={exportToExcel} >
                {
                    Load1 ? "Loading ..." : `${t('excel')}`
                }
            </button>
        </div>
    )
}

export default FilterVisitsByMedRep