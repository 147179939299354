import React from 'react'
import { CheckFont } from './GeneralComponent/CheckLang'


function Page_Header({langmiamed,header , path,name}) {
    return (
        <div className="page-header" >
            <h4 className={langmiamed == 'ar' ? "textRight": "textLeft"} >{header}
            {
                name &&(
                    <span style={{fontFamily: CheckFont(name)}} > / {name}</span>
                )
            }
            </h4>
            <small className={langmiamed == 'ar' ? "page_header_small": ""} >{path}</small>
        </div>
    )
}

export default Page_Header