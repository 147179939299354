import React,{useEffect} from 'react'
import Page_Header from '../Page_Header'
import './NotPermission.css'
import notfound from '../../../images/notFound.png'
import { useTranslation } from "react-i18next";

function NotFound() {
    let langmiamed = localStorage.getItem('langmiamed');
    const [t, i18n] = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langmiamed'));
    }, []);
    return (
        <div  className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
            <main>
                <Page_Header header="" path="" />
            </main>
            <div className="page-content" >
                <div className='noper' >
                    <img src={notfound} />
                    <br></br>
                    <h3>Not Found</h3>
                </div>
            </div>
        </div>
    )
}

export default NotFound