import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Page_Header from "../../General/Page_Header";
import "./WorkTask.css";
import AddTask from "./AddTaks";
import TaskDetails from "./TaskDetails";
import FilterWorkTask from "./FilterWorkTask";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyOneWorkPlan,
  loadOneWorkPlans,
} from "../../../Redux/Actions/ActionWorkPlans";
import { useParams, useNavigate } from "react-router-dom";
import {
  assignTaskToDate,
  deleteTask,
  emptyTask,
  loadTask,
} from "../../../Redux/Actions/ActionTask";
import Warning from "../../General/Warning_Delete/Warning";
import EditTask from "./EditTask";
import InnerLoader from "../../General/InnerLoader";
import { format } from "date-fns";
import VisitesWorkPlan from "../VisitesWorkPlan/VisitesWorkPlan";
import ar from "date-fns/locale/ar";
import Statistics from "../VisitesWorkPlan/Statistics";
import { emptyReports } from "../../../Redux/Actions/ActionReport";
import VisitesProducts from "../VisitesWorkPlan/VisitesProducts";
import NoInternet from "../../General/NotPermission/NoInternet";
import ProductsAssign from "../ProductsAssign/ProductsAssign";
import { CheckFont } from "../../General/GeneralComponent/CheckLang";
import { MarketingAssign } from "../MarketingAssign/MarketingAssign";

function WorkTask() {
  const [openInternet, setopenInternet] = useState(false);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let langmiamed = localStorage.getItem("langmiamed");
  const [t, i18n] = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDet, setOpenDet] = useState(false);
  const [filterTask, setfilterTask] = useState(false);
  const [isTap, setisTap] = useState("task");
  const [tasks, setTasks] = useState([]);
  const [work_days, setWork_days] = useState([]);
  const [day1, setDay1] = useState("");
  const [details, setdetails] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [arr1, setarr1] = useState([]);
  const [scrollLeft, setScrollLeft] = useState(0);
  let { id } = useParams();
  const onePlan = useSelector((state) => state.work_plans.onePlan);
  const taskss = useSelector((state) => state.work_task.taskss);
  const loadingTasks = useSelector((state) => state.work_task.loadingTasks);
  const [loadDel, setloadDel] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("langmiamed"));
    dispatch(loadOneWorkPlans(id, setopenInternet));
    dispatch(loadTask(id, navigate, setopenInternet));
    return () => {
      dispatch(emptyOneWorkPlan());
      dispatch(emptyTask());
      dispatch(emptyReports());
    };
  }, []);

  useEffect(() => {
    setTasks(taskss);
  }, [taskss]);

  useEffect(() => {
    if (onePlan && onePlan.start_date && onePlan.end_date) {
      const startDate = new Date(onePlan.start_date);
      const endDate = new Date(onePlan.end_date);

      const allWorkDays = getDatesBetweenDates(startDate, endDate).map(
        (date, index) => ({
          id: index + 1,
          day: date.toLocaleDateString("en-CA"),
          nameDay: format(date, "EEEE"),
          arabicNameDay: format(date, "EEEE", { locale: ar }),
        })
      );

      setWork_days(allWorkDays);
    }
  }, [onePlan]);

  const getDatesBetweenDates = (startDate, endDate) => {
    let dates = [];
    const theDate = new Date(startDate);
    while (theDate <= endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    return dates;
  };

  const [deletee, setdeletee] = useState(false);
  const [arrW, setarrW] = useState([]);

  const warning = (row) => {
    setarrW(row);
    setdeletee(true);
  };

  const handleDelete = (idd) => {
    dispatch(deleteTask(idd, id, setdeletee, setloadDel));
  };

  const handleEditClick = (event, arr) => {
    event.preventDefault();
    const formValues = {
      id: arr.id,
      note: arr.note,
      priority_level: arr.priority_level,
      time: arr.time,
      date: arr.date,
      status: arr.status,
      work_plan_id: arr.work_plan_id,
      target_group_id: arr.target_group_id,
      target_group_name: arr.target_group_name,
    };
    setarr1(formValues);
    setOpenEdit(true);
  };

  const openAddTask = (day) => {
    setOpen(true);
    setDay1(day);
  };

  const openDetailsTask = (task) => {
    navigate(`/work-plans/${id}/task/${task.id}`);
  };

  const onDragStart = (event, taskId) => {
    event.stopPropagation();
    event.dataTransfer.setData("text/plain", taskId);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, targetDayId, day) => {
    const taskId = event.dataTransfer.getData("text/plain");
    const task = tasks.find((task) => task.id.toString() === taskId);
  
    if (task) {
      const targetDay = work_days.find((day) => day.id === targetDayId);
      if (targetDay) {
        if (task.date === targetDay.day) {
          return;
        }
        const updatedTask = { ...task, date: targetDay.day };
        const updatedTasks = tasks.map((t) =>
          t.id.toString() === taskId ? updatedTask : t
        );
        setTasks(updatedTasks);
        dispatch(assignTaskToDate(taskId, day, id));
      }
    }
  };

  const renderTask = (task, index) => {
    return (
      <div
        className={`div_task ${
          task.status === "is_done" ? "border_true" : "border_false"
        }`}
        dir={langmiamed === "ar" ? "rtl" : "ltr"}
        draggable
        onDragStart={(event) => onDragStart(event, task.id)}
      >
        <div
          className={`action_task ${
            langmiamed == "ar" ? "action_task_left" : "action_task_right"
          } `}
        >
          <i
            className="las la-pen editTask"
            onClick={(event) => handleEditClick(event, task)}
          ></i>
          <i class="las la-times delTask" onClick={() => warning(task)}></i>
        </div>
        {task.status == "is_done" ? (
          <div className="div_task_left_true">
            <i class="las la-check"></i>
          </div>
        ) : (
          <div className="div_task_left_false">
            <i className="las la-history"></i>
          </div>
        )}

        <div className="div_task_info">
          <div className="icon_task">
            <i
              className={`las la-user ${
                langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
              } `}
            ></i>
            <span style={{ fontFamily: CheckFont(task.target_group_name) }}>
              {task.target_group_name}
            </span>
          </div>
          <div className="bothh">
            <div className="icon_task">
              <i
                className={`las la-calendar ${
                  langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                } `}
              ></i>
              <span>{task.date}</span>
            </div>
            <div className="icon_task">
              <i
                className={`las la-clock ${
                  langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                } `}
              ></i>
              <span>{task.time}</span>
            </div>
          </div>
          <span className="detail_task" onClick={() => openDetailsTask(task)}>
            {t("details")}
          </span>
        </div>
      </div>
    );
  };

  const renderDay = (day, id) => {
    const dayTasks = tasks.filter((task) => task.date === day.day);
    return (
      <div
        key={id}
        onDragOver={onDragOver}
        onDrop={(event) => onDrop(event, day.id, day.day)}
        className={`colTask ${
          langmiamed === "ar"
            ? "border_left padding_left_30"
            : "border_right padding_right_30"
        }`}
        style={{ scrollLeft: scrollLeft }}
      >
        <div className="add_task" onClick={() => openAddTask(day.day)}>
          {t("addNewTask")}
        </div>
        <div>
          {dayTasks.map((task, index) => (
            <div key={task.id}>{renderTask(task, index)}</div>
          ))}
        </div>
      </div>
    );
  };

  //   const handleScrollRight = () => {
  //     const maxScroll =
  //       document.getElementById("allTasks").scrollWidth -
  //       document.getElementById("allTasks").clientWidth;
  //     const newScroll = Math.min(scrollLeft + 300, maxScroll);
  //     setScrollLeft(newScroll);
  //   };

  const handleScrollRight = () => {
    const allTasksElement = document.getElementById("allTasks");
    const maxScroll = allTasksElement.scrollWidth - allTasksElement.clientWidth;
    const newScroll = Math.min(
      localStorage.getItem("langmiamed") === "en"
        ? scrollLeft + 300
        : scrollLeft - 300,
      maxScroll
    );
    setScrollLeft(newScroll);
    allTasksElement.scrollLeft = newScroll;
  };
  const handleScrollLeft = () => {
    const allTasksElement = document.getElementById("allTasks");
    const newScroll = Math.max(
      localStorage.getItem("langmiamed") === "en"
        ? scrollLeft - 300
        : scrollLeft + 300,
      0
    );
    setScrollLeft(newScroll);
    allTasksElement.scrollLeft = newScroll;
  };
  useEffect(() => {
    const allTasksElement = taskContainerRef.current;
    if (allTasksElement) {
      allTasksElement.scrollLeft = scrollLeft;
    }
  }, [scrollLeft]);

  const taskContainerRef = useRef(null);

  return (
    <>
      {openDet && (
        <TaskDetails
          t={t}
          langmiamed={langmiamed}
          details={details}
          open={openDet}
          setOpen={setOpenDet}
        />
      )}
      {filterTask && (
        <FilterWorkTask
          navigate={navigate}
          setopenInternet={setopenInternet}
          medical_rep_id={onePlan.medical_rep_id}
          id={id}
          langmiamed={langmiamed}
          t={t}
          setfilterTask={setfilterTask}
        />
      )}
      <div
        className={langmiamed === "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={`${t("work_plan_task")}`}
            name={
              onePlan.medical_rep_name !== undefined
                ? onePlan.medical_rep_name
                : ""
            }
            path={`${t("home")} / ${t("work_plan_task")} `}
          />
        </main>
        <div className="page-content">
          <div className="choise_task" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            <button
              className={
                isTap == "task" ? "active_btn_choise" : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("task");
              }}
            >
              {t("work_plan_task")}
            </button>
            <button
              className={
                isTap == "visite" ? "active_btn_choise" : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("visite");
              }}
            >
              {t("visites")}
            </button>
            <button
              className={
                isTap == "visite_product"
                  ? "active_btn_choise"
                  : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("visite_product");
              }}
            >
              {t("Product_reports")}
            </button>
            <button
              className={
                isTap == "statistics"
                  ? "active_btn_choise"
                  : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("statistics");
              }}
            >
              {t("statistics")}
            </button>
            <button
              className={
                isTap == "products"
                  ? "active_btn_choise"
                  : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("products");
              }}
            >
              {t("stock")}
            </button>

            {/* new */}
            <button
              className={
                isTap == "marketing"
                  ? "active_btn_choise"
                  : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("marketing");
              }}
            >
              {t("marketing")}
            </button>
            {/* new */}
          </div>
          {isTap == "task" && (
            <div
              id="allTasks"
              className="allTasks"
              dir={langmiamed == "ar" ? "rtl" : "ltr"}
            >
              <div className="gen_filter" onClick={() => setfilterTask(true)}>
                {t("filter")}
              </div>
              <i
                className="las la-long-arrow-alt-right arrowRight"
                onClick={
                  langmiamed === "ar" ? handleScrollLeft : handleScrollRight
                }
              ></i>
              <i
                className="las la-long-arrow-alt-left arrowLeft"
                onClick={
                  langmiamed === "ar" ? handleScrollRight : handleScrollLeft
                }
              ></i>

              {loadingTasks == false ? (
                work_days.map((ww) => {
                  return (
                    <div
                      key={ww.id}
                      className={`oneTask ${
                        langmiamed === "ar"
                          ? "margin_left_30"
                          : "margin_right_30"
                      }`}
                    >
                      <div className="top_task">
                        {langmiamed == "ar" ? (
                          <h5>
                            {ww.arabicNameDay} {ww.day}
                          </h5>
                        ) : (
                          <h5>
                            {ww.nameDay} {ww.day}
                          </h5>
                        )}
                      </div>
                      {renderDay(ww, ww.id)}
                    </div>
                  );
                })
              ) : (
                <div className="div_loadd">
                  <InnerLoader />
                </div>
              )}
            </div>
          )}
          {isTap == "visite" && (
            <VisitesWorkPlan langmiamed={langmiamed} t={t} />
          )}
          {isTap == "statistics" && (
            <Statistics id={id} langmiamed={langmiamed} t={t} />
          )}
          {isTap == "visite_product" && (
            <VisitesProducts langmiamed={langmiamed} t={t} />
          )}
          {isTap == "products" && (
            <ProductsAssign langmiamed={langmiamed} t={t} />
          )}
          {isTap == "marketing" && (
            <MarketingAssign langmiamed={langmiamed} t={t} />
          )}
        </div>
      </div>
      {open && (
        <AddTask
          medical_rep_id={onePlan.medical_rep_id}
          date={day1}
          id={id}
          t={t}
          langmiamed={langmiamed}
          open={open}
          setOpen={setOpen}
        />
      )}

      {deletee === true ? (
        <Warning
          loadDel={loadDel}
          arr={arrW}
          open={deletee}
          setOpen={setdeletee}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {openEdit && (
        <EditTask
          medical_rep_id={onePlan.medical_rep_id}
          langmiamed={langmiamed}
          t={t}
          arr={arr1}
          open={openEdit}
          setOpen={setOpenEdit}
        />
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default WorkTask;

/*import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AllLayout from '../../General/AllLayout';
import Page_Header from '../../General/Page_Header';
import './WorkTask.css';
import AddTask from './AddTaks';
import TaskDetails from './TaskDetails';
import FilterWorkTask from './FilterWorkTask';
import { useDispatch, useSelector } from "react-redux";
import { emptyOneWorkPlan, loadOneWorkPlans } from '../../../Redux/Actions/ActionWorkPlans';
import { useParams } from 'react-router-dom';
import { deleteTask, emptyTask, loadTask } from '../../../Redux/Actions/ActionTask';
import Warning from '../../General/Warning_Delete/Warning';
import EditTask from './EditTask';
import { EMPTY_TASK } from '../../../Redux/ActionType';
import InnerLoader from '../../General/InnerLoader';

function WorkTask() {
    let langmiamed = localStorage.getItem('langmiamed');
    const [t, i18n] = useTranslation();
    const [open, setOpen] = useState(false)
    const [openDet, setOpenDet] = useState(false)
    const [filterTask, setfilterTask] = useState(false)
    const [tasks, setTasks] = useState([]);
    const [work_days, setWork_days] = useState([])
    const [day1, setDay1] = useState('')
    const [details, setdetails] = useState([])
    const [openEdit, setOpenEdit] = useState(false)
    const [arr1, setarr1] = useState([])
    let dispatch = useDispatch();
    let { id } = useParams()
    const onePlan = useSelector(state => state.work_plans.onePlan)
    const LoadingOnePlan = useSelector(state => state.work_plans.LoadingOnePlan)
    const taskss = useSelector(state => state.work_task.taskss)
    const loadingTasks = useSelector(state => state.work_task.loadingTasks)
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langmiamed'));
        dispatch(loadOneWorkPlans(id))
        dispatch(loadTask(id))
        return (() => {
            dispatch(emptyOneWorkPlan())
            dispatch(emptyTask())
        })
    }, [])
    useEffect(() => {
        setTasks(taskss)
    }, [taskss])
    useEffect(() => {
        if (onePlan && onePlan.start_date && onePlan.end_date) {
            const startDate = new Date(onePlan.start_date);
            const endDate = new Date(onePlan.end_date);

            const allWorkDays = getDatesBetweenDates(startDate, endDate).map((date, index) => ({
                id: index + 1,
                day: date.toLocaleDateString('en-CA')
            }));

            setWork_days(allWorkDays);
        }
    }, [onePlan]);
    const getDatesBetweenDates = (startDate, endDate) => {
        let dates = [];
        const theDate = new Date(startDate);
        while (theDate <= endDate) {
            dates = [...dates, new Date(theDate)];
            theDate.setDate(theDate.getDate() + 1);
        }
        return dates;
    };
    const moveTask = (taskId, targetDayId) => {
        const task = tasks.find((task) => task.id === taskId);
        if (task) {
            const targetDay = work_days.find((day) => day.id === targetDayId);
            if (targetDay) {
                const updatedTask = { ...task, date: targetDay.day };
                setTasks((prevTasks) => {
                    const updatedTasks = prevTasks.map((t) =>
                        t.id === taskId ? updatedTask : t
                    );
                    return updatedTasks;
                });
            }
        }
    };
    const [deletee, setdeletee] = useState(false);
    const [arrW, setarrW] = useState([]);
    const warning = (row) => {
        setarrW(row)
        setdeletee(true);
    }
    const handleDelete = (idd) => {
        dispatch(deleteTask(idd, id, setdeletee))
    }
    const handleEditClick = (event, arr) => {
        event.preventDefault();
        const formValues = {
            id: arr.id,
            note: arr.note,
            priority_level: arr.priority_level,
            time: arr.time,
            date: arr.date,
            status: arr.status,
            work_plan_id: arr.work_plan_id,
            target_group_id: arr.target_group_id,
            target_group_name: arr.target_group_name
        };
        setarr1(formValues);
        setOpenEdit(true)
    }
    const renderTask = (task, index) => {
        return (
            <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`div_task ${task.status == "is_done" ? 'border_true' : 'border_false'}`}
                        dir={langmiamed == 'ar' ? "rtl" : "ltr"}
                    >
                        <div className='action_task' >
                            <i className="las la-edit editTask" onClick={(event) => handleEditClick(event, task)} ></i>
                            <i class="las la-ban delTask" onClick={() => warning(task)}></i>
                        </div>
                        {task.status == 'is_done' ? (
                            <div className='div_task_left_true'>
                                <i class='las la-check'></i>
                            </div>
                        ) : (
                            <div className='div_task_left_false'>
                                <i className='las la-history'></i>
                            </div>
                        )}

                        <div className='div_task_info'>
                            <div className='icon_task'>
                                <i className={`las la-user ${langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} `}></i>
                                <span>{task.target_group_name}</span>
                            </div>
                            <div className='bothh'>
                                <div className='icon_task'>
                                    <i className={`las la-calendar ${langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} `}></i>
                                    <span>{task.date}</span>
                                </div>
                                <div className='icon_task'>
                                    <i className={`las la-clock ${langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} `}></i>
                                    <span>{task.time}</span>
                                </div>
                            </div>
                            <span className='detail_task' onClick={() => openDetailsTask(task)} >Details</span>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    };

    const renderDay = (day, id) => {
        const dayTasks = tasks.filter((task) => task.date === day);
        return (
            <Droppable droppableId={id}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} key={id} className={`colTask ${langmiamed == 'ar' ? "border_left padding_left_30" : "border_right padding_right_30"} `}>
                        <div className='add_task' onClick={() => openAddTask(day)}>
                            Add New Task
                        </div>
                        {dayTasks.map((task, index) => (
                            <div key={task.id}>
                                {renderTask(task, index)}
                            </div>
                        ))}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

        );
    };

    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        if (!destination) {
            return;
        }
        const targetDayId = parseInt(destination.droppableId);
        if (isNaN(targetDayId) || !work_days.some((day) => day.id === targetDayId)) {
            return;
        }

        if (source.droppableId !== destination.droppableId || source.index !== destination.index) {
            const taskId = parseInt(draggableId);
            moveTask(taskId, targetDayId);
        }
    };
    const openAddTask = (day) => {
        setOpen(true)
        setDay1(day)
    }
    const openDetailsTask = (task) => {
        setOpenDet(true)
        setdetails(task)
    }
    return (
        <>
            {
                filterTask && (
                    <FilterWorkTask id={id} langmiamed={langmiamed} t={t} setfilterTask={setfilterTask} />
                )
            }
            <div className={langmiamed == 'ar' ? 'main-content_ar' : 'main-content_en'}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={t('work_tasks')} path={`${t('home')} / ${t('work_tasks')} `} />
                </main>
                <div className='page-content'>
                    <div className='files_target' onClick={() => setfilterTask(true)} >
                        <div className='div_tooltip' >
                            <div className="tooltip">
                                Filter
                            </div>
                            <i className="las la-filter"></i>
                        </div>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className='allTasks' dir={langmiamed == "ar" ? "rtl" : "ltr"}>
                            {
                                loadingTasks == false? (
                                work_days.map((ww) => {
                                    return (
                                        <div key={id} className={`oneTask ${langmiamed == 'ar' ? "margin_left_30" : "margin_right_30"} `}>
                                            <div className='top_task'>
                                                <h5>{ww.day}</h5>
                                            </div>
                                            {renderDay(`${ww.day}`, `${ww.id}`)}
                                        </div>
                                    )
                                })):<div className='div_loadd' ><InnerLoader /></div>
                            }
                        </div>
                    </DragDropContext>
                </div>
            </div>
            {
                open && (
                    <AddTask date={day1} id={id} t={t} langmiamed={langmiamed} open={open} setOpen={setOpen} />
                )
            }
            {
                openDet && (
                    <TaskDetails details={details} open={openDet} setOpen={setOpenDet} />
                )
            }
            {
                deletee == true ? (
                    <Warning arr={arrW} open={deletee} setOpen={setdeletee} handleDelete={handleDelete} />
                ) : <></>
            }
            {
                openEdit && (
                    <EditTask t={t} arr={arr1} open={openEdit} setOpen={setOpenEdit} />
                )
            }
        </>
    );
}

export default WorkTask;*/
