import * as types from "../ActionType";

const initState = {
    governorates: [],
    // countriess: [],
    loadinGovernorate: true,
    // loadingCountries: true
}

const GovernorateReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_Governorate:
            return { ...state, governorates: action.payload, loadinGovernorate: false }
        default:
            return state;
    }
};

export default GovernorateReducer;
