import React from 'react'
import nodata from '../../../images/icons/cloud-computing.png'
import './NoData.css'
import { useTranslation } from "react-i18next";

function NoData() {
    let langmiamed = localStorage.getItem('langmiamed');
    const [t, i18n] = useTranslation();
    return (
        <div className='nodata' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
            <img src={nodata} /> 
            <h4>{t('nodata')}</h4>
        </div>
    )
}

export default NoData