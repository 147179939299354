import React from 'react'
import chart from '../../../images/chart1.png'
import ChartComponent from './ChartComponent'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { emptyStatistics, loadStatistics } from '../../../Redux/Actions/ActionStatistics';
import InnerLoader from '../../General/InnerLoader';

function Statistics({ id, langmiamed, t }) {
    let dispatch = useDispatch();
    const statistics = useSelector(state => state.statisticss.statistics)
    const loadingStatistics = useSelector(state => state.statisticss.loadingStatistics)
    useEffect(() => {
        dispatch(loadStatistics(id))
        return (() => {
            dispatch(emptyStatistics())
        })
    }, [])
    return (
        <>
            {
                loadingStatistics == false ? (
                    <div className='statistics' dir={langmiamed == 'ar' ? "rtl" : "ltr"} >
                        <div className='all_card_statistics' >
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'  >
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('all_task')}</span>
                                    <span className='num_statistics'>{statistics.all_tasks_count}</span>
                                </div>
                            </div>
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'>
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('visits_count')}</span>
                                    <span className='num_statistics' >{statistics.visits_count}</span>
                                </div>
                            </div>
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'>
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('not_visits_count')}</span>
                                    <span className='num_statistics'>{statistics.not_visits_count}</span>
                                </div>
                            </div>
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'>
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('delivered')}</span>
                                    <span className='num_statistics'>{statistics.delivered_product_count}</span>
                                </div>
                            </div>
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'>
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('marketing_tools_count')}</span>
                                    <span className='num_statistics'>{statistics.marketing_tools_count}</span>
                                </div>
                            </div>
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'>
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('medicine_samples_count')}</span>
                                    <span className='num_statistics'>{statistics.medicine_samples_count}</span>
                                </div>
                            </div>
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'>
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('visits_match_count')}</span>
                                    <span className='num_statistics'>{statistics.visits_match_count}</span>
                                </div>
                            </div>
                            <div className='one_card_statistics'>
                                <img src={chart} className={langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} />
                                <div className='info_statistics'>
                                    <span className={langmiamed == 'ar' ? "textRight" : ""} >{t('visits_not_match_count')}</span>
                                    <span className='num_statistics'>{statistics.visits_not_match_count}</span>
                                </div>
                            </div>
                        </div>
                        <div className='allcharts' >
                            <div className='onechart' >
                                <ChartComponent langmiamed={langmiamed} color1="#50b798" num1={statistics.visits_count} num2={statistics.not_visits_count} name1={t('visits_count')} name2={t('not_visits_count')} />
                            </div>
                            <div className='onechart' >
                                <ChartComponent langmiamed={langmiamed}  color1='#4285C5' num1={statistics.marketing_tools_count} num2={statistics.medicine_samples_count} name1={t('marketing_tools_count')} name2={t('medicine_samples_count')}  />
                            </div>
                            <div className='onechart' >
                                <ChartComponent langmiamed={langmiamed}  color1='#f67eb4' num1={statistics.visits_match_count} num2={statistics.visits_not_match_count} name1={t('visits_match_count')} name2={t('visits_not_match_count')}   />
                            </div>
                        </div>
                    </div>
                ) : <div className='div_loadd' ><InnerLoader /></div>
            }

        </>
    )
}

export default Statistics