import React from 'react';

function NewPagenation({ target_group, currentPage, changePage }) {
    return (
        <div className="paginationBttnsNew">
            <button className="previousBttn" onClick={() => changePage(currentPage - 1)}>Previous</button>
            <span className="currentPage">{currentPage}</span>
            {
                target_group.length == 0 ? (
                    <></>
                ) : <button className="nextBttn" onClick={() => changePage(currentPage + 1)}>Next</button>
            }

        </div>
    );
}

export default NewPagenation;