import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

export const AbstructLang = () => {
    const [translate, i18n] = useTranslation();
    useEffect(() => {
        //window.scrollTo(0, 0);
        i18n.changeLanguage(localStorage.getItem('langmiamed'));
    }, []);

    return {
        translate,
        i18n
    }
}