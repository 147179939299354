import React,{useState} from 'react'
import useClickOutside from '../../../useClickOutside';
import { useDispatch } from "react-redux";
import { putReportType } from '../../../Redux/Actions/ActionReportType';

function EditReport({ langmiamed, arr, setreport_type_id }) {
    let dispatch = useDispatch();
    const [name, setName] = useState(arr.name)
    const [description, setdescription] = useState(arr.description)
    const [service, setservice] = useState(arr.service)
    const [Load, setLoad] = useState(false)
    const submit = (event) => {
        event.preventDefault();
        dispatch(putReportType(arr.id ,name,description,service,setLoad,setreport_type_id))
    }
    let domNode = useClickOutside(() => {
        setreport_type_id(null);
    });
    return (
        <div className='spec_class' ref={domNode} >
            <div className='head_report' >
                <div className='setting_icon' >
                    <i className="las la-caret-square-right"></i>
                    <input defaultValue={name} className='input_edit_report' onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='report_action' >
                    <i className={Load? "las la-spinner edit_proCat ":"las la-save edit_proCat"} onClick={submit} ></i>
                </div>
            </div>
            <select value={service} className='input_edit_report bottom' onChange={(e) => setservice(e.target.value)}>
                <option disabled="disabled" selected="selected">choose</option>
                <option value="WorkPlan" >Work Plan</option>
                <option value="ScientificOffice" >Other Task</option>
                <option value="WorkPlanOrder" >Work Plan Order</option>
            </select>
            <textarea defaultValue={description} className='input_edit_report bottom'  onChange={(e) => setdescription(e.target.value)} /> 
        </div>
    )
}

export default EditReport