import React,{useState} from 'react'
import { useDispatch } from "react-redux";
import { loadFilterVisites, loadVisites } from '../../Redux/Actions/ActionExternalVisites';
import nofilterr from '../../images/icons/nofilter.png'
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading';
import { VariableExternalVisit } from './VariableExternalVisit';
import SelectWithSearch from '../General/GeneralComponent/SelectWithSearch';

function FilterExternal({ setopenInternet, navigate, langmiamed, t }) {
    let dispatch = useDispatch();
    const { state,setState, handleChangeExternalVisit, setLoad } = VariableExternalVisit()
    const { medicalRep, loadingmedicalRep, cities, loadingCities } = useSelectorCustom()
    const { LoadCitiesCustom, LoadMedicalRepCustom } = FunctionsLoading()
    const [isEmpty , setisEmpty] = useState(false)
    const filter = event => {
        event.preventDefault()

        dispatch(loadFilterVisites(state.city_id, state.medical_rep_id, setLoad))
    }
    const Unfilter = event => {
        event.preventDefault()
        setState((prevState) => ({
            ...prevState,
            medical_rep_id: '',
            city_id:''
        }))
        setisEmpty(true)
        dispatch(loadVisites(navigate, setopenInternet))
    }
    return (
        <div className={`targetfilter ${langmiamed == 'ar' ? "padding_right_10" : ""}`} dir={langmiamed == "ar" ? "rtl" : "ltr"} >
            <div className='oneFilter' >
                <SelectWithSearch
                    load={loadingmedicalRep}
                    typeCss={true}
                    title={t('delegates')}
                    array={medicalRep}
                    setId={(value) => handleChangeExternalVisit(value, 'medical_rep_id')}
                    funb={LoadMedicalRepCustom}
                    isEmpty={isEmpty}
                />
            </div>
            <div className='oneFilter' >
                <SelectWithSearch
                    load={loadingCities}
                    typeCss={true}
                    title={t('cities')}
                    array={cities}
                    setId={(value) => handleChangeExternalVisit(value, 'city_id')}
                    funb={LoadCitiesCustom}
                    isEmpty={isEmpty}
                />
            </div>
            <button className='targetfilter_button' onClick={filter} >
                {
                    state.Load ? "Loading ..." : `${t('filter')}`
                }
            </button>
            <img onClick={Unfilter} title={t('unfilter')} className='targetfilter_button_img' src={nofilterr} />
        </div>
    )
}

export default FilterExternal