import React,{useState} from 'react'
import ViewSpecByClass from '../ViewSpecByClass'
import InnerLoader from '../../General/InnerLoader'
import { CheckFont } from '../../General/GeneralComponent/CheckLang'

function ClassificationsCard({ classifications, loadingclassifications, translate, langmiamed }) {
    const [openSpec, setSpec] = useState(false)
    const [classId, setClassId] = useState('')
    const viewSpec = (id) => {
        setClassId(id)
        setSpec(true)
    }
    return (
        <>
            <div className={`set1 ${langmiamed == 'ar' ? 'margin_left_50' : "margin_right_50"}`} >
                <h5 className={langmiamed == 'ar' ? "textRight" : ""} >{translate('classifications')}</h5>
                {
                    loadingclassifications == false ? (
                        classifications.slice().reverse().map((cc) => {
                            return (
                                <div className='spec_class' >
                                    <div className='setting_icon' >
                                        <i className={`las la-caret-square-right ${langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} `}></i>
                                        <span className='name_spec' style={{fontFamily: CheckFont(cc.name)}} >{cc.name}</span>
                                    </div>
                                    <span className={`seespec ${langmiamed == 'ar' ? "textRight" : ""} `} onClick={() => viewSpec(cc.id)} >{translate('See_specializations')}</span>
                                </div>
                            )
                        })
                    ) : <div className='loadSetting' ><InnerLoader /></div>
                }
            </div>
            {
                openSpec && (
                    <ViewSpecByClass classId={classId} open={openSpec} setOpen={setSpec} />
                )
            }
        </>
    )
}

export default ClassificationsCard