import React, { useState, useEffect } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { putMedicalRep } from "../../Redux/Actions/ActionMedicalRep";
import {
  emptySenior,
  loadSeniorSupervisor,
} from "../../Redux/Actions/ActionAuth";
import ReactLoading from "react-loading";
import { loadOneworkFlow } from "../../Redux/Actions/ActionWorkFlow";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { VariableDelegates } from "./VariableDelegates";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { Input } from "./Input";

function EditMedicalRep({ langmiamed, arr, open, setOpen, t }) {
  let dispatch = useDispatch();
  const {
    state,
    setState,
    handleChangeDelegates,
    setLoad,
    setLoad1,
    setuser_id,
    setuser_name,
  } = VariableDelegates();
  const { LoadCitiesCustom, LoadworkFlowCustom } = FunctionsLoading();
  const {
    cities,
    loadingCities,
    senior_supervisor,
    loadingsenior_supervisor,
    workflow,
    loadingWorkflow,
    oneWorkflow,
    loadingoneWorkflow,
  } = useSelectorCustom();
  const [user_role, setuser_role] = useState(arr.responsible_role);
  const [status, setStatus] = useState(arr.status === true ? 1 : 0);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      first_name: arr.first_name,
      last_name: arr.last_name,
      email: arr.email,
      phone: arr.phone,
      address: arr.address,
      gender: arr.gender,
      position: arr.position,
      work_flow_id: arr.work_flow_id,
      work_flow_name: "Select",
      city_id: arr.city_id,
      city_name: arr.city,
      user_id: arr.user_id,
      user_name: arr.responsible_name,
    }));
    if (user_role != "") {
      dispatch(loadSeniorSupervisor(user_role, setLoad1));
    }
    return () => {
      dispatch(emptySenior());
    };
  }, []);
  console.log("arr.users",arr);
  useEffect(() => {
    if (state.work_flow_id != "") {
      dispatch(loadOneworkFlow(state.work_flow_id));
    }
  }, [state.work_flow_id]);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      work_flow_name: oneWorkflow.name,
    }));
  }, [oneWorkflow]);
  const activee = () => {
    setStatus((prevValue) => (prevValue === 1 ? 0 : 1));
  };
  const view = (ser) => {
    setuser_role(ser);
    setuser_id("");
    setuser_name("");
    if (ser == "senior_medical_rep") {
      dispatch(loadSeniorSupervisor("senior_medical_rep", setLoad1));
    }
    if (ser == "senior_supervisor") {
      dispatch(loadSeniorSupervisor("senior_supervisor", setLoad1));
    }
    if (ser == "supervisor") {
      dispatch(loadSeniorSupervisor("supervisor", setLoad1));
    }
  };
  const FunSubmit = (e) => {
    e.preventDefault();
    dispatch(
      putMedicalRep(
        arr.id,
        state.first_name,
        state.last_name,
        state.email,
        state.password,
        state.address,
        state.phone,
        state.gender,
        status,
        state.position,
        state.city_id,
        state.user_id,
        state.work_flow_id,
        setLoad,
        setOpen
      )
    );
  };

const selectedUsers =state?.user_id.length != 0 ? state?.user_id?.map(user => ({
  label: user.first_name + ' ' + (user.last_name || ''),
  value: user.id,
})) : null;

  
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      center={false}
      classNames={{
        modal: "popAdd",
      }}
      focusTrapped={false}
    >
      <div className="popAddForm" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
        <h3 className={langmiamed == "ar" ? "textRight margin_top_20" : ""}>
          {t("editMedicalRep")}
        </h3>
        <form>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("fistName")}
                </span>
              </div>
              <input
                defaultValue={state.first_name}
                type="text"
                placeholder={t("fistName")}
                onChange={(e) =>
                  handleChangeDelegates(e.target.value, "first_name")
                }
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("lastName")}
                </span>
              </div>
              <input
                defaultValue={state.last_name}
                type="text"
                placeholder={t("lastName")}
                onChange={(e) =>
                  handleChangeDelegates(e.target.value, "last_name")
                }
              />
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-envelope"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("email")}
                </span>
              </div>
              <input
                defaultValue={state.email}
                type="email"
                placeholder={t("email")}
                onChange={(e) => handleChangeDelegates(e.target.value, "email")}
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-key"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("password")}
                </span>
              </div>
              <input
                type="password"
                placeholder={t("password")}
                onChange={(e) =>
                  handleChangeDelegates(e.target.value, "password")
                }
              />
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-map-marker"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("address")}
                </span>
              </div>
              <input
                defaultValue={state.address}
                type="text"
                placeholder={t("address")}
                onChange={(e) =>
                  handleChangeDelegates(e.target.value, "address")
                }
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-fingerprint"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("id")}
                </span>
              </div>
              <input
                defaultValue={state.phone}
                type="number"
                disabled
                placeholder={t("phone")}
                onChange={(e) => handleChangeDelegates(e.target.value, "phone")}
              />
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-city"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("cities")}
                </span>
              </div>
              <SelectWithSearch
                load={loadingCities}
                title={t("cities")}
                array={cities}
                setId={(value) => handleChangeDelegates(value, "city_id")}
                funb={LoadCitiesCustom}
                name={state.city_name}
                previousID={state.city_id}
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                {state.Load1 ? (
                  <ReactLoading
                    type="spinningBubbles"
                    color="#9699c2"
                    height={"5%"}
                    width={"5%"}
                  />
                ) : (
                  <i
                    className={`las la-user ${
                      langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                    }`}
                  ></i>
                )}
                <span>{t("responsible")}</span>
                <span className="require">*</span>
              </div>
              <select value={user_role} onChange={(e) => view(e.target.value)}>
                <option disabled="disabled" selected="selected">
                  {t("responsible")}
                </option>
                <option value="senior_supervisor">senior supervisor</option>
                <option value="supervisor">supervisor</option>
                <option value="senior_medical_rep">senior medical rep</option>
              </select>
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("gender")}
                </span>
              </div>
              <select
                value={state.gender}
                onChange={(e) =>
                  handleChangeDelegates(e.target.value, "gender")
                }
              >
                <option disabled="disabled" selected="selected">
                  {t("gender")}
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            {senior_supervisor.length != 0 ? (
              <>
                <div className="one_input_row">
                  <div className="Gen_icon">
                    <i
                      className={`las la-city ${
                        langmiamed == "ar"
                          ? "margin_left_10"
                          : "margin_right_10"
                      }`}
                    ></i>
                    <span>{user_role}</span>
                    <span className="require">*</span>
                  </div>
                  {/* <SelectWithSearch
                    load={loadingsenior_supervisor}
                    title={user_role}
                    array={senior_supervisor}
                    setId={(value) => handleChangeDelegates(value, "user_id")}
                    name={state.user_name}
                    previousID={state.user_id}
                  /> */}
{console.log(arr)}
{console.log("stateEdit" ,state)}
<Input
  placeholder="اختر المسئول"
  name={"user_id"}
  isMulti
  value={selectedUsers} // Pre-selected users from backend
  options={senior_supervisor?.map((supervisor) => ({
    label: supervisor.name,
    value: supervisor.id,
  }))}
  onChange={(selectedOptions) => {

    // Ensure that each selected option is correctly processed
    const updatedUserIds = selectedOptions?.map(option => {
      const selectedUser = senior_supervisor.find(s => s.id === option.value);
      return {
        id: option.value,
        first_name: selectedUser?.first_name || '', // assuming first_name is available in senior_supervisor
        last_name: selectedUser?.last_name || '',   // assuming last_name is available in senior_supervisor
      };
    }) || [];

    handleChangeDelegates(updatedUserIds, "user_id");
  }}
/>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="input_no_row">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i
                  className={`las la-city ${
                    langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                  }`}
                ></i>
                <span>{t("workFlow")}</span>
              </div>
              <SelectWithSearch
                load={loadingWorkflow}
                title={t("workFlow")}
                array={workflow}
                setId={(value) => handleChangeDelegates(value, "work_flow_id")}
                funb={LoadworkFlowCustom}
                name={state.work_flow_name ? state.work_flow_name : ""}
                previousID={state.work_flow_id ? state.work_flow_id : ""}
              />
            </div>

            <div className="Gen_icon">
              <i className="las la-city"></i>
              <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                {t("status")}
              </span>
            </div>
            <Switch
              onColor="#4285C5"
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={40}
              handleDiameter={15}
              checked={status}
              onChange={activee}
            />
          </div>
        </form>
        <div className="div_btn">
          <button disabled={state.Load} onClick={FunSubmit}>
            {state.Load ? t("Loading ... ") : <>{t("edit")}</>}
          </button>
        </div>
      </div>
    </RModal>
  );
}

export default EditMedicalRep;
