import React, { useState, useEffect } from 'react';
import useClickOutside from '../../useClickOutside';
import { CheckFont } from './GeneralComponent/CheckLang';

function MultiSelectEdit({ id, toggleOption, selectedOptions, testarr, openRole, setopenRole, selectedOptionsName, typeCss,iswidth }) {
    const [isIdFound, setIsIdFound] = useState(false);
    let domNode = useClickOutside(() => {
        setopenRole(false);
    });
    useEffect(() => {
        const found = testarr.some((option) => option.id === id);
        setIsIdFound(found);
    }, [id, testarr]);
    return (
        <>
            <div className={`div_creatable nomarginLeft`}  style={{ width: '550px' }} >
                <div className={typeCss ? "view_creatable1 " : 'view_creatable '} onClick={() => setopenRole(true)}>
                    {
                        selectedOptionsName.length !== 0 ? (
                            <>
                                {
                                    selectedOptionsName.map((e) => {
                                        return (
                                            <span>{e}</span>
                                        )
                                    })
                                }
                            </>
                        ) : <span>Select</span>
                    }
                    <i className="las la-angle-double-down icon_searchselect"></i>
                </div>
                {openRole && (
                    <div className='select_creatable' ref={domNode}>
                        {testarr.map((option) => {
                            const isSelected = option.id === id;
                            return (
                                !isSelected ? (
                                    <div key={option.id} className='lable_multi'>
                                        <input
                                            type="checkbox"
                                            value={option.id}
                                            checked={selectedOptions.includes(option.id)}
                                            onChange={(e) => toggleOption(option.id, option.name)}
                                        />
                                        <span  style={{fontFamily: CheckFont(option.name)}} >{option.name}</span>
                                    </div>
                                ) : null
                            );
                        })}
                    </div>
                )}
            </div>

        </>
    );
}

export default MultiSelectEdit;