import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const get = (array,type) => {
    return ({
        type: type,
        payload: array
    })
}

const add = (type) => {
    return ({
        type: type
    })
}
const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}


export const loadRequests = (navigate,setopenInternet) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/notifications/user/request/get`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_REQUESTS))
        }).catch((err) => {
            hasRout(err.response.status,navigate)
            if(err.message == "Network Error" || err.message){
                setopenInternet(true)
            }
        })
    }
}

export const emptyRequest  = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_REQUEST ))
    }
}

export const postRequest = (title,body,medical_rep_ids,setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        const Dataa = new FormData();
        Dataa.append('title', title);
        Dataa.append('body', body);
        for (var i = 0; i < medical_rep_ids.length; i++) {
            Dataa.append(`medical_rep_ids[${i}]`, medical_rep_ids[i]);
        }
        axios.post(`${base_url}api/notifications/user/request`,Dataa, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(add(types.SEND_REQUESTS));
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadRequests())
                setLoad(false)
            }
        }).catch((err) => {
            notifyError(err.response.data.message)
            setLoad(false)
        })
    }
}




