import * as types from "../ActionType";

const initState = {
    workflow: [],
    loadingWorkflow: true,
    oneWorkflow: {},
    loadingoneWorkflow: true
}

const WorkFlowReducer = (state = initState, action) => {
    switch (action.type) {
        case types.ADD_WORK_FLOW:
            return { ...state, loadingWorkflow: true }
        case types.GET_WORK_FLOW:
            return { ...state, workflow: action.payload, loadingWorkflow: false }
        case types.DELETE_WORK_FLOW:
            return { ...state, loadingWorkflow: true }
        case types.EDIT_WORK_FLOW:
            return { ...state, loadingWorkflow: true }
        case types.GET_ONE_WORKFLOW:
            return { ...state, oneWorkflow: action.payload, loadingoneWorkflow: false }
        default:
            return state;
    }
};

export default WorkFlowReducer;