import React from 'react'
import { useDispatch } from "react-redux";
import { linkSpecWithClass } from '../../../Redux/Actions/ActionConfigProduct';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';
import { FunctionsLoading } from '../../Main/Statistics/FunctionsLoading';
import { VariableLink } from './VariableLink';
import SelectWithSearch from '../../General/GeneralComponent/SelectWithSearch';

function LinkSpecWithClass({ langmiamed, t }) {
    let dispatch = useDispatch();
    const { state, setState, handleChangeLink, setLoad, setmaxx } = VariableLink()
    const { classifications, loadingclassifications, specializations, loadingspecializations } = useSelectorCustom()
    const { LoadSpecializationsCustom, LoadClassificationsCustom, PostSpecializationsCustom, DeleteSpecializationsCustom, PostClassificationCustom, DeleteClassificationCustom } = FunctionsLoading()
    const between = (ser) => {
        setState((prevState) => ({
            ...prevState,
            compare_type: ser,
        }));
        if (ser == "between_than") {
            setmaxx('maxx')
        }
    }
    const submit = (event) => {
        event.preventDefault();
        dispatch(linkSpecWithClass(state.specialization_id, state.classification_id, state.compare_type, state.number_of_patients_min, state.number_of_patients_max, setLoad))
    }
    return (
        <div className={`set1 nonOver ${langmiamed == 'ar' ? 'margin_left_50' : "margin_right_50"}`} >
            <h5 className={langmiamed == 'ar' ? "textRight" : ""}>{t('link')}</h5>
            <SelectWithSearch
                load={loadingspecializations}
                typeCss={true}
                title={t('specializations')}
                array={specializations}
                setId={(value) => handleChangeLink(value, 'specialization_id')}
                funb={LoadSpecializationsCustom}
            />
            <SelectWithSearch
                load={loadingclassifications}
                typeCss={true}
                title={t('classifications')}
                array={classifications}
                setId={(value) => handleChangeLink(value, 'classification_id')}
                funb={LoadClassificationsCustom}
            />
            <select onChange={(e) => between(e.target.value)} >
                <option disabled="disabled" selected="selected">{t('choose')}</option>
                <option value='greater_than' >Greater Than</option>
                <option value='less_than' >Less Than</option>
                <option value='equals' >Equal</option>
                <option value='between_than' >Between Than</option>
            </select>
            <input type='number' placeholder={t('min')} onChange={(e) => handleChangeLink(e.target.value, 'number_of_patients_min')} />
            {
                state.maxx !== '' && (
                    <input type='number' placeholder={t('max')} onChange={(e) => handleChangeLink(e.target.value, 'number_of_patients_max')} />
                )
            }
            <button onClick={submit} disabled >
                {
                    state.Load ? "Loading ..." : `${t('save')}`
                }
            </button>
        </div>
    )
}

export default LinkSpecWithClass