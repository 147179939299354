import React, { useState, useEffect } from 'react';
import './Auth.css';
import logo from '../../images/logo_miamed.png';
import med from '../../images/icons/image_login1.png';
import { AuthLogin } from '../../Redux/Actions/ActionAuth';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';

function Login() {
    let {navigate , dispatch}= AbstructVar()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [load, setLoad] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        const storedPassword = localStorage.getItem('password');
        const storedRememberMe = localStorage.getItem('rememberMe');

        if (storedRememberMe === 'true' && storedEmail && storedPassword) {
            setEmail(storedEmail);
            setPassword(storedPassword);
            setRememberMe(true);
        }
    }, []);

    const postLogin = (event) => {
        event.preventDefault();
        if (rememberMe) {
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
            localStorage.setItem('rememberMe', 'true');
        } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
            localStorage.removeItem('rememberMe');
        }
        dispatch(AuthLogin(email, password, navigate, setLoad));
    };

    return (
        <div className='login_auth'>
            <div className='wave'>
                <img src={med} alt="Med" />
            </div>

            <div className='containerauth'>
                <div className='login-content'>
                    <form>
                        <img src={logo} alt="Logo" />
                        <div className='input-div one'>
                            <div className='i'>
                                <i className='fas fa-envelope'></i>
                            </div>
                            <div className='div'>
                                <input
                                    placeholder='Email'
                                    type='email'
                                    className='input'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                        </div>
                        <div className='input-div pass'>
                            <div className='i'>
                                <i className='fas fa-lock'></i>
                            </div>
                            <div className='div'>
                                <input
                                    placeholder='Password'
                                    type='password'
                                    className='input'
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                />
                            </div>
                        </div>
                        <div className='remember-me'>
                            <input
                                type='checkbox'
                                id='rememberMe'
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <label htmlFor='rememberMe'>Remember Me</label>
                        </div>
                        <button onClick={postLogin} className='btn_auth'>
                            {load ? 'Loading ...' : 'Login'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;