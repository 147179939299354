import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { loadStatisticsWorkPlan } from '../../../Redux/Actions/ActionStatistics';
import ChartComponent from '../../WorkPlans/VisitesWorkPlan/ChartComponent';
import '../Main.css'
import FilterStatistics from './FilterStatistics';

function StatisticsWorkPlans({ langmiamed, t }) {
  let dispatch = useDispatch();
  const statisticsWorkPlan = useSelector(state => state.statisticss.statisticsWorkPlan)
  useEffect(() => {
    dispatch(loadStatisticsWorkPlan())
  }, [])
  return (
    <>
      <h4 className={`workplanrepo ${langmiamed == 'ar' ? "textRight" : ""}`} >{t('workplanrepo')}</h4>
      {/* <FilterStatistics t={t} langmiamed={langmiamed} params='work_plan' /> */}
      <div className={`bottom_analytics `} dir={langmiamed == 'ar' ? "rtl" : "ltr"} >
        <div className={`one_bottom_analytics ${langmiamed === 'ar' ? "margin_left_30" : "margin_right_30"} `} >
          <span>{t('alltaskscount')}</span>
          <span className='alltaskscount' >{statisticsWorkPlan.all_tasks_count}</span>
        </div>
        <div className={`one_bottom_analytics ${langmiamed === 'ar' ? "margin_left_30" : "margin_right_30"} `}>
          <span>{t('delivered')}</span>
          <span className='alltaskscount' >{statisticsWorkPlan.delivered_product_count}</span>
        </div>
      </div>
      <div className='allcharts'  style={{ marginTop: "10px" }} >
        <div className='onechart' >
          <ChartComponent langmiamed={langmiamed} color1="#50b798" num1={statisticsWorkPlan.visits_count} num2={statisticsWorkPlan.not_visits_count} name1={t('visits_count')} name2={t('not_visits_count')} />
        </div>
        <div className='onechart' >
          <ChartComponent langmiamed={langmiamed} color1='#4285C5' num1={statisticsWorkPlan.marketing_tools_count} num2={statisticsWorkPlan.medicine_samples_count} name1={t('marketing_tools_count')} name2={t('medicine_samples_count')} />
        </div>
        <div className='onechart' >
          <ChartComponent langmiamed={langmiamed} color1='#f67eb4' num1={statisticsWorkPlan.visits_match_count} num2={statisticsWorkPlan.visits_not_match_count} name1={t('visits_match_count')} name2={t('visits_not_match_count')} />
        </div>
      </div>
    </>

  )
}

export default StatisticsWorkPlans