import { deleteClassification, deleteSpecialization, deleteTargetType, loadClassifications, loadSpecializations, loadTargetType, postClassification, postSpecialization, postTargetType } from '../../../Redux/Actions/ActionTargetGroup'
import { loadMedicalRep, loadTGbyMedicalRep } from '../../../Redux/Actions/ActionMedicalRep'
import { loadReportType } from '../../../Redux/Actions/ActionReportType'
import { AbstructVar } from '../../General/GeneralComponent/AbstructVar'
import { loadCities } from '../../../Redux/Actions/ActionCountries'
import { loadworkFlow } from '../../../Redux/Actions/ActionWorkFlow'
import { loadSeniorSupervisor } from '../../../Redux/Actions/ActionAuth'
import { deleteMeasurement, loadMeasurements, loadProductCat, postMeasurement } from '../../../Redux/Actions/ActionConfigProduct'
import { loadGovernorates } from '../../../Redux/Actions/ActionGovernorates'

export const FunctionsLoading = () => {
    let {dispatch} = AbstructVar()
    const LoadTargetTypeCustom = () => {
        dispatch(loadTargetType())
    }
    const LoadTargetByMedicalRepCustom = (id) => {
        dispatch(loadTGbyMedicalRep(id))
    }
    const LoadMedicalRepCustom = () => {
        dispatch(loadMedicalRep())
    }
    const LoadReportTypeCustom = () => {
        dispatch(loadReportType())
    }
    const LoadSpecializationsCustom = () => {
        dispatch(loadSpecializations())
    }
    const LoadClassificationsCustom = () => {
        dispatch(loadClassifications())
    }
    const LoadCitiesCustom = () => {
        dispatch(loadCities())
    }
    const LoadGovernoratesCustom = (id) => {
        dispatch(loadGovernorates(id))
    }
    const LoadworkFlowCustom = () => {
        dispatch(loadworkFlow())
    }
    const LoadSeniorSupervisorCustom = (user_role,setLoad) => {
        dispatch(loadSeniorSupervisor(user_role,setLoad))
    }
    const LoadMeasurementsCustom = () => {
        dispatch(loadMeasurements())
    }
    const LoadProductCatCustom = () => {
        dispatch(loadProductCat())
    }
    const PostUnit_of_measureCustom = (neww, setCreateView, setLoadUnit) => {
        dispatch(postMeasurement(neww, setCreateView, setLoadUnit))
    }
    const DeleteUnit_of_measureCustom = (id, setLoadUnit) => {
        dispatch(deleteMeasurement(id, setLoadUnit))
    }
    const PostSpecializationsCustom = (neww, setCreateView, setLoadUnit) => {
        dispatch(postSpecialization(neww, setCreateView, setLoadUnit))
    }
    const DeleteSpecializationsCustom = (id, setLoadUnit) => {
        dispatch(deleteSpecialization(id, setLoadUnit))
    }
    const PostClassificationCustom = (neww, setCreateView, setLoadUnit) => {
        dispatch(postClassification(neww, setCreateView, setLoadUnit))
    }
    const DeleteClassificationCustom = (id, setLoadUnit) => {
        dispatch(deleteClassification(id, setLoadUnit))
    }
    const PostTargetTypeCustom = (neww, setCreateView, setLoadUnit) => {
        dispatch(postTargetType(neww, setCreateView, setLoadUnit))
    }
    const DeleteTargetTypeCustom = (id, setLoadUnit) => {
        dispatch(deleteTargetType(id, setLoadUnit))
    }
    return {
        LoadTargetTypeCustom,
        LoadMedicalRepCustom,
        LoadReportTypeCustom,
        LoadSpecializationsCustom,PostSpecializationsCustom,DeleteSpecializationsCustom,
        LoadCitiesCustom,
        LoadGovernoratesCustom,
        LoadworkFlowCustom,
        LoadSeniorSupervisorCustom,
        LoadMeasurementsCustom,
        LoadProductCatCustom,
        PostUnit_of_measureCustom,
        DeleteUnit_of_measureCustom,
        LoadClassificationsCustom,PostClassificationCustom,DeleteClassificationCustom,
        PostTargetTypeCustom,DeleteTargetTypeCustom,
        LoadTargetByMedicalRepCustom
    }
}