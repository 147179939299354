import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";

const getReportType = (reportType) => {
    return ({
        type: types.GET_REPORT_TYPE,
        payload: reportType
    })
}

const addReportType = () => {
    return ({
        type: types.ADD_REPORT_TYPE
    })
}

const delReportType = () => {
    return ({
        type: types.DELETE_REPORT_TYPE
    })
}
const editReportType = () => {
    return ({
        type: types.EDIT_REPORT_TYPE
    })
}

export const loadReportType = () => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        let langmiamed = localStorage.getItem('langmiamed');
        axios.get(`${base_url}api/report-types`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                "lang": langmiamed
            }
        }).then((res) => {
            dispatch(getReportType(res.data.data))
        }).catch((err) => {
        })
    }
}

export const postReportType = (name,description,service,setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.post(`${base_url}api/report-types`, {
            name,description,service
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(addReportType());
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadReportType())
                setLoad(false)
            }
        }).catch((err) => {
            notifyError(err.response.data.message)
            setLoad(false)
        })
    }
}

export const deleteReportType = (id,setdeletee,setloadDel) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        setloadDel(true)
        axios.delete(`${base_url}api/report-types/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(delReportType());
                dispatch(loadReportType());
                setdeletee(false)
                setloadDel(false)
            }
        }).catch((err) => {
            setdeletee(false)
            notifyError(err.response.data.message)
            setloadDel(false)
        })
    }
}

export const putReportType = (id,name,description,service,setLoad,setreport_type_id) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.put(`${base_url}api/report-types/${id}`, {
            name,description,service
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(editReportType());
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadReportType())
                setLoad(false)
                setreport_type_id(null)
            }
        }).catch((err) => {
            notifyError(err.response.data.message)
            setLoad(false)
            setreport_type_id(null)
        })
    }
}