import { useState } from "react"

export const VariableLink = () => {
    const [state, setState] = useState({
        specialization_id: "",
        classification_id: "",
        compare_type: "",
        number_of_patients_min: '',
        number_of_patients_max: "",
        Load: false,
        maxx: ""
    })
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setmaxx = (value) => {
        setState((prevState) => ({
            ...prevState,
            maxx: value,
        }));
    };
    const handleChangeLink = (value, variableName) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    return {
        ...state,
        state,
        setState,
        handleChangeLink,
        setmaxx,
        setLoad
    }
}