import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

export const AbstructVar = () => {
    let langmiamed = localStorage.getItem('langmiamed');
    let dispatch = useDispatch();
    let navigate = useNavigate();
    return {
        dispatch,
        navigate,
        langmiamed
    }
}

export const renderContentHTML = (content) => {
    return { __html: content };
}