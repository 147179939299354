import { useState } from "react"

export const VariableWorkPlans = () => {
    const [state, setState] = useState({
        startDate: "",
        endDate: "",
        Load: false,
        medical_rep_id: "",
        medical_rep_name:'',
        note: '',
        days_duration: ''
    })
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const handleChangeWorkPlans = (value, variableName) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const handleChangeDate = (range) => {
        const [startDatee, endDatee] = range;
        setState((prevState) => ({
            ...prevState,
            startDate: startDatee,
            endDate: endDatee
        }));
    };
    return {
        ...state , 
        state , 
        setState,
        handleChangeWorkPlans,
        setLoad,
        handleChangeDate
    }
}