import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";

const MultiSelectForReports = ({
  target_types,
  setReportsIds,
  selectedTargets,
  setSelectedTargets,
}) => {
  let { t } = useTranslation();
  let { langmiamed } = AbstructVar();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      borderColor: "#ccc",
      boxShadow: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "gray",
      textAlign: langmiamed === "ar" ? "right" : "left",
      fontFamily: "GE SS TWO",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0",
      borderRadius: "4px",
      padding: "2px 4px",
    }),
    option: (provided, state) => ({
      ...provided,

      fontFamily: "GE SS TWO",
    }),
  };

  const targetTypeOptions = target_types?.map((el) => {
    return {
      label: t(el.name),
      value: el.id,
    };
  });

  const onChange = (selectedOptions) => {
    const ids = selectedOptions.map((option) => option.value);
    setReportsIds(ids);
    setSelectedTargets(selectedOptions);
  };

  return (
    <div>
      <Select
        options={targetTypeOptions}
        isMulti
        value={selectedTargets}
        onChange={(selectedValues) => {
          const ids = selectedValues.map((option) => option.value);
          setReportsIds(ids);
          setSelectedTargets(selectedValues);
        }}
        placeholder={t("Choose Target Types")}
        styles={{
          placeholder: (provided) => ({
            ...provided,
            color: "gray",
            textAlign: langmiamed === "ar" ? "right" : "left",
            fontFamily: "Open Sans, sans-serif",
          }),
          menu: (provided) => ({
            ...provided,
            textAlign: langmiamed === "ar" ? "right" : "left",
          }),
          control: (provided) => ({
            ...provided,
            fontFamily: "Open Sans, sans-serif",
            fontSize: "13px !important",
          }),
          option: (provided, state) => ({
            ...provided,
            fontSize: "13px !important",

            // fontFamily: "GE SS TWO",
          }),
        }}
      />
    </div>
  );
};

export default MultiSelectForReports;
