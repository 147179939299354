import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifyErrorNetWork,
  notifysuccess,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import NoInternet from "../../Components/General/NotPermission/NoInternet";
import { hasRout } from "../../Components/General/UseRout";

const addSpecialization = () => {
  return {
    type: types.ADD_SPECIALIZATION,
  };
};
const getSpecializations = (specializations) => {
  return {
    type: types.GET_ALL_SPECIALIZATIONS,
    payload: specializations,
  };
};
const delSpecialization = () => {
  return {
    type: types.DELETE_SPECIALIZATION,
  };
};

const addClassification = () => {
  return {
    type: types.ADD_CLASSIFICATION,
  };
};
const getClassifications = (classifications) => {
  return {
    type: types.GET_ALL_CLASSIFICATIONS,
    payload: classifications,
  };
};
const delClassification = () => {
  return {
    type: types.DELETE_CLASSIFICATION,
  };
};

const addTargetType = () => {
  return {
    type: types.ADD_TARGET_TYPE,
  };
};
const getTargetType = (target_types) => {
  return {
    type: types.GET_ALL_TARGET_TYPE,
    payload: target_types,
  };
};
const delTargetType = () => {
  return {
    type: types.DELETE_TARGET_TYPE,
  };
};
const addTargetGroup = () => {
  return {
    type: types.ADD_TARGET_GROUP,
  };
};
const getTargetGroup = (target_group) => {
  return {
    type: types.GET_ALL_TARGET_GROUP,
    payload: target_group,
  };
};
const delTargetGroup = () => {
  return {
    type: types.DELETE_TARGET_GROUP,
  };
};
const editTargetGroup = () => {
  return {
    type: types.EDIT_TARGET_GROUP,
  };
};
const emptyGeneral = (empty, typee) => {
  return {
    type: typee,
    payload: empty,
  };
};
const downloadTargetGroup = () => {
  return {
    type: types.DOWNLOAD_TARGET_GROUP_WITHOUT_FILTER,
  };
};
const downloadTargetGroupWith = () => {
  return {
    type: types.DOWNLOAD_TARGET_GROUP_WITH_FILTER,
  };
};

const getTGMed = (target_group) => {
  return {
    type: types.GET_TARGETT_MEDICAL,
    payload: target_group,
  };
};

export const loadSpecializations = () => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    let langmiamed = localStorage.getItem("langmiamed");
    axios
      .get(`${base_url}api/specializations`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: langmiamed,
        },
      })
      .then((res) => {
        dispatch(getSpecializations(res.data.data));
      })
      .catch((err) => {
      });
  };
};

export const postSpecialization = (name, setCreateView, setLoadSpec) => {
  return function (dispatch) {
    setLoadSpec(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/specializations`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addSpecialization());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadSpecializations());
          setCreateView("choose");
          setLoadSpec(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setCreateView("choose");
        setLoadSpec(false);
      });
  };
};
export const deleteSpecialization = (id, setLoadSpec) => {
  return function (dispatch) {
    setLoadSpec(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .delete(`${base_url}api/specializations/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delSpecialization());
          dispatch(loadSpecializations());
          setLoadSpec(false);
        }
      })
      .catch((err) => {
        setLoadSpec(false);
        notifyError(err.response.data.message);
      });
  };
};

export const loadClassifications = () => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/classifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getClassifications(res.data.data));
      })
      .catch((err) => {
      });
  };
};

export const postClassification = (name, setCreateView1, setLoadClass) => {
  return function (dispatch) {
    setLoadClass(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/classifications`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addClassification());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadClassifications());
          setCreateView1("choose");
          setLoadClass(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoadClass(false);
      });
  };
};
export const deleteClassification = (id, setLoadClass) => {
  return function (dispatch) {
    setLoadClass(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .delete(`${base_url}api/classifications/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delClassification());
          dispatch(loadClassifications());
          setLoadClass(false);
        }
      })
      .catch((err) => {
        setLoadClass(false);
        notifyError(err.response.data.message);
      });
  };
};

export const loadTargetType = () => {
  return function (dispatch) {
    let langmiamed = localStorage.getItem("langmiamed");
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/target-types`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: langmiamed,
        },
      })
      .then((res) => {
        dispatch(getTargetType(res.data.data));
      })
      .catch((err) => {
      });
  };
};

export const postTargetType = (name, setCreateView2, setLoadType) => {
  return function (dispatch) {
    setLoadType(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/target-types`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addTargetType());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadTargetType());
          setCreateView2("choose");
          setLoadType(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoadType(false);
      });
  };
};
export const deleteTargetType = (id, setLoadType) => {
  return function (dispatch) {
    setLoadType(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .delete(`${base_url}api/target-types/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delTargetType());
          dispatch(loadTargetType());
          setLoadType(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoadType(false);
      });
  };
};

export const postTargetGroup = (
  currentPage,
  itemsPerPage,
  name,
  description,
  address,
  lat,
  lng,
  fixed_phone,
  phone_number,
  opening_time,
  closing_time,
  city_id,
  specialization_id,
  classification_id,
  target_type_id,
  medical_rep_name,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/target-groups`,
        {
          name,
          description,
          address,
          lat,
          lng,
          fixed_phone,
          phone_number,
          opening_time,
          closing_time,
          city_id,
          specialization_id,
          classification_id,
          target_type_id,
          medical_rep_name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addTargetGroup());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadTargetGroup(currentPage, itemsPerPage));
          setLoad(false);
          setOpen(false)
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const loadTargetGroup = (page, per_page, setopenInternet, navigate) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    if (page != null && per_page != null) {
      axios
        .get(`${base_url}api/target-groups?page=${page}&per_page=${per_page}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
            dispatch(getTargetGroup(res.data.data));
        })
        .catch((err) => {
            hasRout(err.response.status, navigate);
          if (err.message == "Network Error" || err.message) {
            setopenInternet(true);
          }
        });
    } else {
      axios
        .get(`${base_url}api/target-groups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
            dispatch(getTargetGroup(res.data.data));
        })
        .catch((err) => {
            hasRout(err.response.status, navigate);
          if (err.message == "Network Error" || err.message) {
            setopenInternet(true);
          }
        });
    }
  };
};
export const emptytargetGroup = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_ALL_TARGET_GROUP));
  };
};

export const deleteTargetGroup = (
  id,
  setdeletee,
  currentPage,
  itemsPerPage,
  setisDelete,
  isFilter,
  setloadDel
) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/target-groups/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delTargetGroup());
          if (isFilter == "filter") {
            setisDelete("deleted");
          } else {
            dispatch(loadTargetGroup(currentPage, itemsPerPage));
          }
          setdeletee(false);
          setloadDel(false);
          setisDelete("");
        }
      })
      .catch((err) => {
        setdeletee(false);
        setloadDel(false);
        setisDelete("");
        notifyError(err.response.data.message);
      });
  };
};
export const putTargetGroup = (
  isFilter,
  setisEdit,
  currentPage,
  itemsPerPage,
  id,
  name,
  description,
  address,
  lat,
  lng,
  fixed_phone,
  phone_number,
  opening_time,
  closing_time,
  city_id,
  specialization_id,
  classification_id,
  target_type_id,
  medical_rep_name,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .put(
        `${base_url}api/target-groups/${id}`,
        {
          name,
          description,
          address,
          lat,
          lng,
          fixed_phone,
          phone_number,
          opening_time,
          closing_time,
          city_id,
          specialization_id,
          classification_id,
          target_type_id,
          medical_rep_name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(editTargetGroup());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          if (isFilter == "filter") {
            setisEdit("edited");
          } else {
            dispatch(loadTargetGroup(currentPage, itemsPerPage));
          }
          setLoad(false);
          setOpen(false);
          setisEdit("");
        }
      })
      .catch((err) => {
        setisEdit("");
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const filterrTargetGroup = (
  page,
  per_page,
  search_value,
  target_type_id,
  city_id,
  specialization_id,
  medical_rep_name,
  created_at,
  setLoad,
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    if (page != "" && per_page != "") {
      axios
        .get(
          `${base_url}api/target-groups?filter[specialization_id]=${specialization_id}&filter[city_id]=${city_id}&filter[target_type_id]=${target_type_id}&filter[search_value]=${search_value}&filter[created_at]=${created_at}&page=${page}&per_page=${per_page}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            dispatch(getTargetGroup(res.data.data));
          setLoad(false);
        })
        .catch((err) => {
            setLoad(false);
        });
    } else {
      axios
        .get(
          `${base_url}api/target-groups?filter[specialization_id]=${specialization_id}&filter[city_id]=${city_id}&filter[target_type_id]=${target_type_id}&filter[search_value]=${search_value}&filter[medical_rep_name]=${medical_rep_name}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            dispatch(getTargetGroup(res.data.data));
          setLoad(false);
        })
        .catch((err) => {
            setLoad(false);
        });
    }
  };
};

export const downloadTG = (setloadFile,lang,setOpen) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadFile(true);
    axios
      .get(`${base_url}api/target-groups/export-excel?&lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadTargetGroup());
          setloadFile(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        setloadFile(false);
      });
  };
};

export const downloadTGWith = (
  lang,
  specialization_id,
  city_id,
  target_type_id,
  setloadFile,
  setOpen
) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadFile(true);
    axios
      .get(
        `${base_url}api/target-groups/export-excel?filter[specialization_id]=${specialization_id}&filter[city_id]=${city_id}&filter[target_type_id]=${target_type_id}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadTargetGroupWith());
          setloadFile(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        setloadFile(false);
      });
  };
};

export const LoadTgMed = (med_id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/medical-rep/${med_id}/unassign/target-groups`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getTargetGroup(res.data.data));
      })
      .catch((err) => {
      });
  };
};

export const emptyTgMed = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_TARGET_GROUP_BY_MEDICAL));
  };
};
