import React, { useEffect, useState } from 'react'
import Table2 from '../../Table2/Table2'
import InnerLoader from '../../General/InnerLoader'
import './VisitesWorkPlan.css'
import { useDispatch, useSelector } from "react-redux";
import { downloadReportss, downloadReportssPro, emptyReportsPro, loadReportss, loadReportssPro } from '../../../Redux/Actions/ActionReport'
import { useParams } from 'react-router-dom'
import NoData from '../../General/NoData/NoData';
import { CheckFont } from '../../General/GeneralComponent/CheckLang';

function VisitesProducts({ langmiamed, t }) {
    let dispatch = useDispatch();
    const reportsPro = useSelector(state => state.reportss.reportsPro)
    const loadingReportsPro = useSelector(state => state.reportss.loadingReportsPro)
    const [loadFile, setloadFile] = useState(false)
    let { id } = useParams()
    useEffect(() => {
        dispatch(loadReportssPro(id))
        return(()=>{
            dispatch(emptyReportsPro())
        })
    }, [])
    const columns1 = [
        {
            name: <span className="color_spann">{t('Product_Name')}</span>, sortable: true,
            cell: (row) => (
                <span  style={{fontFamily: CheckFont(row.product_name)}}>{row['product_name']}</span>
            )
        }, {
            name: <span className="color_spann">{t('image')}</span>, sortable: true,
            cell: (row) => (
                <div className='img_destination' >
                    {
                        row.media_urls.length == 0 ?
                            <div className='defaultt'></div> :
                            <>
                                <img src={row.media_urls[0].url} />
                            </>
                    }
                </div>
            )
        },
        {
            name: <span className="color_spann">{t('internalRef')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.internal_reference)}}>{row['internal_reference']}</span>
            )
        },
        {
            name: <span className="color_spann">{t('product_type')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.product_type)}}>{row['product_type']}</span>
            )
        },
        {
            name: <span className="color_spann">{t('category')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.category)}}>{row['category']}</span>
            )
        },
        {
            name: <span className="color_spann">{t('delivered')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.total_quantity_delivered)}}>{row['total_quantity_delivered']}</span>
            )
        }
    ]
    const downloadExcel = () => {
        dispatch(downloadReportssPro(id, setloadFile))
    }
    return (
        <>
            <div className='users' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                <div className='gen_filter' onClick={downloadExcel} >
                    {
                        loadFile ? "Loading ..." : `${t('excel')}`
                    }
                </div>
                {
                    loadingReportsPro == false ? (
                        <Table2 col={columns1} roo={reportsPro} />
                    ) : <div className='div_loadd' ><InnerLoader /></div>
                }
            </div>
        </>
    )
}

export default VisitesProducts

