import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import './VisitsByMedRep.css'
import Page_Header from '../../General/Page_Header';
import InnerLoader from '../../General/InnerLoader';
import { useParams } from 'react-router-dom';
import { deleteVisiteMedicalRep, emptyVisitesMedicalRep, loadvisitesMedicalRep } from '../../../Redux/Actions/ActionMedicalRep';
import { useNavigate } from 'react-router-dom'
import NoInternet from '../../General/NotPermission/NoInternet';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import Table2 from '../../Table2/Table2';
import External_In_Map from '../../External_Visites/External_In_Map';
import Warning from '../../General/Warning_Delete/Warning';
import ProductsVisitsByMedRep from './ProductsVisitsByMedRep';
import FilterVisitsByMedRep from './FilterVisitsByMedRep';

function VisitsByMedRep() {
    const [openInternet, setopenInternet] = useState(false)
    let langmiamed = localStorage.getItem('langmiamed');
    const [t, i18n] = useTranslation();
    let navigate = useNavigate()
    let dispatch = useDispatch();
    const [openMap, setOpenMap] = useState(false)
    const [openProducts, setOpenProducts] = useState(false)
    const [visitId, setvisitId] = useState(false)
    const [lat, setlat] = useState(false)
    const [lng, setlng] = useState(false)
    let { id } = useParams()
    let { name } = useParams()
    const visitsMedRep = useSelector(state => state.medical_rep.visitsMedRep)
    const loadingvisitsMedRep = useSelector(state => state.medical_rep.loadingvisitsMedRep)
    const [deletee, setdeletee] = useState(false);
    const [arrW, setarrW] = useState([]);
    const [arr, setarr] = useState([]);
    const [loadDel, setloadDel] = useState(false);
    const handleDelete = (idd) => {
        dispatch(deleteVisiteMedicalRep(idd,id, setdeletee, setloadDel))
    }
    const warning = (row) => {
        setarrW(row)
        setdeletee(true);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        i18n.changeLanguage(localStorage.getItem('langmiamed'));
        dispatch(loadvisitesMedicalRep(id, navigate, setopenInternet))
        return (() => {
            dispatch(emptyVisitesMedicalRep())
        })
    }, []);
    const storeMap = (lat, lng) => {
        setlat(lat)
        setlng(lng)
        setOpenMap(true)
    }
    const viewProducts = (e,id) => {
        e.preventDefault()
        setvisitId(id)
        setOpenProducts(true)
    }
    const columns1 = [

        {
            name: <span className="color_spann">{t('target_group')}</span>, sortable: true,
            cell: (row) => (
                <span>{row.targetGroup && row.targetGroup.name}</span>
            )
        },
        {
            name: <span className="color_spann">{t('address')}</span>, sortable: true,
            cell: (row) => (
                <span>{row.targetGroup && row.targetGroup.address}</span>
            )
        },
        {
            name: <span className="color_spann">{t('target_type')}</span>, sortable: true,
            cell: (row) => (
                <span>{row.targetGroup.target_type.name}</span>
            )
        },
        {
            name: <span className="color_spann">{t('location')}</span>, sortable: true,
            cell: (row) => (
                <span>
                    {
                        row.is_site_match ? <i className="las la-check checkmatch"></i> : <i className="las la-times falsematch"></i>
                    }
                </span>
            )
        },
        {
            name: <span className="color_spann">{t('reportType')}</span>, sortable: true,
            cell: (row) => (
                <span>{row.reportType && row.reportType.name}</span>
            )
        },
        {
            name: <span className="color_spann">{t('date')}</span>, sortable: true,
            cell: (row) => (
                <span>{moment(row['created_at']).format("YYYY-MM-DD")}</span>
            )
        },
        {
            name: <span className="color_spann">{t('houre')}</span>, sortable: true,
            cell: (row) => (
                <span>{moment(row['created_at']).format("HH:mm a")}</span>
            )
        },
        {
            name: <span width="100%" className="color_spann">{t('description')}</span>, sortable: true,
            cell: (row) => (
                <span>{row['details']}</span>
            ), width: "250px"
        },
        {
            name: <span className="color_spann">{t('products')}</span>,
            cell: (row) => (
                <i className="las la-eye icon_edit_table" onClick={(e) => viewProducts(e,row.id)}></i>
            )
        },
        {
            name: <span className="color_spann">{t('map')}</span>,
            cell: (row) => (
                <i className="las la-map-marked icon_edit_table" onClick={() => storeMap(row.lat, row.lng)}  ></i>
            )
        },
        {
            name: <span className="color_spann">{t('actions')}</span>,
            cell: (row) => (
                <i class="las la-times-circle icon_del_table" onClick={() => warning(row)} ></i>
            )
        }
    ]
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={`${t('delegates')}`} name={name} path={`${t('home')} / ${t('delegates')}  `} />
                </main>
                <div className="page-content" >
                    <FilterVisitsByMedRep id={id} t={t} langmiamed={langmiamed} setopenInternet={setopenInternet} />
                    <div className='users' dir={langmiamed == "ar" ? "rtl" : "ltr"}  >
                        {
                            loadingvisitsMedRep == false ? (
                                <Table2 col={columns1} roo={visitsMedRep} />
                            ) : <div className='div_loadd' ><InnerLoader /></div>
                        }
                    </div>
                </div>
            </div>
            {
                openMap && (
                    <External_In_Map open={openMap} setOpen={setOpenMap} lat={lat} lng={lng} />
                )
            }
            {
                openInternet && (
                    <NoInternet open={openInternet} setOpen={setopenInternet} />
                )
            }
            {
                deletee == true ? (
                    <Warning loadDel={loadDel} arr={arrW} open={deletee} setOpen={setdeletee} handleDelete={handleDelete} />
                ) : <></>
            }
            {
                openProducts && (
                    <ProductsVisitsByMedRep id={visitId} langmiamed={langmiamed} t={t}  open={openProducts} setOpen={setOpenProducts}/>
                )
            }
        </>
    )
}

export default VisitsByMedRep