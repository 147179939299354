import { useState } from "react";
import Select from "react-select";

export const Input = (props) => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(true);

  const Checkbox = ({ children, ...props }) => (
    <label style={{ marginRight: "1em" }}></label>
  );

  return (
    <>
      <Select
        isMulti
        styles={{
          input: (base) => ({
            ...base,
          }),
          indicatorsContainer: (base) => ({
            ...base,
            backgroundColor: "#F1F3F6",
          }),
          valueContainer: (base) => ({
            ...base,
            fontSize: "17px",
            background: props.InputColor === "failure" ? "#FDF2F2" : "#F1F3F6",
            width: "100%",
            color: props.InputColor === "failure" ? "#771d1d" : "#555555",
            padding: "0.35rem 0.75rem 0.35rem 0.75rem",
            border: props.InputColor === "failure" ? "2px solid #F05252" : "",
          }),
        }}
        className="basic-single  mt-10"
        classNamePrefix="select"
        isDisabled={props.isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        placeholder={props.placeholder}
        options={props.options}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
      />
      <div
        style={{
          color: "hsl(0, 0%, 40%)",
          display: "inline-block",
          fontSize: 12,
          fontStyle: "italic",
          marginTop: "1em",
        }}
      >
        <Checkbox
          checked={isClearable}
          onChange={() => setIsClearable((state) => !state)}
        >
          Clearable
        </Checkbox>
        <Checkbox
          checked={isSearchable}
          onChange={() => setIsSearchable((state) => !state)}
        >
          Searchable
        </Checkbox>
        <Checkbox
          checked={isLoading}
          onChange={() => setIsLoading((state) => !state)}
        >
          Loading
        </Checkbox>
        <Checkbox checked={isRtl} onChange={() => setIsRtl((state) => !state)}>
          RTL
        </Checkbox>
      </div>
    </>
  );
};
