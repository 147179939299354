import React from "react";
import { useState } from "react";
import Product_category from "../Products/Product_Category/Product_category";
import { useTranslation } from "react-i18next";
import { CheckFont } from "./GeneralComponent/CheckLang";

function ActionTop({
  exportToExcel,
  langmiamed,
  array_action,
  FunctionCustum,
  FunctionCustumMar,
  is_view,
  is_product,
  isMarketingTools,
  open,
  setOpen,
  is_target,
  is_user,
  is_medical,
  is_workplan
}) {
  const [t, i18n] = useTranslation();
  // const [open, setOpen] = useState(false);
  return (
    <>
      <div className="files_target" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
        {array_action.map((aa) => {
          return (
            <div key={aa.id} className="div_tooltip">
              <div className="tooltip">
                {langmiamed == "ar" ? (
                  <span style={{ fontFamily: CheckFont(aa.name_ar) }}>
                    {aa.name_ar}
                  </span>
                ) : (
                  <span style={{ fontFamily: CheckFont(aa.name) }}>
                    {aa.name}
                  </span>
                )}
              </div>

              {aa.id == 1 ? (
                <div className="tip_img" onClick={exportToExcel}>
                  <img src={aa.icon} className="excel_img" />
                </div>
              ) : aa.id == 2 ? (
                is_product ? (
                  <div className="tip_img" onClick={FunctionCustum}>
                    <img className="pointer_img" src={aa.icon} />
                  </div>
                ) : (
                  <></>
                )
              ) : aa.id == 3 ? (
                is_view ? (
                  <div className="tip_img" onClick={FunctionCustum}>
                    <img className="plus_img" src={aa.icon} />
                  </div>
                ) : (
                  <></>
                )
              )
              
              : aa.id == 4 ? (
                isMarketingTools ? (
                  <div className="tip_img" onClick={FunctionCustumMar}>
                    <img className="plus_img" src={aa.icon} />
                  </div>
                ) : (
                  <></>
                )
              ) 
              
              : aa.id == 5 ? (
                is_target ? (
                  <div className="tip_img"  onClick={FunctionCustum}>
                    <img className="plus_img" src={aa.icon} />
                  </div>
                ) : (
                  <></>
                )
              ) 
              : aa.id == 7 ? (
                is_medical ? (
                  <div className="tip_img"  onClick={FunctionCustum}>
                    <img className="plus_img" src={aa.icon} />
                  </div>
                ) : (
                  <></>
                )
              ) 
              : aa.id == 8 ? (
                is_workplan ? (
                  <div className="tip_img"  onClick={FunctionCustum}>
                    <img className="plus_img" src={aa.icon} />
                  </div>
                ) : (
                  <></>
                )
              ) 
              : aa.id == 6 ? (
                is_user ? (
                  <div className="tip_img"  onClick={FunctionCustum}>
                    <img className="plus_img" src={aa.icon} />
                  </div>
                ) : (
                  <></>
                )
              ) 
              
              
              : (
                <div className="tip_img">
                  <img src={aa.icon} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      {open && (
        <Product_category
          open={open}
          setOpen={setOpen}
          langmiamed={langmiamed}
          t={t}
        />
      )}
    </>
  );
}

export default ActionTop;

/*
 */
