import React,{useState} from 'react'
import { useDispatch } from "react-redux";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { postCityToCountry } from '../../../Redux/Actions/ActionCountries';
import { CheckFont } from '../../General/GeneralComponent/CheckLang';

function AddCity({show_id,id,open ,setOpen, langmiamed ,t}) {
    let dispatch = useDispatch();
    const [name , setName] = useState('')
    const [Load , setLoad] = useState(false)
    const FunSend = (e) => {
        e.preventDefault()
        dispatch(postCityToCountry(name,id,setLoad,show_id,setOpen))
    }
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            center
            classNames={{
                modal: "addPop",
            }}
            focusTrapped={false}
        >
            <div className='div_addPop' dir={langmiamed == 'ar'? "rtl" : "ltr"}  >
                <h3 style={{fontFamily: CheckFont(t('addCity'))}} className={langmiamed == 'ar'? "textRight": ""} >{t('addCity')}</h3>
                <input style={{fontFamily: CheckFont(t('enterCityName'))}} placeholder={t('enterCityName')} type="text" onChange={(e)=> setName(e.target.value)} />
                <button disabled={Load} onClick={FunSend} style={{fontFamily: CheckFont(t('save'))}} >
                    {
                        Load ? t("Loading ...") : `${t('save')}`
                    }
                </button>
            </div>
        </RModal>
    )
}

export default AddCity