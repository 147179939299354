import React from 'react';
import './General.css';
import lod from '../../images/favicon.png'

function Loader() {
    return (
        <section className="loader">
            <img src={lod} />
        </section>
    );
}

export default Loader;