import * as types from "../ActionType";

const initState = {
    reportType: [],
    loadingReportType: true,
}

const ReportTypeReducer = (state = initState, action) => {
    switch (action.type) {
        case types.ADD_REPORT_TYPE:
            return { ...state, loadingReportType: true }
        case types.GET_REPORT_TYPE:
            return { ...state, reportType: action.payload, loadingReportType: false }
        case types.DELETE_REPORT_TYPE:
            return { ...state, loadingReportType: true }
        case types.EDIT_REPORT_TYPE:
            return { ...state, loadingReportType: true }
        default:
            return state;
    }
};

export default ReportTypeReducer;