import React, { useState } from 'react'
import useClickOutside from '../../../useClickOutside';
import { putProductCat } from '../../../Redux/Actions/ActionConfigProduct';
import { useDispatch } from "react-redux";

function EditProductCat({ t, arr, setproduct_cat_id }) {
    let dispatch = useDispatch();
    const [name, setName] = useState(arr.name)
    const [font_family, setfont_family] = useState(arr.font_family)
    const [Load, setLoad] = useState(false)
    let domNode = useClickOutside(() => {
        setproduct_cat_id(null);
    });
    const submit = event => {
        event.preventDefault();
        dispatch(putProductCat(arr.id, name, font_family, setLoad,setproduct_cat_id))
    }
    return (
        <div className='one_info_proCat' ref={domNode} >
            <div className='div_info_proCat' >
                <input className='input_edit_proCat' defaultValue={name} onChange={(e)=> setName(e.target.value)} />
                <select className='input_edit_proCat' value={font_family} onChange={(e) => setfont_family(e.target.value)}>
                    <option disabled="disabled" selected="selected">{t('product_type')}</option>
                    <option value='AMPOULES' >AMPOULES</option>
                    <option value='EYEDROPS' >EYE DROPS</option>
                    <option value='IVS' >IVS</option>
                    <option value='NASALDROPS' >NASAL DROPS</option>
                </select>
            </div>
            <div className='action_proCat' >
                {
                    Load ? <i className='las la-spinner icon_edit_table' ></i> :
                        <i className="las la-save edit_proCat" onClick={submit} ></i>
                }
            </div>
        </div>
    )
}

export default EditProductCat