import React from "react";
import Navbar from "../Layout/Navbar";
import Sidebar from "../Layout/Sidebar";

function AllLayout() {
  return (
    <>
      <input type="checkbox" id="menu-toggle" />
      <Navbar />
      <Sidebar />
    </>
  );
}

export default AllLayout;
