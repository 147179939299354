import { useState } from "react"
import { statusVacation } from "../../Redux/Actions/actionVacation";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";

export const VariableVacations = () => {
    let {dispatch} = AbstructVar()
    const [state, setState] = useState({
        type: "",
        startDate: null,
        endDate: null,
        reason: '',
        medical_rep_id : "",
        medical_rep_name: '',
        Load: false,
        vacationId: null,
        arr1: [],
        openEdit: false
    })
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setopenEdit = (value) => {
        setState((prevState) => ({
            ...prevState,
            openEdit: value,
        }));
    };
    const handleChangeVacations = (value, variableName) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const handleChangeDate = (range) => {
        const [startDatee, endDatee] = range;
        setState((prevState) => ({
            ...prevState,
            startDate: startDatee,
            endDate: endDatee
        }));
    };
    const changeStatusVacation = (id, e) => {
        setState((prevState) => ({
            ...prevState,
            vacationId: id
        }));
        dispatch(statusVacation(id, e, setLoad))
    }
    const handleEditVacation = (event, arr) => {
        event.preventDefault();
        const formValues = {
            id: arr.id,
            type: arr.type,
            startDate: arr.start,
            endDate: arr.end,
            reason: arr.reason,
            medical_rep_name: arr.medical_rep_name,
            medical_rep_id: arr.medical_rep_id
        };
        setState((prevState) => ({
            ...prevState,
            arr1: formValues,
            openEdit: true
        }));
    }
    return {
        ...state,
        state,
        setState,
        handleChangeVacations,
        setLoad,
        handleChangeDate,
        changeStatusVacation,
        setopenEdit,
        handleEditVacation
    }
}