import React from "react";
import { CheckArabic, CheckFont } from "../General/GeneralComponent/CheckLang";
import { useTranslation } from "react-i18next";
import { base_url } from "../General/Notification";

function OneProduct({
  langmiamed,
  pp,
  handleEditProduct,
  warning,
  font_family,
}) {
  const { t, i18n } = useTranslation();
  return (
    <div className="oneproduct" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
      {pp.media_urls.length != 0 ? (
        <>
          <div
            className="img_product"
            style={{
              backgroundImage: `linear-gradient(rgba(53, 37, 37, 0.0), rgba(0, 0, 0, 0.0)), url(${base_url}/${pp.media_urls[0].url})`,
            }}
          >
            {/* <span
              className="product_cat"
              style={{ fontFamily: CheckFont(pp.measurement_unit_name) }}
            >
              {pp.measurement_unit_name}{" "}
            </span> */}
            <div
              className={`action_target ${
                i18n.language === "en" ? "right-10" : "left-10"
              }`}
            >
              <i className="las la-times" onClick={() => warning(pp)}></i>
              <i
                className="las la-pen"
                onClick={(event) => {
                  handleEditProduct(event, pp);
                }}
              ></i>
            </div>
          </div>
          <div className="product_info">
            <h5
              style={{
                color: pp.color || "black",
                fontFamily: CheckArabic(pp.name, font_family),
              }}
            >
              {pp.name}
            </h5>
            <span style={{ fontFamily: CheckFont(pp.description) }}>
              {pp.description}
            </span>
            <span style={{ fontFamily: CheckFont(pp.internal_reference) }}>
              {pp.internal_reference}
            </span>
            <span style={{ fontFamily: CheckFont(pp.category_name) }}>
              {t(pp.product_type)}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="img_product" style={{ backgroundColor: "#d3d0d0a7" }}>
            {/* <span
              className="product_cat"
              style={{ fontFamily: CheckFont(pp.measurement_unit_name) }}
            >
              {pp.measurement_unit_name}
            </span> */}
            <div
              className={`action_target ${
                i18n.language === "en" ? "right-10" : "left-10"
              }`}
            >
              <i className="las la-times" onClick={() => warning(pp)}></i>
              <i
                className="las la-pen"
                onClick={(event) => {
                  handleEditProduct(event, pp);
                }}
              ></i>
            </div>
          </div>
          <div className="product_info">
            <h5
              style={{
                color: pp.color || "black",
                fontFamily: CheckArabic(pp.name, font_family),
              }}
            >
              {pp.name}
            </h5>
            <span style={{ fontFamily: CheckFont(pp.description) }}>
              {pp.description}
            </span>
            <span style={{ fontFamily: CheckFont(pp.internal_reference) }}>
              {pp.internal_reference}
            </span>
            <span style={{ fontFamily: CheckFont(pp.product_type) }}>
              {t(pp.product_type)}
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default OneProduct;
