import React, { useEffect, useState } from "react";
import Page_Header from "../General/Page_Header";
import ActionTop from "../General/ActionTop";
import { array_action } from "../../dummyData";
import "./Users.css";
import Table2 from "../Table2/Table2";
import { deleteUser, loadUsers } from "../../Redux/Actions/ActionAuth";
import InnerLoader from "../General/InnerLoader";
import AddUser from "./AddUser";
import Warning from "../General/Warning_Delete/Warning";
import EditUser from "./EditUser";
import { exportFile } from "../General/Notification";
import NoInternet from "../General/NotPermission/NoInternet";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { DeleteComponent } from "../General/GeneralComponent/DeleteComponent";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { ColumnUser, VariableUser } from "./VariableUser";

function Users() {
  const [openInternet, setopenInternet] = useState(false);
  let { dispatch, navigate, langmiamed } = AbstructVar();
  const { users, loadingUsers } = useSelectorCustom();
  const reversedUsers = [...users].reverse();
  const { translate } = AbstructLang();
  const { state, handleEditUser, setOpen, setOpenEdit, openAddNew } =
    VariableUser();
  const { stateD, warning, setdeletee, setloadDel } = DeleteComponent();
  const handleDelete = (id) => {
    dispatch(deleteUser(id, setdeletee, setloadDel));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadUsers(navigate, setopenInternet));
  }, []);
  const { columnsUser } = ColumnUser(translate, handleEditUser, warning);
  const exportToExcel = () => {
    const dataToExport = users.map((item) => ({
      name: item.name,
      phone: item.phone,
      role: item.role,
      email: item.email,
      city_name: item.city_name,
      address: item.address,
    }));

    exportFile(dataToExport, "users_data");
  };
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("users")}
            path={`${translate("home")} / ${translate("users")} `}
          />
        </main>
        <div className="page-content">
          <ActionTop
            exportToExcel={exportToExcel}
            langmiamed={langmiamed}
            array_action={array_action}
            FunctionCustum={openAddNew}
            // is_view={true}
            is_user={true}
          />
          {state.open && (
            <AddUser
              t={translate}
              langmiamed={langmiamed}
              open={state.open}
              setOpen={setOpen}
            />
          )}
          <div className="users" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            {loadingUsers == false ? (
              <Table2 col={columnsUser} roo={reversedUsers} />
            ) : (
              <InnerLoader />
            )}
          </div>
        </div>
      </div>
      {state.openEdit && (
        <EditUser arr={state.arr} open={state.openEdit} setOpen={setOpenEdit} />
      )}
      {stateD.deletee == true ? (
        <Warning
          loadDel={stateD.loadDel}
          arr={stateD.arrW}
          open={stateD.deletee}
          setOpen={setdeletee}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default Users;
