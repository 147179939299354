import React, { useEffect, useState } from 'react'
import Page_Header from '../General/Page_Header';
import Table2 from '../Table2/Table2';
import InnerLoader from '../General/InnerLoader';
import NoInternet from '../General/NotPermission/NoInternet';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import { emptyRequest, loadRequests } from '../../Redux/Actions/ActionRequest';
import { ColumnRequest } from './VariableRequest';

function AllRequests() {
    const [openInternet, setopenInternet] = useState(false)
    let {dispatch,navigate,langmiamed} = AbstructVar()
    const {requests,loadingRequests} = useSelectorCustom()
    const reversedUsers = [...requests].reverse()
    const {translate} = AbstructLang()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadRequests(navigate,setopenInternet))
        return(()=>{
            dispatch(emptyRequest())
        })
    }, []);
    const {columnsReq} = ColumnRequest(translate)
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={translate('allRequests')} path={`${translate('home')} / ${translate('allRequests')} `} />
                </main>
                <div className="page-content" >
                    <div className='users' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                        {
                            loadingRequests == false ?(
                                <Table2 col={columnsReq} roo={reversedUsers}  />
                            ):<InnerLoader />
                        }
                    </div>
                </div>
            </div>
            {
                openInternet && (
                    <NoInternet open={openInternet} setOpen={setopenInternet} />
                )
            }
        </>
    )
}

export default AllRequests
