import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page_Header from "../General/Page_Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Politics.css";
import { VariablePolitics } from "./VariablePolitics";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import {
  editAbout,
  editPrivacy,
  loadPolitics,
} from "../../Redux/Actions/ActionPolitics";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";

function Politics() {
  let { dispatch } = AbstructVar();
  const { politics } = useSelectorCustom();
  const [isTap, setisTap] = useState("privacy");
  let langmiamed = localStorage.getItem("langmiamed");
  const {
    state,
    setLoadPrivacy,
    setLoadAbout,
    setState,
    handleChangePolitics,
  } = VariablePolitics();
  const [t, i18n] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("langmiamed"));
    dispatch(loadPolitics());
  }, []);
  useEffect(() => {
    if (politics.contents) {
      setState((prevState) => ({
        ...prevState,
        editorValuePrivacy: politics.contents.privacy,
        editorValueAbout: politics.contents.about_us,
      }));
    }
  }, [politics]);
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "align",
    "link",
    "image",
    "color",
  ];
  const editPrivacyFunc = (e) => {
    e.preventDefault();
    dispatch(editPrivacy(state.editorValuePrivacy, setLoadPrivacy));
  };
  const editAboutFunc = (e) => {
    e.preventDefault();
    dispatch(editAbout(state.editorValueAbout, setLoadAbout));
  };
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={t("Politics")}
            path={`${t("home")} / ${t("Politics")} `}
          />
        </main>
        <div className="page-content">
          <div className="choise_task">
            <button
              className={
                isTap == "privacy" ? "active_btn_choise" : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("privacy");
              }}
            >
              {t("privacy")}
            </button>
            <button
              className={
                isTap == "about" ? "active_btn_choise" : "unactive_btn_choise"
              }
              onClick={() => {
                setisTap("about");
              }}
            >
              {t("about")}
            </button>
          </div>
          {isTap === "privacy" && (
            <div className="div_ReactQuill">
              <h5 className={langmiamed === "ar" ? "textRight" : ""}>
                {t("privacy")}
              </h5>
              <ReactQuill
                className="input_quill11"
                modules={modules}
                formats={formats}
                value={state.editorValuePrivacy}
                onChange={(e) => handleChangePolitics(e, "editorValuePrivacy")}
              />
              <button className="btn_privacy" onClick={editPrivacyFunc}>
                {state.LoadPrivacy ? "Loading ..." : `${t("save")}`}
              </button>
            </div>
          )}
          {isTap === "about" && (
            <div className="div_ReactQuill">
              <h5 className={langmiamed === "ar" ? "textRight" : ""}>
                {t("about")}
              </h5>
              <ReactQuill
                className="input_quill11"
                modules={modules}
                formats={formats}
                value={state.editorValueAbout}
                onChange={(e) => handleChangePolitics(e, "editorValueAbout")}
              />
              <button className="btn_privacy" onClick={editAboutFunc}>
                {state.LoadAbout ? "Loading ..." : `${t("save")}`}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Politics;
