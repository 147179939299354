import { useState } from "react";

export const DeleteComponent = () => {
    const [stateD , setState] = useState({
        deletee: false,
        arrW: [],
        loadDel: false
    })
    const warning = (row) => {
        setState((prevState) => ({
            ...prevState,
            arrW: row,
            deletee: true
        }));
    }
    const setdeletee = (value) => {
        setState((prevState) => ({
            ...prevState,
            deletee: value,
        }));
    };
    const setloadDel = (value) => {
        setState((prevState) => ({
            ...prevState,
            loadDel: value,
        }));
    };
    return {
        ...stateD,
        stateD,
        warning,
        setdeletee,
        setloadDel
    }
}