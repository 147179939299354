import React, { useState, useEffect } from 'react';
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch } from "react-redux";
import { startOfDay } from 'date-fns';
import { refreshWorkPlans } from '../../Redux/Actions/ActionWorkPlans';
import { CheckFont } from '../General/GeneralComponent/CheckLang';

function PopupRefresh({ duration, t, langmiamed, work_plan_id, open1, setOpen,setopenInternet,navigate }) {
    let dispatch = useDispatch();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [error, setError] = useState("");
    const [Load, setLoad] = useState("");

    useEffect(() => {
        if (startDate && duration) {
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(startDateObj.getTime() + duration * 24 * 60 * 60 * 1000);
            const formattedEndDate = endDateObj.toISOString().slice(0, 10);
            setEndDate(formattedEndDate);
        }
    }, [startDate, duration]);

    const handleStartDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const today = startOfDay(new Date());
    
        if (selectedDate <= today) {
            setError(`${t('errRefresh')}`);
        } else {
            setError("");
            setStartDate(e.target.value);
        }
    };
    const submit =e=>{
        e.preventDefault()
        dispatch(refreshWorkPlans(work_plan_id,startDate,endDate,duration, navigate, setopenInternet, setLoad))
    }

    return (
        <RModal
            open={open1}
            onClose={() => {
                setOpen(false);
            }}
            center={true}
            classNames={{
                modal: "popAdd",
            }}
            focusTrapped={false}
        >
            <div className='popAddForm' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                <br></br>
                <div className='one_input_row' style={{ width: "100%" }}>
                    <div className='Gen_icon'>
                        <i className='las la-calendar' ></i>
                        <span style={{fontFamily: CheckFont(t('startDate'))}} className={langmiamed == 'ar' ? "textAlignRight" : ""} >{t('startDate')}</span>
                    </div>
                    <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                    {
                        error && <span className={` color_redd ${langmiamed == 'ar' ? "textAlignRight" : "margin_left_10"}`} >{error}</span>
                    }
                </div>
                <br></br>
                <div className='one_input_row' style={{ width: "100%" }}>
                    <div className='Gen_icon'>
                        <i className='las la-calendar' ></i>
                        <span style={{fontFamily: CheckFont(t('endDate'))}}  className={langmiamed == 'ar' ? "textAlignRight" : ""} >{t('endDate')}</span>
                    </div>
                    <input type="date" value={endDate} disabled />
                </div>
                <br></br>
                <div className='one_input_row'>
                    <div className='Gen_icon'>
                        <i className='las la-sort-numeric-up-alt' ></i>
                        <span style={{fontFamily: CheckFont(t('dayDuration'))}} className={langmiamed == 'ar' ? "textAlignRight" : ""} >{t('dayDuration')}</span>
                    </div>
                    <input value={duration} type="number" disabled />
                </div>
                <div className='div_btn' ><button style={{fontFamily: CheckFont(t('refresh'))}}  onClick={submit} >
                    {
                        Load ? "Loading ..." : `${t('refresh')}`
                    }
                </button></div>
            </div>
        </RModal>
    )
}

export default PopupRefresh;