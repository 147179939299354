import React from "react";
import Table2 from "../Table2/Table2";
import select from "../../images/icons/pointer.png";
import External_In_Map from "../External_Visites/External_In_Map";
import FilterStatistics from "../Main/Statistics/FilterStatistics";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import {
  ColumnArrayReportTargetVisitsWithDate,
  VariableReports,
} from "./VariableReports";

function TargetGroupReportWithDate({ langmiamed, t, navigate, setopenInternet }) {
  const { targetGroupsVisitesWithDate, loagingTargetGroupsVisitesWithDate } =
    useSelectorCustom();
  const reversedgeneralExReport = targetGroupsVisitesWithDate?.reverse();
  const { state, storeMap, setIsOpen } = VariableReports();
  const { OrdersReqsColumn } = ColumnArrayReportTargetVisitsWithDate(t, storeMap);
  return (
    <>
      <FilterStatistics
        t={t}
        langmiamed={langmiamed}
        params="target_visits_report_with_date"
        excel="target_visits_report_with_date"
      />
      <div className="users" dir={langmiamed == "ar" ? "ltr" : "ltr"}>
        {loagingTargetGroupsVisitesWithDate == false ? (
          <Table2 col={OrdersReqsColumn} roo={reversedgeneralExReport} />
        ) : (
          <div className="alternative">
            <img src={select} />
            <br></br>
            <p>{t("filterr")}</p>
          </div>
        )}
      </div>
      {state.openMap && (
        <External_In_Map
          open={state.openMap}
          setOpen={setIsOpen}
          lat={state.lat}
          lng={state.lng}
        />
      )}
    </>
  );
}

export default TargetGroupReportWithDate;
