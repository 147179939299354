import * as types from "../ActionType";

const initState = {
    statistics: {},
    loadingStatistics: true,
    generalStatic: {},
    loadingGeneralStatic: true,
    statisticsWorkPlan: {},
    loadingStatisticsWorkPlan: true,
    statisticsExternal: [],
    loadingStatisticsExternal: true,
    statisticsTarget: {},
    loadingStatisticsTarget: true
}

const StatisticsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_ALL_STATISTICS:
            return { ...state, statistics: action.payload, loadingStatistics: false }
        case types.EMPTY_ALL_STATISTICS:
            return { ...state, statistics: action.payload, loadingStatistics: false }
        case types.GET_GENERAL_STATIC:
            return { ...state, generalStatic: action.payload, loadingGeneralStatic: false }
        case types.GET_STATISTICS_WORK_PLANS:
            return { ...state, statisticsWorkPlan: action.payload, loadingStatisticsWorkPlan: false }
        case types.GET_STATISTICS_EXTERNAL_VISITE:
            return { ...state, statisticsExternal: action.payload, loadingStatisticsExternal: false }
        case types.GET_STATISTICS_TARGET_GROUP:
            return { ...state, statisticsTarget: action.payload, loadingStatisticsTarget: false }
        default:
            return state;
    }
};

export default StatisticsReducer;
