export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_ALL_CITIES = "GET_ALL_CITIES";
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const ADD_COUNTRY = "ADD_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const ADD_CITY_TO_COUNTRY = "ADD_CITY_TO_COUNTRY";
export const DELETE_CITY = "DELETE_CITY";
export const EDIT_CITY = "EDIT_CITY";
export const GET_ALL_SPECIALIZATIONS = "GET_ALL_SPECIALIZATIONS";
export const ADD_SPECIALIZATION = "ADD_SPECIALIZATION";
export const DELETE_SPECIALIZATION = "DELETE_SPECIALIZATION";
export const GET_ALL_CLASSIFICATIONS = "GET_ALL_CLASSIFICATIONS";
export const ADD_CLASSIFICATION = "ADD_CLASSIFICATION";
export const DELETE_CLASSIFICATION = "DELETE_CLASSIFICATION";
export const GET_ALL_TARGET_TYPE = "GET_ALL_TARGET_TYPE";
export const ADD_TARGET_TYPE = "ADD_TARGET_TYPE";
export const DELETE_TARGET_TYPE = "DELETE_TARGET_TYPE";
export const ADD_TARGET_GROUP = "ADD_TARGET_GROUP";
export const GET_ALL_TARGET_GROUP = "GET_ALL_TARGET_GROUP";
export const DELETE_TARGET_GROUP = "DELETE_TARGET_GROUP";
export const EDIT_TARGET_GROUP = "EDIT_TARGET_GROUP";
export const GET_SENIOR_SUPERVISOR = "GET_SENIOR_SUPERVISOR";
export const EMPTY_SENIOR_SUPERVISOR = "EMPTY_SENIOR_SUPERVISOR";
export const ADD_WORK_PLAN = "ADD_WORK_PLAN";
export const GET_WORK_PLANS = "GET_WORK_PLANS";
export const EDIT_WORK_PLAN = "EDIT_WORK_PLAN";
export const DELETE_WORK_PLAN = "DELETE_WORK_PLAN";

export const ADD_MEDICAL_REP = "ADD_MEDICAL_REP";
export const GET_MEDICAL_REPS= "GET_MEDICAL_REPS";
export const EDIT_MEDICAL_REP = "EDIT_MEDICAL_REP";
export const DELETE_MEDICAL_REP = "DELETE_MEDICAL_REP";

export const ADD_MEASUREMENT = "ADD_MEASUREMENT";
export const GET_MEASUREMENT = "GET_MEASUREMENT";
export const DELETE_MEASUREMENT = "DELETE_MEASUREMENT";

export const ADD_PRODUCT_CAT = "ADD_PRODUCT_CAT";
export const GET_PRODUCT_CAT = "GET_PRODUCT_CAT";
export const DELETE_PRODUCT_CAT = "DELETE_PRODUCT_CAT";
export const EDIT_PRODUCT_CAT = "EDIT_PRODUCT_CAT";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_MARKETINGtOOL = "ADD_MARKETINGtOOL";
export const GET_PRODUCT = "GET_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_TOOL = "DELETE_TOOL";
export const EDIT_PRODUCT = "EDIT_PRODUCT";

export const ADD_WORK_FLOW = "ADD_WORK_FLOW";
export const GET_WORK_FLOW = "GET_WORK_FLOW";
export const DELETE_WORK_FLOW = "DELETE_WORK_FLOW";
export const EDIT_WORK_FLOW = "EDIT_WORK_FLOW";

export const FILTER_TARGET_GROUP = "FILTER_TARGET_GROUP";

export const GET_VACATION = "GET_VACATION";
export const EDIT_VACATION = "EDIT_VACATION";


export const GET_SPECIALIZATIONS_BY_CLASSIFICATIONS = "GET_SPECIALIZATIONS_BY_CLASSIFICATIONS";
export const EMPTY_SPECIALIZATIONS_BY_CLASSIFICATIONS = "EMPTY_SPECIALIZATIONS_BY_CLASSIFICATIONS";
export const GET_CLASSIFICATIONS_BY_SPECIALIZATIONS = "GET_CLASSIFICATIONS_BY_SPECIALIZATIONS";
export const EMPTY_CLASSIFICATIONS_BY_SPECIALIZATIONS = "EMPTY_CLASSIFICATIONS_BY_SPECIALIZATIONS";

export const LINK_CLASSIFICATIONS_WITH_SPECIALIZATIONS = "LINK_CLASSIFICATIONS_WITH_SPECIALIZATIONS";

export const ADD_TASK = "ADD_TASK";
export const GET_TASKS = "GET_TASKS";
export const EMPTY_TASK = "EMPTY_TASK";
export const EDIT_TASK = "EDIT_TASK";
export const DELETE_TASK = "DELETE_TASK";

export const GET_ONE_PLAN = "GET_ONE_PLAN";
export const EMPTY_ONE_PLAN = "EMPTY_ONE_PLAN";

export const GET_EXTERNAL_VISITES = "GET_EXTERNAL_VISITES";

export const ADD_REPORT_TYPE = "ADD_REPORT_TYPE";
export const GET_REPORT_TYPE = "GET_REPORT_TYPE";
export const DELETE_REPORT_TYPE = "DELETE_REPORT_TYPE";
export const EDIT_REPORT_TYPE = "EDIT_REPORT_TYPE";

export const GET_TASK_VISITES = "GET_TASK_VISITES";
export const EMPTY_TASK_VISITES = "EMPTY_TASK_VISITES";

export const DECLASSIFY = "DECLASSIFY";
export const GET_INFORMATION_PROFILE = "GET_INFORMATION_PROFILE";

export const ASSIGN_TASK_TO_DATE = "ASSIGN_TASK_TO_DATE";
export const GET_ROLES = "GET_ROLES";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const EMPTY_PERMISSIONS = "EMPTY_PERMISSIONS";
export const EDIT_PERMISSIONS = "EDIT_PERMISSIONS";

export const ASSIGN = "ASSIGN";
export const UNASSIGN = "UNASSIGN";
export const TARGET_GROUP_BY_MEDICAL = "TARGET_GROUP_BY_MEDICAL";
export const EMPTY_TARGET_GROUP_BY_MEDICAL = "EMPTY_TARGET_GROUP_BY_MEDICAL";

export const EMPTY_ALL_TARGET_GROUP = "EMPTY_ALL_TARGET_GROUP";
export const EMPTY_ALL_PRODUCTS = "EMPTY_ALL_PRODUCTS";
export const EMPTY_ALL_MED_REP = "EMPTY_ALL_MED_REP";
export const EMPTY_ALL_WORK_PLAN = "EMPTY_ALL_WORK_PLAN";
export const EMPTY_ALL_WORK_TASK = "EMPTY_ALL_WORK_TASK";
export const EMPTY_ALL_VACATION = "EMPTY_ALL_VACATION";
export const EMPTY_ALL_EXTERNAL_VISIT = "EMPTY_ALL_EXTERNAL_VISIT";

export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const EMPTY_ALL_REPORTS = "EMPTY_ALL_REPORTS";
export const GET_ALL_REPORTS_PRODUCTS = "GET_ALL_REPORTS_PRODUCTS";
export const EMPTY_ALL_REPORTS_PRODUCTS = "EMPTY_ALL_REPORTS_PRODUCTS";

export const GET_ALL_STATISTICS= "GET_ALL_STATISTICS";
export const EMPTY_ALL_STATISTICS= "EMPTY_ALL_STATISTICS";
export const DOWNLOAD_VISITES_EXCEL = "DOWNLOAD_VISITES_EXCEL";
export const DOWNLOAD_VISITES_PRODUCTS = "DOWNLOAD_VISITES_PRODUCTS";

export const DOWNLOAD_TARGET_GROUP_WITHOUT_FILTER = "DOWNLOAD_TARGET_GROUP_WITHOUT_FILTER";
export const DOWNLOAD_TARGET_GROUP_WITH_FILTER = "DOWNLOAD_TARGET_GROUP_WITH_FILTER";

export const GET_ONE_TASK = "GET_ONE_TASK";
export const EMTY_ONE_TASK = "EMTY_ONE_TASK";

export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const GET_TARGETT_MEDICAL = "GET_TARGETT_MEDICAL";

export const GET_WORK_PLAN_PRODUCTS = "GET_WORK_PLAN_PRODUCTS";
export const EMPTY_WORK_PLAN_PRODUCTS = "EMPTY_WORK_PLAN_PRODUCTS";
export const UNASSIGN_WORK_PLAN_PRODUCTS = "UNASSIGN_WORK_PLAN_PRODUCTS";
export const ASSIGN_WORK_PLAN_PRODUCTS = "ASSIGN_WORK_PLAN_PRODUCTS";
export const SAVE_FCM = "SAVE_FCM";

export const GET_UNASSIGN_PRODUCTS = "GET_UNASSIGN_PRODUCTS";
export const EMPTY_UNASSIGN_PRODUCTS = "EMPTY_UNASSIGN_PRODUCTS";

// 
export const GET_WORK_PLAN_MARKETING = "GET_WORK_PLAN_MARKETING";
export const EMPTY_WORK_PLAN_MARKETING = "EMPTY_WORK_PLAN_MARKETING";
export const UNASSIGN_WORK_PLAN_MARKETING = "UNASSIGN_WORK_PLAN_MARKETING";
export const ASSIGN_WORK_PLAN_MARKETING = "ASSIGN_WORK_PLAN_MARKETING";
export const GET_UNASSIGN_MARKETING = "GET_UNASSIGN_MARKETING";
export const EMPTY_UNASSIGN_MARKETING = "EMPTY_UNASSIGN_MARKETING";
// 
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const GET_ONE_WORKFLOW = "GET_ONE_WORKFLOW";
export const GET_GENERAL_STATIC = "GET_GENERAL_STATIC";

export const GET_VISITS_BY_MED_REP = "GET_VISITS_BY_MED_REP";
export const EMPTY_VISITS_BY_MED_REP = "EMPTY_VISITS_BY_MED_REP";
export const DELETE_VISITS_BY_MED_REP = "DELETE_VISITS_BY_MED_REP";

export const GET_GENERAL_REPORTS = "GET_GENERAL_REPORTS";
export const EXPORT_GENERAL_REPORTS = "EXPORT_GENERAL_REPORTS";
export const GET_PRODUCTS_BY_VISIT_MEDREP = "GET_PRODUCTS_BY_VISIT_MEDREP";
export const EMPTY_PRODUCTS_BY_VISIT_MEDREP = "EMPTY_PRODUCTS_BY_VISIT_MEDREP";
export const DELETE_PRODUCTS_BY_VISIT_MEDREP = "DELETE_PRODUCTS_BY_VISIT_MEDREP";

export const GET_GENERALEX_REPORTS = "GET_GENERALEX_REPORTS";
export const EXPORT_GENERALEX_REPORTS = "EXPORT_GENERALEX_REPORTS";

export const GET_GENERAL_TARGET_GROUP = "GET_GENERAL_TARGET_GROUP";
export const GET_ORDERS_REQS = "GET_ORDERS_REQS";
export const EXPORT_GENERAL_TARGET_GROUP = "EXPORT_GENERAL_TARGET_GROUP";

export const FILTER_VISITS_BY_MEDREP = "FILTER_VISITS_BY_MEDREP";
export const EXPORT_VISITS_BY_MEDREP = "EXPORT_VISITS_BY_MEDREP";
export const REFRESH_WORK_PLANE = "REFRESH_WORK_PLANE";

export const GET_STATISTICS_WORK_PLANS = "GET_STATISTICS_WORK_PLANS";
export const GET_STATISTICS_EXTERNAL_VISITE = "GET_STATISTICS_EXTERNAL_VISITE";
export const GET_STATISTICS_TARGET_GROUP = "GET_STATISTICS_TARGET_GROUP";


export const GET_REQUESTS = "GET_REQUESTS";
export const EMPTY_REQUEST = "EMPTY_REQUEST";
export const SEND_REQUESTS = "SEND_REQUESTS";

export const EDIT_VACATION_INFORMATION = "EDIT_VACATION_INFORMATION";

export const GET_POLITICS = "GET_POLITICS";
export const EDIT_PRIVACY = "EDIT_PRIVACY";
export const EDIT_ABOUT = "EDIT_ABOUT";
export const EXPORT_ORDERS_REQS = "EXPORT_ORDERS_REQS";
export const GET_OBJECTIONS_REPORT = "GET_OBJECTIONS_REPORT";
export const EXPORT_OBJECTIONS_REPORT = "EXPORT_OBJECTIONS_REPORT";
export const EXPORT_ORDERS = "EXPORT_ORDERS";
export const GET_ORDERS = "GET_ORDERS";
export const GET_TARGET_GROUP_VISITES = "GET_TARGET_GROUP_VISITES";
export const GET_TARGET_GROUP_VISITES_WITH_DATE = "GET_TARGET_GROUP_VISITES_WITH_DATE";
export const GET_ALL_VISITS = "GET_ALL_VISITS";
export const target_group = "target_group";
export const EXPORT_All_Visits = "EXPORT_All_Visits";
export const EXPORT_All_Visits_WITH_DATE = "EXPORT_All_Visits_WITH_DATE";
export const EXPORT_TARGET_GROUP_VISITS = "EXPORT_TARGET_GROUP_VISITS";
export const GET_FREE_SAMPLES = "GET_FREE_SAMPLES";
export const GET_FREE_SAMPLES_Target = "GET_FREE_SAMPLES_Target";
export const GET_MARKETING_TOOLS = "GET_MARKETING_TOOLS";
export const EXPORT_FREE_SAMPLES = "EXPORT_FREE_SAMPLES";
export const EXPORT_FREE_SAMPLES_TARGET = "EXPORT_FREE_SAMPLES_TARGET";
export const GET_REP_SALES_REPORT = "GET_REP_SALES_REPORT";
export const EXPORT_REP_SALES_REPORT = "EXPORT_REP_SALES_REPORT";
export const EXPORT_FREE_MARKETING_TOOLS = "EXPORT_FREE_MARKETING_TOOLS";
export const GET_PRODUCTS_MONTHLY_SALES = "GET_PRODUCTS_MONTHLY_SALES";
export const GET_MEDICAL_REP_TARGET = "GET_MEDICAL_REP_TARGET";
export const EXPORT_MONTHLY_PRODUCTS = "EXPORT_MONTHLY_PRODUCTS";
export const EXPORT_MEDICAL_REP_TARGET = "EXPORT_MEDICAL_REP_TARGET";
export const SEND_TARGET_ORDERS = "SEND_TARGET_ORDERS";


export const GET_HOSPITAL_REPORTS = "GET_HOSPITAL_REPORTS";
export const EXPORT_HOSPITAL = "EXPORT_HOSPITAL";
export const EMPTY_HOSPITAL_REPORTS = "EMPTY_HOSPITAL_REPORTS";



export const GET_Governorate = "GET_Governorate";