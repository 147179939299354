import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
const CustomToast = ({ mess }) => {
  return <div>{mess}</div>;
};
toast.configure();
export const notifyError = (mess) => {
  toast.error(<CustomToast mess={mess} />, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};
export const notifyErrorNetWork = (mess) => {
  toast.error(<CustomToast mess={mess} />, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: false,
  });
};
export const notifysuccess = (mess) => {
  toast.success(<CustomToast mess={mess} />, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};

export const base_url = "https://miamed.peaklinkdemo.com/";
// export const base_url = "https://miamed.peaklink.site/";
// export const base_url = "https://dev.miamed.peaklink.site/";
// export const base_url = "https://control.miamed.peaklink.site/";
/*export const base_url = "https://test.miamed.peaklink.site/";*/

export const exportFile = (array, name) => {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, name);
  XLSX.writeFile(workbook, `${name}.xlsx`);
};
