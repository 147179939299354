import React, { useEffect, useState } from 'react'
import Page_Header from '../../General/Page_Header';
import InnerLoader from '../../General/InnerLoader';
import Table2 from '../../Table2/Table2';
import Switch from "react-switch";
import { useParams } from 'react-router-dom';
import { emptyPermission, loadPermissions, putPermission } from '../../../Redux/Actions/ActionRoles';
import moment from 'moment';
import ReactLoading from 'react-loading';
import NoInternet from '../../General/NotPermission/NoInternet';
import { AbstructVar } from '../../General/GeneralComponent/AbstructVar';
import { AbstructLang } from '../../General/GeneralComponent/AbstructLang';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';

function Permissions() {
    const [openInternet, setopenInternet] = useState(false)
    let { dispatch, langmiamed, navigate } = AbstructVar()
    const { translate } = AbstructLang()
    const {permission,loadingpermission} = useSelectorCustom()
    const [statusID, setstatusID] = useState(null);
    const [loadStatusID, setloadStatusID] = useState(false);
    function replaceUnderscoreWithSpace(text) {
        return text.replace(/_/g, ' ') && text.replace(/-/g, ' ');
    }
    let { id, name } = useParams()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadPermissions(id, navigate, setopenInternet))
        return (() => {
            dispatch(emptyPermission())
        })
    }, []);

    const columns1 = [
        {
            name: <span className="color_spann">{translate('permissions')}</span>, sortable: true,
            cell: (row) => (
                <span>{replaceUnderscoreWithSpace(row['name'])}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('date')}</span>, sortable: true,
            cell: (row) => (
                <span>{moment(row['created_at']).format("YYYY-MM-DD")}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('edit')}</span>,
            cell: (row) => (
                <>
                    {
                        (row.id === statusID && loadStatusID == true) ? (
                            <ReactLoading type="cylon" color="#4285C5" height={'10%'} width={'10%'} />
                        ) :
                            <Switch onColor='#4285C5' checkedIcon={false} uncheckedIcon={false} height={20} width={40} handleDiameter={15} checked={row.status} onChange={() => changeCheck(row)} />
                    }
                </>
            )
        }
    ]
    const changeCheck = (row) => {
        setstatusID(row.id);
        let reverseStatus;
        if (row.status == true) {
            reverseStatus = 0;
        } else {
            reverseStatus = 1;
        }
        dispatch(putPermission(row.id, id, row.name, reverseStatus, setloadStatusID))
    }
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={`${translate('permissions')} / ${name}`} path={`${translate('home')} / ${translate('permissions')} `} />
                </main>
                <div className="page-content" >
                    <div className='users' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                        {
                            loadingpermission == false ? (
                                <Table2 col={columns1} roo={permission} />
                            ) : <div className='div_loadd' ><InnerLoader /></div>
                        }
                    </div>
                </div>
            </div>
            {
                openInternet && (
                    <NoInternet open={openInternet} setOpen={setopenInternet} />
                )
            }
        </>
    )
}

export default Permissions
