import React, { useState, useEffect } from 'react';
import { Modal as RModal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import NoData from '../../../General/NoData/NoData';
import { CheckFont } from '../../../General/GeneralComponent/CheckLang';

function DetailsMarkiting({ markitingArr, langmiamed, t, open, setOpen }) {
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
                //dispatch(emptyTaskVisites())
            }}
            center
            classNames={{
                modal: 'modal_popUp_DetailsProducts',
            }}
        >
            <div className='popUp_DetailsProducts' dir={langmiamed == 'ar'? "rtl" : "ltr"} >
                {
                    markitingArr.map((pp) => {
                        return (
                            <div className='one_products_visite' >
                                {
                                    pp.marketing_tools.media_urls.length != 0 ? (
                                        <img src={pp.marketing_tools.media_urls[0].url} />
                                    ) : <div className='relatied_img' ></div>
                                }
                                <h5  style={{fontFamily: CheckFont(pp.marketing_tools.name)}} className={langmiamed == 'ar' ? "textRight" : ""} >{t('marketing_name')}: {pp.marketing_tools.name}</h5>
                                <h5  style={{fontFamily: CheckFont(pp.quantity)}} className={`top_minac ${langmiamed == 'ar' ? "textRight" : ""} `} >{t('Quantity')}: {pp.quantity}</h5>
                                <h5 style={{fontFamily: CheckFont(pp.type)}} className={`top_minac ${langmiamed == 'ar' ? "textRight" : ""} `} >{pp.type}</h5>
                            </div>
                        )
                    })
                }
                {
                    markitingArr.length == 0 &&(
                        <NoData />
                    )
                }
            </div>
        </RModal>
    )
}

export default DetailsMarkiting