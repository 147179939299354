import React from "react";
import { unassignMedicalRep } from "../../Redux/Actions/ActionMedicalRep";
import { VariableAssign } from "./VariableAssign";
import loc from "../../images/icons/location_icon.svg";
import people from "../../images/icon_sidebar/people.png";
import medd from "../../images/icons/med.png";
import InnerLoader from "../General/InnerLoader";
import { CheckFont } from "../General/GeneralComponent/CheckLang";

function UnAssignComponent({
  langmiamed,
  translate,
  medical_rep_id,
  loadingTG,
  TGbymedicalRep,
  dispatch,
}) {
  const { state, setLoad1, handleCheckboxChange, handleSelectAll } =
    VariableAssign();
  const unassign = () => {
    dispatch(unassignMedicalRep(medical_rep_id, state.checkedIds1, setLoad1));
  };
  return (
    <>
      {/* <div
        className={`div_selected ${
          langmiamed == "ar" ? "margin_left_70" : "margin_right_70"
        } `}
      >
        <div className="div_assign">
          <h5 className={langmiamed == "ar" ? "textRight" : ""}>
            {translate("TGforMedRep")}
          </h5>
          <span onClick={unassign} className="btn_assign">
            {state.Load1 ? "Loading ..." : `${translate("unassign")}`}
          </span>
        </div>
        <div className="name_medRep" key="selectAll">
          <input
            className={
              langmiamed == "ar" ? "margin_left_20" : "margin_right_20"
            }
            type="checkbox"
            onChange={(event) =>
              handleSelectAll(
                event,
                "checkedIds1",
                "selectAll1",
                TGbymedicalRep
              )
            }
            checked={state.selectAll1}
          />
          <span>{translate("selectall")}</span>
        </div>
        {medical_rep_id != "" ? (
          loadingTG == false ? (
            TGbymedicalRep.map((tt) => {
              const isChecked =
                state.selectAll1 || state.checkedIds1.includes(tt.id);
              return (
                <div className="unassign" key={tt.id}>
                  <div className="setting_icon1">
                    <input
                      className={
                        langmiamed == "ar"
                          ? "margin_left_20"
                          : "margin_right_20"
                      }
                      value={tt.id}
                      type="checkbox"
                      onChange={(event) =>
                        handleCheckboxChange(event, "checkedIds1")
                      }
                      checked={isChecked}
                    />
                    <div className="colcol">
                      <div className="bothcol">
                        <img src={people} />
                        <span
                          style={{ fontFamily: CheckFont(tt.name) }}
                          className={
                            langmiamed == "ar"
                              ? "margin_right_10"
                              : "margin_left_20"
                          }
                        >
                          {tt.name}
                        </span>
                      </div>
                      {tt.address != null && (
                        <div className="bothcol">
                          <img src={loc} />

                          <span
                            style={{ fontFamily: CheckFont(tt.address) }}
                            className={
                              langmiamed == "ar"
                                ? "margin_right_10"
                                : "margin_left_20"
                            }
                          >
                            {tt.address}
                          </span>
                        </div>
                      )}
                      {tt.medical_rep_name != null && (
                        <div className="bothcol">
                          <img src={medd} />
                          <span
                            style={{
                              fontFamily: CheckFont(tt.medical_rep_name),
                            }}
                            className={
                              langmiamed == "ar"
                                ? "margin_right_10"
                                : "margin_left_20"
                            }
                          >
                            {tt.medical_rep_name}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="loadSetting">
              <InnerLoader />
            </div>
          )
        ) : (
          <div className="text_alt" style={{ textAlign: "center" }}>
            <span>{translate("should")}</span>
          </div>
        )}
      </div> */}

      {/*  */}

      <div className="div_assign ">
        <h5 className={langmiamed == "ar" ? "textRight" : ""}>
          {translate("TGforMedRep")}
        </h5>
        <div className="action_assign">
          <span
            className={`btn_assign ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
            onClick={unassign}
          >
            {state.Load ? "Loading ..." : `${translate("unassign")}`}
          </span>
        </div>
      </div>

      {/* Search Input */}
      <div className="searchBox">
        <div className="divForSelectAll">
          <div class="checkbox-wrapper-26">
            <input
            onChange={(event) =>
                handleSelectAll(
                  event,
                  "checkedIds1",
                  "selectAll1",
                  TGbymedicalRep
                )
              }
              checked={state.selectAll1}
              type="checkbox"
              id="_checkbox-27"
            />
            <label for="_checkbox-27">
              <div class="tick_mark"></div>
            </label>
          </div>
          <span>{translate("selectall")}</span>
        </div>
      </div>
      <div className="div_checkbox">
        {medical_rep_id != "" ? (
          loadingTG  ==  false ? (
            TGbymedicalRep.map((tt) => {
              const isChecked =
              state.selectAll1 || state.checkedIds1.includes(tt.id);
              return (
                <div className="name_medRep" key={tt.id}>
                  <div class="checkbox-wrapper-26">
                    <input
                     value={tt.id}
                     type="checkbox"
                     onChange={(event) =>
                       handleCheckboxChange(event, "checkedIds1")
                     }
                     checked={isChecked}
                      id={tt.id}
                    />
                    <label for={tt.id}>
                      <div class="tick_mark"></div>
                    </label>
                  </div>

                  <div className="colcol">
                    <div className="bothcol">
                      <img src={people} />
                      <span
                        style={{ fontFamily: CheckFont(tt.name) }}
                        className={
                          langmiamed == "ar"
                            ? "margin_right_10"
                            : "margin_left_20"
                        }
                      >
                        {tt.name}
                      </span>
                    </div>
                    {tt.address != null && (
                      <div className="bothcol">
                        <img src={loc} />
                        <span
                          style={{ fontFamily: CheckFont(tt.address) }}
                          className={
                            langmiamed == "ar"
                              ? "margin_right_10"
                              : "margin_left_20"
                          }
                        >
                          {tt.address}
                        </span>
                      </div>
                    )}
                    {tt.medical_rep_name != null && (
                      <div className="bothcol">
                        <img src={medd} />
                        <span
                          style={{ fontFamily: CheckFont(tt.medical_rep_name) }}
                          className={
                            langmiamed == "ar"
                              ? "margin_right_10"
                              : "margin_left_20"
                          }
                        >
                          {tt.medical_rep_name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
        ) : (
            <div className="loadSetting">
              <InnerLoader />
            </div>
          )
        ) : (
          <div className="text_alt" style={{ textAlign: "center" }}>
            <span>{translate("should")}</span>
          </div>
        )}
      </div>

      {/*  */}
    </>
  );
}

export default UnAssignComponent;
