import * as types from "../ActionType";

const initState = {
    external_visites: [],
    loadingexternal_visites: true,
}

const ExternalVisitesReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_EXTERNAL_VISITES:
            return { ...state, external_visites: action.payload, loadingexternal_visites: false }
        default:
            return state;
    }
};

export default ExternalVisitesReducer;
