import React, { useState, useEffect } from 'react';
import { Modal as RModal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import NoData from '../../General/NoData/NoData';
import { useDispatch, useSelector } from "react-redux";
import { deleteProvisitesMedicalRep, emptyProvisitesMedicalRep, loadProvisitesMedicalRep } from '../../../Redux/Actions/ActionMedicalRep';
import './VisitsByMedRep.css'
import InnerLoader from '../../General/InnerLoader';

function ProductsVisitsByMedRep({ langmiamed, t, id, open, setOpen }) {
    let dispatch = useDispatch();
    const [Load, setLoad] = useState(false)
    const ProductVisit = useSelector(state => state.medical_rep.ProductVisit)
    const loadingProductVisit = useSelector(state => state.medical_rep.loadingProductVisit)
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadProvisitesMedicalRep(id))
    }, []);
    const deletee = (pro_id) => {
        dispatch(deleteProvisitesMedicalRep(id, pro_id, setLoad))
    }
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
                dispatch(emptyProvisitesMedicalRep())
            }}
            center
            classNames={{
                modal: 'modal_popUp_DetailsProducts',
            }}
        >
            <div className='popUp_DetailsProducts' dir={langmiamed == 'ar' ? "rtl" : "ltr"} >
                {
                    loadingProductVisit == false ? (
                        ProductVisit.map((pp) => {
                            return (
                                <div className='one_products_visite' key={pp.id} >
                                    <i className="las la-times del_ProductVisit" onClick={() => deletee(pp.id)} ></i>
                                    {
                                        pp.product.media_urls.length != 0 ? (
                                            <img src={pp.product.media_urls[0].url} />
                                        ) : <div className='relatied_img' ></div>
                                    }
                                    <h5 className={langmiamed == 'ar' ? "textRight" : ""} >{t('Product_Name')}: {pp.product.name}</h5>
                                    <h5 className={`top_minac ${langmiamed == 'ar' ? "textRight" : ""} `} >{t('Quantity')}: {pp.quantity}</h5>
                                    <h5 className={`top_minac ${langmiamed == 'ar' ? "textRight" : ""} `} >{pp.product.product_type}</h5>
                                </div>
                            )
                        })) : <div className='loadProductVisit' ><InnerLoader /></div>
                }
                {
                    loadingProductVisit == false && ProductVisit.length === 0 && (
                        <NoData />
                    )
                }
            </div>
        </RModal>
    )
}

export default ProductsVisitsByMedRep