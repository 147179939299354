import React from 'react'
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import GoogleMapReact from "google-map-react";
const AnyReactComponent = () => <div><i className='fa fa-map-marker map_marker' ></i></div>;
function External_In_Map({ open, setOpen, lat, lng }) {
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            center
            classNames={{
                modal: "mapPop",
            }}
            focusTrapped={false}
        >
            <div className='div_mapPop' >
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyCPsxZeXKcSYK1XXw0O0RbrZiI_Ekou5DY" }}
                    defaultCenter={{ lat: lat, lng: lng }}
                    defaultZoom={13}
                >
                    <AnyReactComponent
                        lat={lat}
                        lng={lng}
                    />
                </GoogleMapReact>
            </div>
        </RModal>
    )
}

export default External_In_Map