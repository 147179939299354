import * as types from "../ActionType";

const initState = {
    requests: [],
    loadingRequests: true,
}

const RequestReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_REQUESTS:
            return { ...state, requests: action.payload, loadingRequests: false }
        case types.EMPTY_REQUEST:
            return { ...state, requests: action.payload, loadingRequests: true }
        case types.SEND_REQUESTS:
            return { ...state, loadingRequests: true }
        default:
            return state;
    }
};

export default RequestReducer;
