import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css"; // Import modal styles
import "./targetOrders.css";
export const TargetModal = ({ handleSubmit, open, setOpen, load }) => {
  const { t } = useTranslation();

  const myRef = React.useRef(null);

  return (
    <>
      <div ref={myRef} style={{ textAlign: "center", marginTop: "20px" }}>
        {/* <button className="button" >
          Open modal
        </button> */}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        container={myRef.current}
        styles={{
          modal: {
            borderRadius: "10px",
            padding: "20px",
            maxWidth: "400px",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.5)",
          },
          closeButton: {
            top: "8px",
            right: "8px",
          },
        }}
      >
        <p className="font-global" style={{ padding: "10px" }}>
          {t(
            "Are you sure about the process of identifying the targeted orders"
          )}
        </p>
        <button
          style={{
            display: "block",
            margin: "auto",
            border: "none",
            outline: "none",
            padding: "5px 20px",
            fontSize: "13px",
            backgroundColor: "var(--main-color)",
            color: "white",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleSubmit}
          className="mt-2 "
        >
          {load ? t("Loading ...") : t("Submit")}
        </button>
      </Modal>
    </>
  );
};
