import * as types from "../ActionType";

const initState = {
    measurement: [],
    loadingmeasurement: true,
    productCat: [],
    loadingproductCat: true,
    specilizationByClass: [],
    loadingSpec: true,
    classificationByspec: [],
    loadingClass: true
}

const ConfigProductReducer = (state = initState, action) => {
    switch (action.type) {
        case types.ADD_MEASUREMENT:
            return { ...state, loadingmeasurement: true }
        case types.GET_MEASUREMENT:
            return { ...state, measurement: action.payload, loadingmeasurement: false }
        case types.DELETE_MEASUREMENT:
            return { ...state, loadingmeasurement: true }
        case types.ADD_PRODUCT_CAT:
            return { ...state, loadingproductCat: true }
        case types.GET_PRODUCT_CAT:
            return { ...state, productCat: action.payload, loadingproductCat: false }
        case types.DELETE_PRODUCT_CAT:
            return { ...state, loadingproductCat: true }
        case types.EDIT_PRODUCT_CAT:
            return { ...state, loadingproductCat: true }
        case types.GET_SPECIALIZATIONS_BY_CLASSIFICATIONS:
            return { ...state, specilizationByClass: action.payload, loadingSpec: false }
        case types.EMPTY_SPECIALIZATIONS_BY_CLASSIFICATIONS:
            return { ...state, specilizationByClass: action.payload, loadingSpec: true }
        case types.GET_CLASSIFICATIONS_BY_SPECIALIZATIONS:
            return { ...state, classificationByspec: action.payload, loadingClass: false }
        case types.EMPTY_CLASSIFICATIONS_BY_SPECIALIZATIONS:
            return { ...state, classificationByspec: action.payload, loadingClass: true }
        case types.LINK_CLASSIFICATIONS_WITH_SPECIALIZATIONS:
            return { ...state }
        case types.DECLASSIFY:
            return { ...state ,loadingSpec: true ,loadingClass: true   }
        default:
            return state;
    }
};

export default ConfigProductReducer;
