import React from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { filterrTargetGroup } from "../../Redux/Actions/ActionTargetGroup";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { VariableAssign } from "./VariableAssign";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";

function FilterAssign({ t, open, setOpen }) {
  let { dispatch } = AbstructVar();
  const { state, handleChangeAssign, setState, setLoad } = VariableAssign();
  const {
    cities,
    loadingCities,
    specializations,
    loadingspecializations,
    target_types,
    loadingtarget_type,
  } = useSelectorCustom();
  const { LoadSpecializationsCustom, LoadCitiesCustom, LoadTargetTypeCustom } =
    FunctionsLoading();
  const filter = (event) => {
    event.preventDefault();
    dispatch(
      filterrTargetGroup(
        "",
        "",
        state.search_value,
        state.target_type_id,
        state.city_id,
        state.specialization_id,
        state.medical_rep_name,
        state.created_at,  // Added created_at if needed
        setLoad
      )
    );
  };
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      center
    //   classNames={{
    //     modal: `popFilter`,
    //   }}
      focusTrapped={false}
    >
      <div className="div_popFilter">
        <h4>Filter On Target Group</h4>
        <div className="input_filter_assign">
          <input
            style={{ marginLeft: "10px" }}
            className="view_creatable1"
            placeholder={` ${t("search")} ...`}
            onChange={(e) => handleChangeAssign("search_value", e.target.value)}
          />
        </div>
        <div className="input_filter_assign">
          <SelectWithSearch
            load={loadingCities}
            typeCss={true}
            title={t("cities")}
            array={cities}
            setId={(value) => handleChangeAssign("city_id", value)}
            funb={LoadCitiesCustom}
          />
        </div>
        <div className="input_filter_assign">
          <SelectWithSearch
            load={loadingspecializations}
            typeCss={true}
            title={t("specializations")}
            array={specializations}
            setId={(value) => handleChangeAssign("specialization_id", value)}
            funb={LoadSpecializationsCustom}
          />
        </div>
        <div className="input_filter_assign">
          <SelectWithSearch
            load={loadingtarget_type}
            typeCss={true}
            title={t("target_type")}
            array={target_types}
            setId={(value) => handleChangeAssign("target_type_id", value)}
            funb={LoadTargetTypeCustom}
          />
        </div>
        <div className="input_filter_assign">
          <input
            style={{ marginLeft: "10px" }}
            className="view_creatable1"
            placeholder={` ${t("delegates")} ...`}
            onChange={(e) =>
              handleChangeAssign("medical_rep_name", e.target.value)
            }
          />
        </div>
        <button className="targetfilter_button" onClick={filter}>
          {state.Load ? "Loading ..." : `${t("filter")}`}
        </button>
      </div>
    </RModal>
  );
}

export default FilterAssign;
