import * as types from "../ActionType";

const initState = {
    WorkPlanMar: [],
    loadingWorkPlanMar: true,
    Unassign_Marketing: [],
    loadingUnassign_Marketing: true
}

const WorkPlan_MarketingReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_WORK_PLAN_MARKETING:
            return { ...state, WorkPlanMar: action.payload, loadingWorkPlanMar: false }
        case types.EMPTY_WORK_PLAN_MARKETING:
            return { ...state, WorkPlanMar: action.payload, loadingWorkPlanMar: true }
        case types.UNASSIGN_WORK_PLAN_MARKETING:
            return { ...state, loadingWorkPlanMar: true }
        case types.ASSIGN_WORK_PLAN_MARKETING:
            return { ...state, loadingWorkPlanMar: true }
        case types.GET_UNASSIGN_MARKETING:
            return { ...state, Unassign_Marketing: action.payload, loadingUnassign_Marketing: false }
        case types.EMPTY_UNASSIGN_MARKETING:
            return { ...state, Unassign_Marketing: action.payload, loadingUnassign_Marketing: true }
        default:
            return state;
    }
};

export default WorkPlan_MarketingReducer;
