import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { deleteReportType, loadReportType } from '../../../Redux/Actions/ActionReportType';
import InnerLoader from '../../General/InnerLoader';
import Warning from '../../General/Warning_Delete/Warning';
import EditReport from './EditReport';
import { DeleteComponent } from '../../General/GeneralComponent/DeleteComponent';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';
import { VariableReports } from './VariableReports';
import { CheckFont } from '../../General/GeneralComponent/CheckLang';

function ShowReports({ type,t, langmiamed }) {
    let dispatch = useDispatch();
    const {reportType,loadingReportType} = useSelectorCustom()
    const {state , handleEditReport,setreport_type_id} = VariableReports()
    const {stateD , warning , setdeletee,setloadDel} = DeleteComponent()
    const [allarr, setallarr] = useState([])
    const handleDelete = (id) => {
        dispatch(deleteReportType(id, setdeletee,setloadDel))
    }
    useEffect(() => {
        dispatch(loadReportType())
    }, [])
    useEffect(() => {
        if(reportType.length != 0)
        {
            const emptyarr = reportType.filter(rr => rr.service === type);
            setallarr(emptyarr);
        }
    }, [reportType])
    return (
        <>
            <div className={`set3 ${langmiamed == 'ar' ? 'margin_left_50' : "margin_right_50"}`} >
                <h5 className={langmiamed == 'ar' ? "textRight" : ""}>{t('reportType')}</h5>
                {
                    loadingReportType == false ? (
                        allarr.slice().reverse().map((rr) => {
                            return (
                                <>
                                    {
                                        state.report_type_id === rr.id ? (
                                            <EditReport langmiamed={langmiamed} arr={state.arr1} setreport_type_id={setreport_type_id} />
                                        ) :
                                            <div className='spec_class' key={rr.id} >
                                                
                                                <div className='head_report' >
                                                    <div className='setting_icon' >
                                                        <i className={`las la-caret-square-right ${langmiamed == 'ar' ? "margin_left_10": "margin_right_10"} `}></i>
                                                        <span style={{fontFamily: CheckFont(rr.name)}} className='name_spec' >{t(rr.name)}</span>
                                                    </div>
                                                </div>
                                                {/*<span  className={`servicereport ${langmiamed == 'ar' ? "textRight" : ""} `}>{rr.service}</span>*/}
                                                <span  className={`descreport ${langmiamed == 'ar' ? "textRight" : ""} `}>{rr.description}</span>
                                            </div>
                                    }
                                </>
                            )
                        })
                    ) : <div className='loadSetting' ><InnerLoader /></div>
                }
            </div>
            {
                stateD.deletee == true ? (
                    <Warning loadDel={stateD.loadDel}  arr={stateD.arrW} open={stateD.deletee} setOpen={setdeletee} handleDelete={handleDelete} />
                ) : <></>
            }
        </>
    )
}

export default ShowReports