import React from "react";
import Table2 from "../Table2/Table2";
import select from "../../images/icons/pointer.png";
import External_In_Map from "../External_Visites/External_In_Map";
import FilterStatistics from "../Main/Statistics/FilterStatistics";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import {
  ColumnArrayReportMarketingTools,
  VariableReports,
} from "./VariableReports";

function MarketingToolsReport({ langmiamed, t, navigate, setopenInternet }) {
  const { marketingTools, loagingMarketingTools } = useSelectorCustom();
  const reversedgeneralExReport = marketingTools?.reverse();
  const { state, storeMap, setIsOpen } = VariableReports();
  const { OrdersReqsColumn } = ColumnArrayReportMarketingTools(t, storeMap);
  return (
    <>
      <FilterStatistics
        t={t}
        langmiamed={langmiamed}
        params="marketing_tools_report"
        excel="marketing_tools_report"
      />
      <div className="users" dir={langmiamed == "ar" ? "ltr" : "ltr"}>
        {loagingMarketingTools == false ? (
          <Table2 col={OrdersReqsColumn} roo={reversedgeneralExReport} />
        ) : (
          <div className="alternative">
            <img src={select} />
            <br></br>
            <p>{t("filterr")}</p>
          </div>
        )}
      </div>
      {state.openMap && (
        <External_In_Map
          open={state.openMap}
          setOpen={setIsOpen}
          lat={state.lat}
          lng={state.lng}
        />
      )}
    </>
  );
}

export default MarketingToolsReport;
