import * as types from "../ActionType";

const initState = {
    medicalRep: [],
    loadingmedicalRep: true,
    TGbymedicalRep: [],
    loadingTG: true,
    visitsMedRep: [],
    loadingvisitsMedRep: true,
    ProductVisit: [],
    loadingProductVisit: true
}

const Medical_repReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_MEDICAL_REPS:
            return { ...state, medicalRep: action.payload, loadingmedicalRep: false }
        case types.DELETE_MEDICAL_REP:
            return { ...state, loadingmedicalRep: true }
        case types.ADD_MEDICAL_REP:
            return { ...state, loadingmedicalRep: true }
        case types.EDIT_MEDICAL_REP:
            return { ...state, loadingmedicalRep: true }
        case types.ASSIGN:
            return { ...state }
        case types.TARGET_GROUP_BY_MEDICAL:
            return { ...state, TGbymedicalRep: action.payload, loadingTG: false }
        case types.EMPTY_TARGET_GROUP_BY_MEDICAL:
            return { ...state, TGbymedicalRep: action.payload, loadingTG: true }
        case types.UNASSIGN:
            return { ...state, loadingTG: true }
        case types.GET_VISITS_BY_MED_REP:
            return { ...state, visitsMedRep: action.payload, loadingvisitsMedRep: false }
        case types.EMPTY_VISITS_BY_MED_REP:
            return { ...state, visitsMedRep: action.payload, loadingvisitsMedRep: true }
        case types.DELETE_VISITS_BY_MED_REP:
            return { ...state, loadingvisitsMedRep: true }
        case types.GET_PRODUCTS_BY_VISIT_MEDREP:
            return { ...state, ProductVisit: action.payload, loadingProductVisit: false }
        case types.EMPTY_PRODUCTS_BY_VISIT_MEDREP:
            return { ...state, ProductVisit: action.payload, loadingProductVisit: true }
        case types.DELETE_PRODUCTS_BY_VISIT_MEDREP:
            return { ...state, loadingProductVisit: true }
        case types.EXPORT_VISITS_BY_MEDREP:
            return { ...state }
        default:
            return state;
    }
};

export default Medical_repReducer;
