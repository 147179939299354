import React from 'react';
import useClickOutside from '../../useClickOutside';
import { CheckFont } from './GeneralComponent/CheckLang';

function MultiSelect({ toggleOption, selectedOptions, testarr, openRole, setopenRole, selectedOptionsName, typeCss }) {
    let domNode = useClickOutside(() => {
        setopenRole(false);
    });

    return (
        <>
            <div className='div_creatable nomarginLeft' style={{ width: '550px' }}>
                <div className={typeCss ? "view_creatable1 " : 'view_creatable '} onClick={() => setopenRole(true)}>
                    {
                        selectedOptionsName.length !== 0 ? (
                            <>
                                {
                                    selectedOptionsName.map((e) => {
                                        return (
                                            <span>{e}</span>
                                        )
                                    })
                                }
                            </>
                        ) : <span>Select</span>
                    }
                    <i className="las la-angle-double-down icon_searchselect"></i>
                </div>
                {openRole && (
                    <div className='select_creatable' ref={domNode}>
                        {testarr?.map((option) => (
                            <div key={option.id} className='lable_multi'>
                                <input
                                    type="checkbox"
                                    value={option.id}
                                    checked={selectedOptions.includes(option.id)}
                                    onChange={(e) => toggleOption(option.id, option.name)}

                                />
                                <span style={{fontFamily: CheckFont(option.name)}} >{option.name}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>

        </>
    );
}

export default MultiSelect;