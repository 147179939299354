import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {
  loadFilterVacation,
  loadVacation,
} from "../../Redux/Actions/actionVacation";
import nofilterr from "../../images/icons/nofilter.png";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { VariableVacations } from "./VariableVacations";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";

function FilterVacation({ setopenInternet, navigate, langmiamed, t }) {
  let dispatch = useDispatch();
  const { state, setState, handleChangeVacations, setLoad, handleChangeDate } =
    VariableVacations();
  const { medicalRep, loadingmedicalRep } = useSelectorCustom();
  const { LoadMedicalRepCustom } = FunctionsLoading();
  const [isEmpty, setisEmpty] = useState(false);
  const filter = (event) => {
    event.preventDefault();
    dispatch(
      loadFilterVacation(
        state.medical_rep_id,
        state.startDate,
        state.endDate,
        state.type,
        setLoad
      )
    );
  };
  const Unfilter = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      type: "",
      startDate: null,
      endDate: null,
    }));
    setisEmpty(true);
    dispatch(loadVacation(navigate, setopenInternet));
  };
  return (
    <div
      className={`targetfilter ${langmiamed == "ar" ? "padding_right_10" : ""}`}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <div className="oneFilter">
        <select
          defaultValue={state.type}
          onChange={(e) => handleChangeVacations(e.target.value, "type")}
        >
          <option selected="selected" value="">
            {t("vacationType")}
          </option>
          <option value="hourly">{t("half_day")}</option>
          <option value="daily">{t("day_off")}</option>
        </select>
      </div>
      <div className="oneFilter">
        <SelectWithSearch
          load={loadingmedicalRep}
          typeCss={true}
          title={t("delegates")}
          array={medicalRep}
          setId={(value) => handleChangeVacations(value, "medical_rep_id")}
          funb={LoadMedicalRepCustom}
          isEmpty={isEmpty}
        />
      </div>
      <div className="oneFilter">
        <DatePicker
          selected={state.startDate}
          onChange={handleChangeDate}
          startDate={state.startDate}
          endDate={state.endDate}
          selectsRange
          placeholderText={t("selectDate")}
          className="date_inn"
        />
      </div>
      <button className="targetfilter_button" onClick={filter}>
        {state.Load ? "Loading ..." : `${t("filter")}`}
      </button>
      <img
        onClick={Unfilter}
        title={t("unfilter")}
        className="targetfilter_button_img"
        src={nofilterr}
      />
    </div>
  );
}

export default FilterVacation;
