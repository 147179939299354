import React, { useState } from 'react';
import useClickOutside from '../../useClickOutside';
import ReactLoading from 'react-loading';

function SearchSelect({ load, openCity, setopenCity, searchValue1, handleInputChange, filteredCategories, handleOptionClick,typeCss,title,left }) {
    let domNode = useClickOutside(() => {
        setopenCity(false);
    });
    
    return (
        <div className={ left? 'div_creatable nomarginLeft':'div_creatable'}>
            <div className={ typeCss? "view_creatable1 ": 'view_creatable '} onClick={() => setopenCity(true)}>
                <span>{searchValue1 || `${title? title : "Select"}`}</span>
                <i className="las la-angle-double-down icon_searchselect"></i>
            </div>
            {openCity && (
                <div className='select_creatable' ref={domNode} >
                    <input
                        type="text"
                        value={searchValue1}
                        onChange={handleInputChange}
                        placeholder="Search ..."
                        className='input_custm_select'
                    />
                    {
                        load == false ? (
                            filteredCategories.map((cc) => (
                                <div
                                    className={`choose_creatable ${searchValue1 === cc.name ? "active_creatable" : ""}`}
                                    key={cc.id}
                                    onClick={() => handleOptionClick(cc)}
                                >
                                    <span>{cc.name}</span>
                                </div>
                            ))) : <div className='loadinselect'><ReactLoading type="spinningBubbles" color="#9699c2" height={'7%'} width={'7%'} /></div>
                    }
                </div>
            )}
        </div>
    );
}

export default SearchSelect;