import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
  notifyErrorNetWork,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";
import { LoadTgMed } from "./ActionTargetGroup";
import moment from "moment";

const getMedicalRep = (medicalRep) => {
  return {
    type: types.GET_MEDICAL_REPS,
    payload: medicalRep,
  };
};
const getVisitesMedicalRep = (visitsMedRep) => {
  return {
    type: types.GET_VISITS_BY_MED_REP,
    payload: visitsMedRep,
  };
};
const getProVisitesMedicalRep = (ProductVisit) => {
  return {
    type: types.GET_PRODUCTS_BY_VISIT_MEDREP,
    payload: ProductVisit,
  };
};

const delMedicalRep = () => {
  return {
    type: types.DELETE_MEDICAL_REP,
  };
};
const delProVisitesMedicalRep = () => {
  return {
    type: types.DELETE_PRODUCTS_BY_VISIT_MEDREP,
  };
};
const delVisiteMedicalRep = () => {
  return {
    type: types.DELETE_VISITS_BY_MED_REP,
  };
};
const addMedicalRep = () => {
  return {
    type: types.ADD_MEDICAL_REP,
  };
};
const editMedicalRep = () => {
  return {
    type: types.EDIT_MEDICAL_REP,
  };
};

const assignnMedicalRep = () => {
  return {
    type: types.ASSIGN,
  };
};

const unassignnMedicalRep = () => {
  return {
    type: types.UNASSIGN,
  };
};

const getTGbyMedicalRep = (TGbymedicalRep) => {
  return {
    type: types.TARGET_GROUP_BY_MEDICAL,
    payload: TGbymedicalRep,
  };
};
const emptyGeneral = (empty, typee) => {
  return {
    type: typee,
    payload: empty,
  };
};
const downloadvisitsByMed = () => {
  return {
    type: types.EXPORT_VISITS_BY_MEDREP,
  };
};

export const loadMedicalRep = (navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/medical-reps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getMedicalRep(res.data.data));
      })
      .catch((err) => {
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const deleteMedicalRep = (id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/medical-reps/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delMedicalRep());
          dispatch(loadMedicalRep());
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setdeletee(false);
        setloadDel(false);
      });
  };
};
export const postMedicalRep = (
  first_name,
  last_name,
  email,
  password,
  address,
  phone,
  gender,
  status,
  position,
  city_id,
  user_id,
  work_flow_id,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/medical-reps`,
        {
          first_name,
          last_name,
          email,
          password,
          address,
          phone,
          gender,
          status,
          position,
          city_id,
          user_id,
          work_flow_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addMedicalRep());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadMedicalRep());
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const putMedicalRep = (
  id,
  first_name,
  last_name,
  email,
  password,
  address,
  phone,
  gender,
  status,
  position,
  city_id,
  user_id,
  work_flow_id,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
  console.log("user_idReq",user_id);
  // var userId = user_id.map((e)=>e.id)
    if (password == "") {
      axios
        .put(
          `${base_url}api/medical-reps/${id}`,
          {
            user_id : user_id.map((e)=>e.id),
            first_name,
            last_name,
            email,
            address,
            phone,
            gender,
            status,
            position,
            city_id,
            work_flow_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            dispatch(editMedicalRep());
          if (res.data.status == "Success") {
            notifysuccess(res.data.message);
            dispatch(loadMedicalRep());
            setLoad(false);
            setOpen(false)
          }
        })
        .catch((err) => {
            notifyError(err.response.data.message);
          setLoad(false);
        });
    } else {
      axios
        .put(
          `${base_url}api/medical-reps/${id}`,
          {
            first_name,
            last_name,
            email,
            password,
            address,
            phone,
            gender,
            status,
            position,
            city_id,
            user_id,
            work_flow_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            dispatch(editMedicalRep());
          if (res.data.status == "Success") {
            notifysuccess(res.data.message);
            dispatch(loadMedicalRep());
            setLoad(false);
          }
        })
        .catch((err) => {
            notifyError(err.response.data.message);
          setLoad(false);
        });
    }
  };
};

export const loadFilterMedicalRep = (city_id, search_value, setLoad) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(
        `${base_url}api/medical-reps?filter[city_id]=${city_id}&filter[search_value]=${search_value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(getMedicalRep(res.data.data));
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};

export const assignMedicalRep = (medRep_id, target_groups, setLoad) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    if (medRep_id == "") {
      notifyError("يجب اختيار مندوب اولا");
      setLoad(false);
    } else {
      axios
        .post(
          `${base_url}api/medical-rep/${medRep_id}/assign-target-group`,
          {
            target_groups,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
            dispatch(assignnMedicalRep());
          if (res.data.status == "Success") {
            notifysuccess(res.data.message);
            dispatch(loadTGbyMedicalRep(medRep_id));
            dispatch(LoadTgMed(medRep_id));
            setLoad(false);
          }
        })
        .catch((err) => {
            notifyError(err.response.data.message);
          setLoad(false);
        });
    }
  };
};

export const loadTGbyMedicalRep = (id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/medical-rep/${id}/target-groups`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getTGbyMedicalRep(res.data.data));
      })
      .catch((err) => {
      });
  };
};

export const unassignMedicalRep = (medRep_id, target_groups, setLoad) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/medical-rep/${medRep_id}/unassign-target-group`,
        {
          target_groups,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(unassignnMedicalRep());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadTGbyMedicalRep(medRep_id));
          dispatch(LoadTgMed(medRep_id));
          setLoad(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const loadvisitesMedicalRep = (id, navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/target-group-visits/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getVisitesMedicalRep(res.data.data));
      })
      .catch((err) => {
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};
export const emptyVisitesMedicalRep = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_VISITS_BY_MED_REP));
  };
};

export const deleteVisiteMedicalRep = (id, med_id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/target-group-visits/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delVisiteMedicalRep());
          dispatch(loadvisitesMedicalRep(med_id));
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setdeletee(false);
        setloadDel(false);
      });
  };
};

export const loadProvisitesMedicalRep = (id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/target-group-visits/${id}/delivered-products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getProVisitesMedicalRep(res.data.data));
      })
      .catch((err) => {
      });
  };
};

export const emptyProvisitesMedicalRep = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_PRODUCTS_BY_VISIT_MEDREP));
  };
};

export const deleteProvisitesMedicalRep = (visit_id, id, setdeletee) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setdeletee(true);
    axios
      .delete(`${base_url}api/target-group-visits/delivered-products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delProVisitesMedicalRep());
          dispatch(loadProvisitesMedicalRep(visit_id));
          setdeletee(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setdeletee(false);
      });
  };
};

export const FiltervisitsByMed = (
  id,
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  is_site_match,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/target-group-visits/${id}?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&filter[is_site_match]=${is_site_match}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(getVisitesMedicalRep(res.data.data));
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};

export const FundownloadvisitsByMed = (
  id,
  start_date,
  end_date,
  target_group_type_id,
  report_type_id,
  is_site_match,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    if (start_date !== "" && end_date !== "") {
      start_date = moment(start_date).format("YYYY-MM-DD");
      end_date = moment(end_date).format("YYYY-MM-DD");
    }
    axios
      .get(
        `${base_url}api/target-group-visits/${id}/export?filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[target_group_type_id]=${target_group_type_id}&filter[report_type_id]=${report_type_id}&filter[is_site_match]=${is_site_match}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          const fileURL = `${base_url}${res.data.data}`;
          const downloadWindow = window.open(fileURL);
          if (downloadWindow === null) {
            console.error(
              "تعذر فتح نافذة تحميل. يُرجى التحقق من إعدادات المتصفح."
            );
          }
          dispatch(downloadvisitsByMed());
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};
