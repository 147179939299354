import React, { useState, useEffect } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import detail from "../../../images/information1.png";
import Location from "../../General/Location";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyTaskVisites,
  loadTaskVisites,
} from "../../../Redux/Actions/ActionTask";
import InnerLoader from "../../General/InnerLoader";

function TaskDetails({ langmiamed, t, details, open, setOpen }) {
  let dispatch = useDispatch();
  const taskVisites = useSelector((state) => state.work_task.taskVisites);
  const loadingTaskVisites = useSelector(
    (state) => state.work_task.loadingTaskVisites
  );
  const [type, setType] = useState("");
  const [is_product, setis_product] = useState(null);
  const [productArr, setproductArr] = useState([]);
  useEffect(() => {
    dispatch(loadTaskVisites(details.id));
  }, []);
  const [lat, setlat] = useState(null);
  const [lng, setlng] = useState(null);
  const storeMap = (lat, lng) => {
    setType("map");
    setlat(lat);
    setlng(lng);
  };
  const storeProduct = (product) => {
    setType("product");
    setis_product(50);
    setproductArr(product);
  };
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
        dispatch(emptyTaskVisites());
      }}
      center
      classNames={{
        modal: "detailsPop",
      }}
    >
      <>
        <div
          className="div_detailsPop"
          dir={langmiamed == "ar" ? "rtl" : "ltr"}
        >
          <div className="general_information">
            <img src={detail} />
            <div className="one_tab">
              <span>{t("General_Details")}</span>
            </div>
            <div className="gen">
              <h5>
                {t("This_task")} {details.status} ({details.priority_level})
              </h5>
              <span className="span_gen">
                {details.date} / {details.time}
              </span>
              <p>{details.note} </p>
              <div className="delegate_name">
                <i className="las la-user"></i>
                <span className="span_gen">{details.target_group_name}</span>
              </div>
            </div>
          </div>
          <div className="visitis_for_task">
            <h5 className={`bottom ${langmiamed == "ar" ? "textRight" : ""} `}>
              {t("taskvisit")}
            </h5>
            {loadingTaskVisites === false ? (
              taskVisites.map((tt) => {
                return (
                  <div className="one_visit">
                    <div className="info_visit">
                      {tt.is_site_match ? (
                        <div className="div_match">
                          <i
                            className={`las la-check-circle ${
                              langmiamed == "ar"
                                ? "margin_left_10"
                                : "margin_right_10"
                            }`}
                          ></i>
                          <span className="match_visit">
                            {t("Location_is_match")}{" "}
                          </span>
                        </div>
                      ) : (
                        <div className="div_match">
                          <i
                            className={`las la-times-circle  ${
                              langmiamed == "ar"
                                ? "margin_left_10"
                                : "margin_right_10"
                            }`}
                          ></i>
                          <span className="match_visit">
                            {t("Location_isnot_match")}
                          </span>
                        </div>
                      )}
                      <p
                        className={`margin_top_visit p_visit ${
                          langmiamed == "ar" ? "textRight" : ""
                        }`}
                      >
                        {tt.details}{" "}
                      </p>
                      <span
                        className={`report_type ${
                          langmiamed == "ar" ? "textRight" : ""
                        }`}
                      >
                        {tt.report_type_name}
                      </span>
                    </div>
                    <div className="map_visit">
                      <div
                        className="select_icon"
                        onClick={() => storeMap(tt.lat, tt.lng)}
                      >
                        <i className="las la-map-marked"></i>
                        <span>{t("location")}</span>
                      </div>
                      <div
                        className="select_icon"
                        onClick={() => storeProduct(tt.delivered_Products)}
                      >
                        <i className="las la-capsules"></i>
                        <span>{t("products")}</span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <InnerLoader />
            )}
          </div>
        </div>
        <div className="default_map">
          {type == "map" ? (
            lat == null || lng == null ? (
              <div>
                <p>{t("click")}</p>
                <p>{t("click1")}</p>
              </div>
            ) : (
              <Location latt={lat} lngg={lng} />
            )
          ) : type == "product" ? (
            is_product == null ? (
              <div>
                <p>{t("click")}</p>
                <p>{t("click1")}</p>
              </div>
            ) : (
              <div
                className="products_visite"
                dir={langmiamed == "ar" ? "rtl" : "ltr"}
              >
                {productArr.map((pp) => {
                  return (
                    <div className="one_products_visite">
                      {pp.product.media_urls.length != 0 ? (
                        <img src={pp.product.media_urls[0].url} />
                      ) : (
                        <div className="relatied_img"></div>
                      )}
                      <h5 className={langmiamed == "ar" ? "textRight" : ""}>
                        {t("Product_Name")}: {pp.product.name}
                      </h5>
                      <h5
                        className={`top_minac ${
                          langmiamed == "ar" ? "textRight" : ""
                        } `}
                      >
                        {t("Quantity")}: {pp.quantity}
                      </h5>
                      <h5
                        className={`top_minac ${
                          langmiamed == "ar" ? "textRight" : ""
                        } `}
                      >
                        {pp.product.product_type}
                      </h5>
                    </div>
                  );
                })}
              </div>
            )
          ) : (
            <div>
              <p>{t("click")}</p>
              <p>{t("click1")}</p>
            </div>
          )}
        </div>
      </>
    </RModal>
  );
}

export default TaskDetails;

/*
<img src={detail} />
                <div className='tabs_details'>
                    <div
                        className={`one_tab ${activeTab === 'General' ? 'active_task' : ''}`}
                        onClick={() => setActiveTab('General')}
                    >
                        <i className='las la-info'></i>
                        <span>General Details</span>
                    </div>
                    <div
                        className={`one_tab ${activeTab === 'Visites' ? 'active_task' : ''}`}
                        onClick={() => setActiveTab('Visites')}
                    >
                        <i className='las la-eye'></i>
                        <span>Visites</span>
                    </div>
                    <div
                        className={`one_tab ${activeTab === 'report' ? 'active_task' : ''}`}
                    >
                        <i className="las la-copy"></i>
                        <span>Reports</span>
                    </div>
                </div>
                {
                    activeTab == 'General' && (
                        <div className='gen' >
                            <h5>This task is {details.status} ({details.priority_level})</h5>
                            <span className='span_gen'>
                                {details.date} / {details.time}
                            </span>
                            <p>{details.note}</p>
                            <div className='delegate_name'>
                                <i className='las la-user'></i>
                                <span className='span_gen'>{details.target_group_name}</span>
                            </div>
                        </div>
                    )
                }
                {
                    activeTab == 'Visites' && (
                        <div className='visites' >
                            <div className='one_visit' >
                                <div className='visites_info'>
                                    <span className='span_gen'>The medical Representative location is <span className='match' >match</span> to the location of the target group</span>
                                    <br></br>
                                    <p className='margin_top_visit' >details details details details details details details details details details details details details details details details details </p>
                                    <span className='report_type' >Report Type</span>
                                </div>
                                <div className='visit_map' >
                                    <Location latt={33.510414} lngg={36.278336} />
                                </div>
                            </div>
                            <div className='one_visit' >
                                <div className='visites_info'>
                                    <span className='span_gen'>The medical Representative location is <span className='match' >match</span> to the location of the target group</span>
                                    <br></br>
                                    <p className='margin_top_visit' >details details details details details details details details details details details details details details details details details </p>
                                    <span className='report_type' >Report Type</span>
                                </div>
                                <div className='visit_map' >
                                    <Location latt={33.510414} lngg={36.278336} />
                                </div>
                    </div>
                        </div>
                    )
                }
                */
