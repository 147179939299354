import React from 'react'

function Add({langmiamed,icon , title}) {
    return (
        <div className='general_add' >
            <i className={`${icon} ${langmiamed == 'ar'? "margin_left_10":"margin_right_10"}`}></i>
            <span>{title}</span>
        </div>
    )
}

export default Add