import React from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

const ChartComponent = ({ langmiamed, color1, num1, num2, name1, name2 }) => {
  const total = num1 + num2;
  const data = [
    { name: `${name1}`, value: num1 },
    { name: `${name2}`, value: num2 },
  ];

  const COLORS = [`${color1}`, '#cecbcbcb'];

  return (
    <div className='div_chart'>
      {
        num1 == 0 && num2 == 0 ? (
          <div className='default_circle'>0</div>
        ) : <PieChart width={160} height={160}>
          <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      }

      <div className='apper'>
        <div className='one_square'>
          <div className='match_square' style={{ backgroundColor: `${color1}` }}></div>
          <span className={langmiamed == "ar" ? "textRight margin_right_10" : "margin_left_10"} >{name1}</span>
        </div>
        <div className='one_square'>
          <div className='not_match_square'></div>
          <span className={langmiamed == "ar" ? "textRight margin_right_10" : "margin_left_10"} >{name2}</span>
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;