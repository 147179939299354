import React, { useState, useEffect } from "react";
import {
  filterrTargetGroup,
  loadTargetGroup,
} from "../../../Redux/Actions/ActionTargetGroup";
import nofilterr from "../../../images/icons/nofilter.png";
import { useSelectorCustom } from "../../General/GeneralComponent/useSelectorCustom";
import { AbstructVar } from "../../General/GeneralComponent/AbstructVar";
import { FunctionsLoading } from "../../Main/Statistics/FunctionsLoading";
import { VariableTargetGroup } from "../VariableTargetGroup";
import SelectWithSearch from "../../General/GeneralComponent/SelectWithSearch";

function FilterTargetGroup({
  isNoFilter,
  setopenInternet,
  isDelete,
  isEdit,
  setisFilter,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  langmiamed,
  t,
  searchValue,
  setSearchValue,
}) {
  let { dispatch, navigate } = AbstructVar();

  const {
    cities,
    loadingCities,
    governorates,
    loadinGovernorate,
    specializations,
    loadingspecializations,
    target_types,
    loadingtarget_type,
  } = useSelectorCustom();

  const {
    LoadGovernoratesCustom,
    LoadCitiesCustom,
    LoadSpecializationsCustom,
    LoadTargetTypeCustom,
  } = FunctionsLoading();
  const { state, setState, handleChangeTargetGroup, setLoad } =
    VariableTargetGroup();

  useEffect(() => {
    LoadGovernoratesCustom(1)
  }, []);
  const [isEmpty, setisEmpty] = useState();
  useEffect(() => {
    if (isEdit === "edited") {
      dispatch(
        filterrTargetGroup(
          currentPage,
          itemsPerPage,
          state.search_value,
          state.target_type_id,
          state.city_id,
          state.specialization_id,
          "",
          state.created_at,
          setLoad
        )
      );
    }
    if (isDelete === "deleted") {
      dispatch(
        filterrTargetGroup(
          currentPage,
          itemsPerPage,
          state.search_value,
          state.target_type_id,
          state.city_id,
          state.specialization_id,
          "",
          state.created_at,
          setLoad
        )
      );
    }
    if (isNoFilter === "nofilter") {
      Unfilter();
    }
  }, [isEdit, currentPage, isDelete]);
  useEffect(() => {
    dispatch(
      filterrTargetGroup(
        currentPage,
        itemsPerPage,
        state.search_value,
        state.target_type_id,
        state.city_id,
        state.specialization_id,
        "",
        state.created_at,
        setLoad
      )
    );
  }, [currentPage]);
  const filter = (event) => {
    event.preventDefault();
    setisFilter("filter");
    setCurrentPage(1);
    currentPage = 1;
    dispatch(
      filterrTargetGroup(
        currentPage,
        itemsPerPage,
        state.search_value,
        state.target_type_id,
        state.city_id,
        state.specialization_id,
        "",
        state.created_at,
        setLoad
      )
    );
  };
  const Unfilter = (event) => {
    event.preventDefault();
    setSearchValue("");
    setCurrentPage(1);
    currentPage = 1;
    setState((prevState) => ({
      ...prevState,
      search_value: "",
      city_id: "",
      specialization_id: "",
      target_type_id: "",
      created_at: "",
    }));
    setisEmpty(true);
    dispatch(
      loadTargetGroup(currentPage, itemsPerPage, setopenInternet, navigate)
    );
  };
  return (
    <div
      className={`targetfilter ${langmiamed == "ar" ? "padding_right_10" : ""}`}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <div className="oneFilter width_23p">
        <input
          defaultValue={state.search_value}
          placeholder={` ${t("search")} ...`}
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "search_value")
          }
        />
      </div>
      <div className="oneFilter width_23p">
        <SelectWithSearch
          load={loadingCities}
          typeCss={true}
          title={t("cities")}
          array={cities}
          setId={(value) => handleChangeTargetGroup(value, "city_id")}
          funb={LoadCitiesCustom}
          isEmpty={isEmpty}
          type="cities"
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
      </div>
      <div className="oneFilter width_23p">
        <SelectWithSearch
          load={loadinGovernorate}
          typeCss={true}
          title={t("Regions")}
          array={governorates}
          setId={(value) => handleChangeTargetGroup(value, "city_id")}
          funb={LoadGovernoratesCustom}
          isEmpty={isEmpty}
          func={LoadCitiesCustom}
          type="Regions"
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
      </div>
      <div className="oneFilter width_23p">
        <SelectWithSearch
          load={loadingspecializations}
          typeCss={true}
          title={t("specializations")}
          array={specializations}
          setId={(value) => handleChangeTargetGroup(value, "specialization_id")}
          funb={LoadSpecializationsCustom}
          isEmpty={isEmpty}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
      </div>
      <div className="oneFilter width_23p">
        <SelectWithSearch
          load={loadingtarget_type}
          typeCss={true}
          title={t("target_type")}
          array={target_types}
          setId={(value) => handleChangeTargetGroup(value, "target_type_id")}
          funb={LoadTargetTypeCustom}
          isEmpty={isEmpty}
          tt={true}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />
      </div>
      <div className="oneFilter width_23p">
        <input
          type="date"
          defaultValue={state.search_value}
          placeholder={` ${t("date")} ...`}
          onChange={(e) =>
            handleChangeTargetGroup(e.target.value, "created_at")
          }
        />
      </div>
      <button className="targetfilter_button" onClick={filter}>
        {state.Load ? "Loading ..." : `${t("filter")}`}
      </button>
      <img
        onClick={Unfilter}
        title={t("unfilter")}
        className="targetfilter_button_img"
        src={nofilterr}
      />
    </div>
  );
}

export default FilterTargetGroup;
