import React, { useState } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch } from "react-redux";
import exportt from "../../../images/icons/export.png";
import filterr from "../../../images/icons/setting.png";
import {
  downloadTG,
  downloadTGWith,
} from "../../../Redux/Actions/ActionTargetGroup";
import { useSelectorCustom } from "../../General/GeneralComponent/useSelectorCustom";
import { FunctionsLoading } from "../../Main/Statistics/FunctionsLoading";
import { VariableTargetGroup } from "../VariableTargetGroup";
import SelectWithSearch from "../../General/GeneralComponent/SelectWithSearch";
import { CheckFont } from "../../General/GeneralComponent/CheckLang";
import { useTranslation } from "react-i18next";

function PopupExcel({ langmiamed, t, open, setOpen }) {
  const { i18n } = useTranslation();
  let dispatch = useDispatch();
  const {
    cities,
    loadingCities,
    specializations,
    loadingspecializations,
    target_types,
    loadingtarget_type,
  } = useSelectorCustom();
  const { LoadCitiesCustom, LoadSpecializationsCustom, LoadTargetTypeCustom } =
    FunctionsLoading();
  const { state, handleChangeTargetGroup, setLoad } = VariableTargetGroup();
  const [loadFile, setloadFile] = useState(false);
  const [mode, setmode] = useState("without");
  const withoutFilter = () => {
    dispatch(downloadTG(setloadFile, i18n.language,setOpen));
  };
  const filter = (event) => {
    event.preventDefault();
    dispatch(
      downloadTGWith(
        i18n.language,
        state.specialization_id,
        state.city_id,
        state.target_type_id,
        setLoad,
        setOpen
      )
    );
  };
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      center={true}
      classNames={{
        modal: "popExcel",
      }}
      focusTrapped={false}
    >
      {mode == "without" ? (
        <div className="div_popExcel">
          <div onClick={() => setmode("with")}>
            <img src={filterr} />
            <h4 style={{ fontFamily: CheckFont(t("withFilter")) }}>
              {t("withFilter")}
            </h4>
          </div>
          <div onClick={withoutFilter}>
            <img src={exportt} />
            <h4 style={{ fontFamily: CheckFont(t("withoutFilter")) }}>
              {loadFile ? t("Loading ...") : `${t("withoutFilter")}`}
            </h4>
          </div>
        </div>
      ) : (
        <div className="div_with">
          <div className="input_filter_assign">
            <SelectWithSearch
              load={loadingCities}
              typeCss={true}
              title={t("cities")}
              array={cities}
              setId={(value) => handleChangeTargetGroup(value, "city_id")}
              funb={LoadCitiesCustom}
            />
          </div>
          <div className="input_filter_assign">
            <SelectWithSearch
              load={loadingspecializations}
              typeCss={true}
              title={t("specializations")}
              array={specializations}
              setId={(value) =>
                handleChangeTargetGroup(value, "specialization_id")
              }
              funb={LoadSpecializationsCustom}
            />
          </div>
          <div className="input_filter_assign">
            <SelectWithSearch
              load={loadingtarget_type}
              typeCss={true}
              title={t("target_type")}
              array={target_types}
              setId={(value) =>
                handleChangeTargetGroup(value, "target_type_id")
              }
              funb={LoadTargetTypeCustom}
            />
          </div>
          <button
            style={{ fontFamily: CheckFont(t("download")) }}
            className="targetfilter_button"
            onClick={filter}
          >
            {state.Load ? t("Loading ...") : `${t("download")}`}
          </button>
        </div>
      )}
    </RModal>
  );
}

export default PopupExcel;
