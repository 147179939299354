import React from 'react'
import notfound from '../../images/notFound.png'

function OutNotFound() {
  return (
    <div className='noper' >
      <img src={notfound} />
      <br></br>
      <h3>Not Found</h3>
    </div>
  )
}

export default OutNotFound