import React,{useEffect} from 'react'
import { useSelector } from 'react-redux'
import { loadStatisticExternal } from '../../../Redux/Actions/ActionStatistics';
import repo from '../../../images/new side icon/profit-report.png'

function Scientific_Office_Statistics({langmiamed,dispatch,t}) {
    const statisticsExternal = useSelector(state => state.statisticss.statisticsExternal)
    useEffect(() => {
        dispatch(loadStatisticExternal())
    }, []);
    return (
        <div className="analytics" dir={langmiamed == 'ar' ? "rtl" : "ltr"}>
            {
                statisticsExternal.map((ss) => {
                    return (
                        <div className="card ">
                            <div className="card-head">
                                <h2 >{ss.visits_count}</h2>
                                <img src={repo} />
                            </div>
                            <div className="card-progress">
                                <div className={`desc_card ${langmiamed == 'ar' ? "textRight" : ""}`}>{t(ss.report_type_name)}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Scientific_Office_Statistics