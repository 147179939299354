import React, { useEffect, useState } from 'react'
import Table2 from '../../Table2/Table2'
import InnerLoader from '../../General/InnerLoader'
import './VisitesWorkPlan.css'
import External_In_Map from '../../External_Visites/External_In_Map'
import { useDispatch, useSelector } from "react-redux";
import { downloadReportss, emptyReports, loadReportss } from '../../../Redux/Actions/ActionReport'
import { useParams } from 'react-router-dom'
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import { CheckFont } from '../../General/GeneralComponent/CheckLang'

function VisitesWorkPlan({ langmiamed, t }) {
    let navigate = useNavigate()
    let dispatch = useDispatch();
    const reports = useSelector(state => state.reportss.reports)
    const loadingReports = useSelector(state => state.reportss.loadingReports)
    const [openMap, setOpenMap] = useState(false)
    const [lat, setlat] = useState(false)
    const [lng, setlng] = useState(false)
    const [loadFile, setloadFile] = useState(false)
    let { id } = useParams()
    useEffect(() => {
        dispatch(loadReportss(id))
        return(()=>{
            dispatch(emptyReports())
        })
    }, [])
    const storeMap = (lat, lng) => {
        setlat(lat)
        setlng(lng)
        setOpenMap(true)
    }
    const openDetailsTask = (task) => {
        /*setOpenDet(true);
        setdetails(task);*/
        navigate(`/work-plans/${id}/task/${task}`)
    };
    const columns1 = [

        {
            name: <span className="color_spann">{t('target_group')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.work_plan_task.target_group.name)}}>{row.work_plan_task.target_group.name}</span>
            )
        },
        {
            name: <span className="color_spann">{t('address')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.work_plan_task.target_group.address)}}>{row.work_plan_task.target_group.address}</span>
            )
        },
        {
            name: <span className="color_spann">{t('location')}</span>, sortable: true,
            cell: (row) => (
                <span>
                    {
                        row.is_site_match ? <i className="las la-check checkmatch"></i> : <i className="las la-times falsematch"></i>
                    }
                </span>
            )
        },
        {
            name: <span className="color_spann">{t('reportType')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.report_type.name)}}>{row.report_type.name}</span>
            )
        },
        {
            name: <span className="color_spann">{t('date')}</span>, sortable: true,
            cell: (row) => (
                <span>{moment(row['created_at']).format("YYYY-MM-DD")}</span>
            )
        },
        {
            name: <span className="color_spann">{t('houre')}</span>, sortable: true,
            cell: (row) => (
                <span>{moment(row['created_at']).format("HH:mm a")}</span>
            )
        },
        {
            name: <span width="100%" className="color_spann">{t('description')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.details)}}>{row['details']}</span>
            ), width: "250px"
        },
        {
            name: <span className="color_spann">{t('details')}</span>,
            cell: (row) => (
                <i className="las la-eye icon_edit_table" onClick={() => openDetailsTask(row.work_plan_task.id)}  ></i>
            )
        },
        {
            name: <span className="color_spann">{t('map')}</span>,
            cell: (row) => (
                <i className="las la-map-marked icon_edit_table" onClick={() => storeMap(row.lat, row.lng)}  ></i>
            )
        }
    ]
    const downloadExcel = () =>{
        dispatch(downloadReportss(id,setloadFile))
    }
    return (
        <>
            <div className='users' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                <div className='gen_filter' onClick={downloadExcel} >
                    {
                        loadFile? "Loading ..." : `${t('excel')}`
                    }
                </div>
                {
                    loadingReports == false ? (
                        <Table2 col={columns1} roo={reports} />
                    ) : <div className='div_loadd' ><InnerLoader /></div>
                }
            </div>
            {
                openMap && (
                    <External_In_Map open={openMap} setOpen={setOpenMap} lat={lat} lng={lng} />
                )
            }
            {/*
                openDet && (
                    <TaskDetails t={t} langmiamed={langmiamed} details={details} open={openDet} setOpen={setOpenDet} />
                )
                */}
        </>
    )
}

export default VisitesWorkPlan
