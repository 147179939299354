import React, { useState, useEffect } from 'react'
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import SelectWithSearch from '../General/GeneralComponent/SelectWithSearch';
import { VariableVacations } from './VariableVacations';
import moment from 'moment';
import { putVacation } from '../../Redux/Actions/actionVacation';

function EditVacation({ arr, open, setOpen }) {
    let { dispatch, langmiamed } = AbstructVar()
    const { medicalRep, loadingmedicalRep } = useSelectorCustom()
    const { LoadMedicalRepCustom } = FunctionsLoading()
    const { state, setState, handleChangeVacations, setLoad } = VariableVacations()
    const { translate } = AbstructLang()
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            type: arr.type,
            startDate: moment(arr.startDate).format("YYYY-MM-DD"),
            endDate: moment(arr.endDate).format("YYYY-MM-DD"),
            reason: arr.reason,
            medical_rep_id: arr.medical_rep_id,
            medical_rep_name: arr.medical_rep_name,
        }));
    }, [])
    const FunSubmit = (e) => {
        e.preventDefault()
        const start = moment(state.startDate).format("YYYY-MM-DD HH:mm:ss")
        let end;
        if(state.endDate === null){
            end = moment(state.endDate).format("YYYY-MM-DD HH:mm:ss")
        }else{
            end = null
        }
        dispatch(putVacation(arr.id, start, end, state.type, state.reason, state.medical_rep_id, setLoad,setOpen))
    }
    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            center={true}
            classNames={{
                modal: "popAdd",
            }}
            focusTrapped={false}
        >
            <div className='popAddForm' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                <h3 className={langmiamed == 'ar' ? "textRight margin_top_20" : ""}>{translate('editvacation')}</h3>
                <form>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-hand-pointer' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('type')}</span>
                            </div>
                            <select defaultValue={state.type} onChange={(e) => handleChangeVacations(e.target.value, 'type')} >
                                <option disabled="disabled" selected="selected">{translate('vacationType')}</option>
                                <option value='hourly' >{translate('half_day')}</option>
                                <option value='daily' >{translate('day_off')}</option>
                            </select>
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-user' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('medicalRep')}</span>
                            </div>
                            <SelectWithSearch
                                load={loadingmedicalRep}
                                array={medicalRep}
                                setId={(value) => handleChangeVacations(value, 'medical_rep_id')}
                                funb={LoadMedicalRepCustom}
                                typeCss={true}
                                name={state.medical_rep_name}
                                previousID={state.medical_rep_id}
                            />
                        </div>
                    </div>
                    {
                        state.type === 'daily' ? (
                            <div className='input_row11' >
                                <div className='one_input_row'>
                                    <div className='Gen_icon'>
                                        <i className='las la-calendar' ></i>
                                        <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('startDate')}</span>
                                    </div>
                                    <input defaultValue={state.startDate} type="date" onChange={(e) => handleChangeVacations(e.target.value, 'startDate')} />
                                </div>
                                <div className='one_input_row' >
                                    <div className='Gen_icon'>
                                        <i className='las la-calendar' ></i>
                                        <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('endDate')}</span>
                                    </div>
                                    <input defaultValue={state.endDate} type="date" onChange={(e) => handleChangeVacations(e.target.value, 'endDate')} />
                                </div>
                            </div>
                        ) :
                            <div className='input_row11' >
                                <div className='one_input_row'>
                                    <div className='Gen_icon'>
                                        <i className='las la-calendar' ></i>
                                        <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('date')}</span>
                                    </div>
                                    <input defaultValue={state.startDate} type="date" onChange={(e) => handleChangeVacations(e.target.value, 'startDate')} />
                                </div>
                            </div>
                    }

                    <div className='input_row11' >
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-pen' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('reason')}</span>
                            </div>
                            <textarea defaultValue={state.reason} onChange={(e) => handleChangeVacations(e.target.value, 'reason')} />
                        </div>
                    </div>
                </form>
                <div className='div_btn' ><button onClick={FunSubmit} >
                    {
                        state.Load ? "Loading ... " :
                            <>{translate('edit')}</>
                    }
                </button></div>
            </div>
        </RModal>
    )
}

export default EditVacation