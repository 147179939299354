import { useState } from "react"

export const VariableTask = () => {
    const [state, setState] = useState({
        time: "",
        priority_level: "",
        Load: false,
        status: "",
        target_group_id:'',
        note: '',
        date: '',
        target_group_name: ""
    })
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const handleChangeTask = (value, variableName) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    return {
        ...state , 
        state , 
        setState,
        handleChangeTask,
        setLoad
    }
}