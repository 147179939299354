import React from 'react'
import './General.css';
import lod from '../../images/favicon.png'

function InnerLoader() {
    return (
        <section className="loader1">
            <img src={lod} />
        </section>
    )
}

export default InnerLoader