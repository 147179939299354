import { useState } from "react";
import { CheckFont } from "../General/GeneralComponent/CheckLang";

export const VariableCountry = () => {
  const [state, setState] = useState({
    records: [],
    activeView: null,
    addCountry: false,
    addCity: false,
    addcity_id: "",
    nameCountry: "",
    editCountry: false,
    is_search: false,
    loadcityy: false,
    arr2: [],
    city_id: "",
    is_search: false,
  });
  const setRecords = (value) => {
    setState((prevState) => ({
      ...prevState,
      records: value,
    }));
  };
  const seteditCountry = (value) => {
    setState((prevState) => ({
      ...prevState,
      editCountry: value,
    }));
  };
  const setaddCity = (value) => {
    setState((prevState) => ({
      ...prevState,
      addCity: value,
    }));
  };
  const setaddCountry = (value) => {
    setState((prevState) => ({
      ...prevState,
      addCountry: value,
    }));
  };
  const setcity_id = (value) => {
    setState((prevState) => ({
      ...prevState,
      city_id: value,
    }));
  };
  const setis_search = (value) => {
    setState((prevState) => ({
      ...prevState,
      is_search: value,
    }));
  };
  function handleFilter(event, arr) {
    const newData = arr.filter((row) => {
      return row.name
        .toLocaleLowerCase()
        .includes(event.target.value.toLocaleLowerCase());
    });
    setState((prevState) => ({
      ...prevState,
      records: newData,
    }));
  }
  const handleEditCountry = (event, arr) => {
    event.preventDefault();
    const formValues = {
      id: arr.id,
      name: arr.name,
    };
    setState((prevState) => ({
      ...prevState,
      arr2: formValues,
      editCountry: true,
    }));
  };
  const handleEditCity = (event, arr) => {
    event.preventDefault();
    const formValues = {
      id: arr.id,
      name: arr.name,
      country_id: arr.country_id,
    };
    setState((prevState) => ({
      ...prevState,
      arr2: formValues,
      city_id: arr.id,
    }));
  };
  return {
    ...state,
    state,
    setState,
    handleFilter,
    setRecords,
    handleEditCountry,
    seteditCountry,
    setaddCity,
    setaddCountry,
    setcity_id,
    setis_search,
    handleEditCity,
  };
};

export const ColumnArrayLocation = (
  state,
  translate,
  handleEditCountry,
  warning,
  handleAddCity,
  storeId
) => {
  const columns_country = [
    {
      name: <span className="color_spann ">{translate("name")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.name) }}>{row["name"]}</span>
      ),
    },
    {
      name: <span className="color_spann">{translate("actions")}</span>,
      cell: (row) => (
        <>
          <i
            class="las la-pen icon_edit_table"
            onClick={(event) => handleEditCountry(event, row)}
          ></i>
          <i
            class="las la-times-circle  icon_del_table"
            onClick={() => warning(row)}
          ></i>
          <button
            className="btn_table"
            onClick={(event) => handleAddCity(event, row.id)}
          >
            {translate("add_city")}
          </button>
          {row.id === state.activeView ? (
            <button
              className="btn_table btn_view_table"
              onClick={() => storeId(row.id)}
            >
              {translate("view_cities")}
            </button>
          ) : (
            <button className="btn_table " onClick={() => storeId(row)}>
              {translate("view_cities")}
            </button>
          )}
        </>
      ),
    },
  ];
  return {
    columns_country,
  };
};
