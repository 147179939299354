import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const get = (array,type) => {
    return ({
        type: type,
        payload: array
    })
}

const edit = (type) => {
    return ({
        type: type
    })
}

export const loadPolitics = () => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/configurations`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(get(res.data.data,types.GET_POLITICS))
        }).catch((err) => {
        })
    }
}


export const editPrivacy = (value,setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.put(`${base_url}api/configurations/privacy?value=${value}`,{}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(edit(types.EDIT_PRIVACY));
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadPolitics())
                setLoad(false)
            }
        }).catch((err) => {
            notifyError(err.response.data.message)
            setLoad(false)
        })
    }
}

export const editAbout = (value,setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.put(`${base_url}api/configurations/about_us?value=${value}`,{}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(edit(types.EDIT_ABOUT));
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadPolitics())
                setLoad(false)
            }
        }).catch((err) => {
            notifyError(err.response.data.message)
            setLoad(false)
        })
    }
}




