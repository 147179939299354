import { useState } from "react"

export const VariableExternalVisit = () => {
    const [state, setState] = useState({
        medical_rep_id: "",
        city_id: "",
        Load: false,
        open: false,
        lat: '',
        lng: ''
    })
    const handleChangeExternalVisit = (value, variableName) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const openMap = (latt, lngg) => {
        setState((prevState) => ({
            ...prevState,
            open: true,
            lat: latt,
            lng: lngg
        }));
    }
    const setOpen = (value) => {
        setState((prevState) => ({
            ...prevState,
            open: value
        }));
    }
    return {
        ...state,
        state,
        setState,
        handleChangeExternalVisit,
        setLoad,
        openMap,
        setOpen
    }
}