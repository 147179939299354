import React from "react";
import Table2 from "../Table2/Table2";
import select from "../../images/icons/pointer.png";
import External_In_Map from "../External_Visites/External_In_Map";
import FilterStatistics from "../Main/Statistics/FilterStatistics";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { ColumnArrayMonthlyOrders, ColumnArrayMonthlyProductsSales, VariableReports } from "./VariableReports";

function ProductsSalesReport({ langmiamed, t, navigate, setopenInternet }) {
  const { monthlyProductsSales, loagingMonthlyProductsSales } =
    useSelectorCustom();
  const reversedgeneralExReport = monthlyProductsSales?.reverse();
  const { state, storeMap, setIsOpen } = VariableReports();
  const { productsMonthleSalesColumn } = ColumnArrayMonthlyProductsSales(t, storeMap);
  return (
    <>
      <FilterStatistics
        t={t}
        langmiamed={langmiamed}
        params="products_monthly_salesReport"
        excel="products_monthly_salesReport"
        
      />
      <div className="users" dir={langmiamed == "ar" ? "ltr" : "ltr"}>
        {!loagingMonthlyProductsSales  ? (
          <Table2 col={productsMonthleSalesColumn} roo={reversedgeneralExReport} />
        ) : (
          <div className="alternative">
            <img src={select} />
            <br></br>
            <p>{t("filterr")}</p>
          </div>
        )}
      </div>
      {state.openMap && (
        <External_In_Map
          open={state.openMap}
          setOpen={setIsOpen}
          lat={state.lat}
          lng={state.lng}
        />
      )}
    </>
  );
}

export default ProductsSalesReport;
