import React, { useState, useEffect } from "react";
import "./WorkFlow.css";
import Page_Header from "../General/Page_Header";
import ActionTop from "../General/ActionTop";
import { array_action } from "../../dummyData";
import floww from "../../images/workflow.png";
import AddWorkFlow from "./AddWorkFlow";
import {
  deleteWorkFlow,
  loadworkFlow,
} from "../../Redux/Actions/ActionWorkFlow";
import InnerLoader from "../General/InnerLoader";
import Warning from "../General/Warning_Delete/Warning";
import EditWorkFlow from "./EditWorkFlow";
import SinglePagenation from "../General/Pagination/SinglePagenation";
import { exportFile } from "../General/Notification";
import NoData from "../General/NoData/NoData";
import NoInternet from "../General/NotPermission/NoInternet";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { DeleteComponent } from "../General/GeneralComponent/DeleteComponent";
import { PagenationCustom } from "../General/GeneralComponent/PagenationCustom";
import { VariableWorkFlow } from "./VariableWorkFlow";
import { CheckFont } from "../General/GeneralComponent/CheckLang";

function WorkFlow() {
  const [openInternet, setopenInternet] = useState(false);
  let { dispatch, navigate, langmiamed } = AbstructVar();
  const { translate } = AbstructLang();
  const { workflow, loadingWorkflow } = useSelectorCustom();
  const reversedworkflow = [...workflow].reverse();
  const { state, handleEditWorkFlow, setwork_flow_id, openAddNew, setOpen } =
    VariableWorkFlow();
  const { stateD, warning, setdeletee, setloadDel } = DeleteComponent();
  const handleDelete = (id) => {
    dispatch(deleteWorkFlow(id, setdeletee, setloadDel));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadworkFlow(navigate, setopenInternet));
  }, []);
  const { displayArr, pageCount, changePage } = PagenationCustom(
    reversedworkflow,
    12
  );
  const exportToExcel = () => {
    exportFile(workflow, "work_flow_data");
  };
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={translate("workFlow")}
            path={`${translate("home")} / ${translate("workFlow")} `}
          />
        </main>
        <div className="page-content">
          <ActionTop
            exportToExcel={exportToExcel}
            langmiamed={langmiamed}
            array_action={array_action}
            FunctionCustum={openAddNew}
            is_view={true}
          />
          {state.open && (
            <AddWorkFlow
              t={translate}
              langmiamed={langmiamed}
              open={state.open}
              setOpen={setOpen}
            />
          )}
          <div className="allproducts" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            {loadingWorkflow == false ? (
              displayArr.map((ww) => {
                return (
                  <>
                    {state.work_flow_id === ww.id ? (
                      <EditWorkFlow
                        langmiamed={langmiamed}
                        arr={state.arr1}
                        setwork_flow_id={setwork_flow_id}
                      />
                    ) : (
                      <div className="oneFlow">
                        <div className="header_flow">
                          <img
                            className={
                              langmiamed == "ar"
                                ? "margin_left_20"
                                : "margin_right_20"
                            }
                            src={floww}
                          />
                          <h5 style={{ fontFamily: CheckFont(ww.name) }}>
                            {ww.name}
                          </h5>
                        </div>
                        <span
                          style={{ fontFamily: CheckFont(ww.city_name) }}
                          className="city_flow"
                        >
                          ... {ww.city_name} ...
                        </span>
                        <p style={{ fontFamily: CheckFont(ww.description) }}>
                          {ww.description}
                        </p>
                        <div className="flow_icon">
                          <i
                            className="las la-times-circle  delFlow"
                            onClick={() => warning(ww)}
                          ></i>
                          <i
                            className="las la-pen editFlow"
                            onClick={(event) => handleEditWorkFlow(event, ww)}
                          ></i>
                        </div>
                      </div>
                    )}
                  </>
                );
              })
            ) : (
              <div className="div_loadd">
                <InnerLoader />
              </div>
            )}
            {loadingWorkflow == false && workflow.length == 0 && <NoData />}
            <SinglePagenation pageCount={pageCount} changePage={changePage} />
          </div>
        </div>
      </div>
      {stateD.deletee == true ? (
        <Warning
          loadDel={stateD.loadDel}
          arr={stateD.arrW}
          open={stateD.deletee}
          setOpen={setdeletee}
          handleDelete={handleDelete}
        />
      ) : (
        <></>
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default WorkFlow;
