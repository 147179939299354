import { useState } from "react"

export const VariableProducts = () => {
    const [state, setState] = useState({
        open: false,
        openEdit: false,
        openMar:false,
        arr1: [],
        search_value: "",
        product_type: "",
        Load: false,
        measurement_unit_id: "",
        measurement_unit_name:"",
        product_category_id: "",
        product_category_name: "",
        name: "",
        color: "#878585",
        image: "",
        description: "",
        internal_reference: "",
        LoadUnit: false
    })
    const setOpen = (value) => {
        setState((prevState) => ({
            ...prevState,
            open: value,
        }));
    };
    const setOpenMar = (value) => {
        setState((prevState) => ({
            ...prevState,
            openMar: value,
        }));
    };
    const setopenEdit = (value) => {
        setState((prevState) => ({
            ...prevState,
            openEdit: value,
        }));
    };
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setLoadUnit = (value) => {
        setState((prevState) => ({
            ...prevState,
            LoadUnit: value,
        }));
    };
    const handleEditProduct = (event, arr) => {
        event.preventDefault();
        let formValues = {
            id: arr.id,
            name: arr.name,
            description: arr.description,
            category_id: arr.category_id,
            category_name: arr.category_name,
            internal_reference: arr.internal_reference,
            measurement_unit_id: arr.measurement_unit_id,
            measurement_unit_name: arr.measurement_unit_name,
            color: arr.color,
            product_type: arr.product_type
        };
        setState((prevState) => ({
            ...prevState,
            arr1: formValues,
            openEdit: true,
        }));
    }
    const handleChangeProducts = (value, variableName) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    return{
        ...state,
        state,
        setState,
        setOpen,
        setOpenMar,
        setopenEdit,
        handleEditProduct,
        handleChangeProducts,
        setLoad,
        setLoadUnit
    }
}