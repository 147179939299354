import React, { useEffect } from 'react'
import Page_Header from '../../General/Page_Header';
import { loadNotification } from '../../../Redux/Actions/ActionAuth';
import SinglePagenation from '../../General/Pagination/SinglePagenation';
import InnerLoader from '../../General/InnerLoader';
import moment from 'moment';
import { AbstructVar } from '../../General/GeneralComponent/AbstructVar';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';
import { PagenationCustom } from '../../General/GeneralComponent/PagenationCustom';
import { AbstructLang } from '../../General/GeneralComponent/AbstructLang';
import { CheckFont } from '../../General/GeneralComponent/CheckLang';

function PageNotification() {
    let { navigate, langmiamed, dispatch } = AbstructVar()
    const { notify, loadingNotify } = useSelectorCustom()
    const { translate } = AbstructLang();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadNotification())
    }, []);
    const { displayArr, pageCount, changePage } = PagenationCustom(notify, 12)
    const ToDetails = (work_plan_id, task_id,service) => {
        if (work_plan_id !== null && task_id !== null && service === "WorkPlanVisit" ) {
            navigate(`/work-plans/${work_plan_id}/task/${task_id}`)
        }
        if(service === "MedicalRepVacation"){
            navigate('/vacation')
        }
        if(service === "TargetGroup"){
            navigate('/target-group')
        }
        if(service === "ExternalVisit"){
            navigate('/external-visites')
        }
    }
    return (
        <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
            <main>
                <Page_Header langmiamed={langmiamed} header={translate('notification')} path={`${translate('home')} / ${translate('notification')} `} />
            </main>
            <div className="page-content" >
                <div className='main_notify' dir={langmiamed == 'ar' ? "rtl" : "ltr"} >
                    <br></br>
                    {
                        loadingNotify == false ? (
                            <div className='all_notify' >
                                {
                                    displayArr.map((nn) => {
                                        return (
                                            <div key={nn.id} className={`one_notify ${langmiamed == 'ar' ? "boder_rightt" : "boder_leftt"}`} onClick={() => ToDetails(nn.work_plan_id, nn.work_plan_task_id,nn.service)} >
                                                <span style={{ fontFamily: CheckFont(nn.title) }} className={`tit_notify ${langmiamed == 'ar' ? "textRight" : ""}`}>{nn.title}</span>
                                                <span style={{ fontFamily: CheckFont(nn.body) }} className={langmiamed == 'ar' ? "textRight" : ""}>{nn.body}</span>
                                                <span>{moment(nn.created_at).format("YYYY-MM-DD")} / {moment(nn.created_at).format("HH:mm A")}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>) : <div className='div_loadd' ><InnerLoader /></div>
                    }


                    <SinglePagenation pageCount={pageCount} changePage={changePage} />
                </div>
            </div>
        </div>
    )
}

export default PageNotification