import React, { useState, useEffect } from "react";
import useClickOutside from "../../useClickOutside";
import Add from "../General/Add";
import {
  PostUser,
  emptySenior,
  loadSeniorSupervisor,
} from "../../Redux/Actions/ActionAuth";
import ReactLoading from "react-loading";
import MultiSelect from "../General/MultiSelect";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { VariableUser } from "./VariableUser";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";

function AddUser({ langmiamed, open, setOpen, t }) {
  const { dispatch } = AbstructVar();
  const { cities, loadingCities, senior_supervisor } = useSelectorCustom();
  const {
    state,
    setLoad,
    handleChangeUser,
    viewSenior_super,
    setShow,
    setLoadSuper,
  } = VariableUser();
  const { LoadCitiesCustom } = FunctionsLoading();
  const [openRole, setopenRole] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  useEffect(() => {
    return () => {
      dispatch(emptySenior());
    };
  }, []);
  let domNode = useClickOutside(() => {
    setOpen(false);
  });
  const viewSenior_super1 = (ser) => {
    setShow(ser);
    setSelectedOptionsName([]);
    setSelectedOptions([]);
    dispatch(loadSeniorSupervisor(ser, setLoadSuper));
  };
  const sendUser = (event) => {
    event.preventDefault();
    dispatch(
      PostUser(
        state.first_name,
        state.last_name,
        state.email,
        state.password,
        state.address,
        state.phone,
        state.role,
        state.gender,
        state.city_id,
        selectedOptions,
        setLoad,
        setOpen,
        setOpen
      )
    );
  };
  const toggleOption = (optionId, optionName) => {
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(optionId)) {
        return prevOptions.filter((opt) => opt !== optionId);
      } else {
        return [...prevOptions, optionId];
      }
    });
    setSelectedOptionsName((prevOptions) => {
      if (prevOptions.includes(optionName)) {
        return prevOptions.filter((opt) => opt !== optionName);
      } else {
        return [...prevOptions, optionName];
      }
    });
  };
  return (
    <div
      className={`add_target_group ${
        langmiamed == "ar" ? "add_target_group_ar" : "add_target_group_en"
      } `}
      ref={domNode}
      dir={langmiamed == "ar" ? "rtl" : "ltr"}
    >
      <Add
        langmiamed={langmiamed}
        icon="las la-plus-circle"
        title={`${t("addUser")}`}
      />
      <form className="general_form">
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("fistName")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="text"
          onChange={(e) => handleChangeUser(e.target.value, "first_name")}
        />
        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("lastName")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="text"
          onChange={(e) => handleChangeUser(e.target.value, "last_name")}
        />
        <div className="form_header">
          <i
            className={`las la-envelope ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("email")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="email"
          onChange={(e) => handleChangeUser(e.target.value, "email")}
        />
        <div className="form_header">
          <i
            className={`las la-key ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("password")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="password"
          onChange={(e) => handleChangeUser(e.target.value, "password")}
        />
        <div className="form_header">
          <i
            className={`las la-phone-volume ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("phone")}</span>
          <span className="require">*</span>
        </div>
        <input
          type="number"
          onChange={(e) => handleChangeUser(e.target.value, "phone")}
        />
        <div className="form_header">
          <i
            className={`las la-map-marker ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("address")}</span>
          {/* <span className="require">*</span> */}
        </div>
        <input
          type="text"
          onChange={(e) => handleChangeUser(e.target.value, "address")}
        />
        <div className="form_header">
          <i
            className={`las la-user-tag ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("role")}</span>
          <span className="require">*</span>
        </div>
        <select onChange={(e) => viewSenior_super(e.target.value)}>
          <option disabled="disabled" selected="selected">
            {t("role")}
          </option>
          <option value="admin">Admin</option>
          <option value="markting_manager">Markting Manager</option>
          <option value="head_of_section">Head Of Section</option>
          <option value="senior_supervisor">Senior Supervisor</option>
          <option value="supervisor">Supervisor</option>
          <option value="senior_medical_rep">Senior Medical Rep</option>
        </select>
        {state.otherrole == "senior_medical_rep" && (
          <>
            <div className="form_header">
              {state.LoadSuper ? (
                <ReactLoading
                  type="spinningBubbles"
                  color="#9699c2"
                  height={"5%"}
                  width={"5%"}
                />
              ) : (
                <i
                  className={`las la-user-tag ${
                    langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                  }`}
                ></i>
              )}
              <span>{t("responsible")}</span>
              <span className="require">*</span>
            </div>
            <select onChange={(e) => viewSenior_super1(e.target.value)}>
              <option disabled="disabled" selected="selected">
                {t("role")}
              </option>
              <option value="admin">Admin</option>
              <option value="markting_manager">Markting Manager</option>
              <option value="head_of_section">Head Of Section</option>
              <option value="senior_supervisor">Senior Supervisor</option>
              <option value="supervisor">Supervisor</option>
            </select>
          </>
        )}
        {state.otherrole == "supervisor" && (
          <>
            <div className="form_header">
              {state.LoadSuper ? (
                <ReactLoading
                  type="spinningBubbles"
                  color="#9699c2"
                  height={"5%"}
                  width={"5%"}
                />
              ) : (
                <i
                  className={`las la-user-tag ${
                    langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                  }`}
                ></i>
              )}
              <span>{t("responsible")}</span>
              <span className="require">*</span>
            </div>
            <select onChange={(e) => viewSenior_super1(e.target.value)}>
              <option disabled="disabled" selected="selected">
                {t("responsible")}
              </option>
              <option value="admin">Admin</option>
              <option value="markting_manager">Markting Manager</option>
              <option value="head_of_section">Head Of Section</option>
              <option value="senior_supervisor">Senior Supervisor</option>
            </select>
          </>
        )}
        {state.otherrole == "senior_supervisor" && (
          <>
            <div className="form_header">
              {state.LoadSuper ? (
                <ReactLoading
                  type="spinningBubbles"
                  color="#9699c2"
                  height={"5%"}
                  width={"5%"}
                />
              ) : (
                <i
                  className={`las la-user-tag ${
                    langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                  }`}
                ></i>
              )}
              <span>{t("responsible")}</span>
              <span className="require">*</span>
            </div>
            <select onChange={(e) => viewSenior_super1(e.target.value)}>
              <option disabled="disabled" selected="selected">
                {t("role")}
              </option>
              <option value="admin">Admin</option>
              <option value="markting_manager">Markting Manager</option>
              <option value="head_of_section">Head Of Section</option>
            </select>
          </>
        )}
        {state.show != "" ? (
          <>
            <div className="form_header">
              <i
                className={`las la-user-tag ${
                  langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                }`}
              ></i>
              <span>name of {t("responsible")}</span>
              <span className="require">*</span>
            </div>
            <MultiSelect
              selectedOptions={selectedOptions}
              testarr={senior_supervisor}
              openRole={openRole}
              setopenRole={setopenRole}
              selectedOptionsName={selectedOptionsName}
              toggleOption={toggleOption}
            />
          </>
        ) : (
          <></>
        )}

        <div className="form_header">
          <i
            className={`las la-pencil-alt ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("gender")}</span>
          <span className="require">*</span>
        </div>
        <select onChange={(e) => handleChangeUser(e.target.value, "gender")}>
          <option disabled="disabled" selected="selected">
            {t("gender")}
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <div className="form_header">
          <i
            className={`las la-city ${
              langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
            }`}
          ></i>
          <span>{t("cities")}</span>
          <span className="require">*</span>
        </div>
        <SelectWithSearch
          load={loadingCities}
          title={t("cities")}
          array={cities}
          setId={(value) => handleChangeUser(value, "city_id")}
          funb={LoadCitiesCustom}
        />
        <button disabled={state.Load} onClick={sendUser}>
          {state.Load ? t("Loading ...") : `${t("save")}`}
        </button>
      </form>
    </div>
  );
}

export default AddUser;
