import React, { useState, useEffect } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useTranslation } from "react-i18next";
import "./Users.css";
import { useDispatch, useSelector } from "react-redux";
import { loadCities } from "../../Redux/Actions/ActionCountries";
import {
  emptySenior,
  loadSeniorSupervisor,
  postEditUser,
} from "../../Redux/Actions/ActionAuth";
import SearchSelect from "../General/SelectSearch";
import ReactLoading from "react-loading";
import MultiSelectEdit from "../General/MultiSelectEdit";

function EditUser({ arr, open, setOpen }) {
  let dispatch = useDispatch();
  const cities = useSelector((state) => state.countries.cities);
  const loadingCities = useSelector((state) => state.countries.loadingCities);
  const senior_supervisor = useSelector(
    (state) => state.auth.senior_supervisor
  );
  let langmiamed = localStorage.getItem("langmiamed");
  const [t, i18n] = useTranslation();
  const [first_name, setfirst_name] = useState(arr.first_name);
  const [last_name, setlast_name] = useState(arr.last_name);
  const [email, setemail] = useState(arr.email);
  const [password, setpassword] = useState("");
  const [address, setaddress] = useState(arr.address);
  const [phone, setphone] = useState(arr.phone);
  const [role, setrole] = useState(arr.role);
  const [otherrole, setotherrole] = useState(arr.role);
  const [otherrole1, setotherrole1] = useState("");
  const [gender, setgender] = useState(arr.gender);
  const [Load, setLoad] = useState(false);
  const [LoadSuper, setLoadSuper] = useState(false);
  const [show, setShow] = useState("");
  const [relatedUsers, setrelatedUsers] = useState(arr.relatedUsers);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const [openRole, setopenRole] = useState(false);
  useEffect(() => {
    dispatch(loadCities());
    const extractedIds = relatedUsers.map((user) => user.id);
    const extractedNames = relatedUsers.map(
      (user) => `${user.first_name} ${user.last_name}`
    );

    setSelectedOptions(extractedIds);
    setSelectedOptionsName(extractedNames);
    if (arr.relatedUsers.length != null && arr.relatedUsers.length != 0) {
      setShow("yes");
      setotherrole1(arr.relatedUsers[0].role);
      dispatch(loadSeniorSupervisor(arr.relatedUsers[0].role, setLoadSuper));
    }
  }, []);
  useEffect(() => {
    setfiltered(cities);
  }, [cities]);
  const [openCity, setopenCity] = useState(false);
  const [searchValue1, setSearchValue1] = useState(arr.city_name);
  const [city_id, setSearchValue2] = useState(arr.city_id);
  const [filtered, setfiltered] = useState([]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue1(inputValue);
    const filtered = cities.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setfiltered(filtered);
  };

  const handleOptionClick = (item) => {
    setSearchValue1(item.name);
    setSearchValue2(item.id);
    setopenCity(false);
  };
  const viewSenior_super = (ser) => {
    setrole(ser);
    if (ser == "senior_medical_rep") {
      setotherrole("senior_medical_rep");
    }
    if (ser == "supervisor") {
      setotherrole("supervisor");
    }
    if (ser == "senior_supervisor") {
      setotherrole("senior_supervisor");
    }
    if (ser == "markting_manager") {
      setotherrole("");
      dispatch(loadSeniorSupervisor(ser, setLoadSuper));
    }
    if (ser == "head_of_section") {
      setotherrole("");
      dispatch(loadSeniorSupervisor(ser, setLoadSuper));
    }
    if (ser == "admin") {
      setotherrole("");
      dispatch(loadSeniorSupervisor(ser, setLoadSuper));
    }
  };
  const viewSenior_super1 = (ser) => {
    setSelectedOptions([]);
    setSelectedOptionsName([]);
    setShow(ser);
    setotherrole1(ser);
    dispatch(loadSeniorSupervisor(ser, setLoadSuper));
  };
  const toggleOption = (optionId, optionName) => {
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(optionId)) {
        return prevOptions.filter((opt) => opt !== optionId);
      } else {
        return [...prevOptions, optionId];
      }
    });
    setSelectedOptionsName((prevOptions) => {
      if (prevOptions.includes(optionName)) {
        return prevOptions.filter((opt) => opt !== optionName);
      } else {
        return [...prevOptions, optionName];
      }
    });
  };
  const FunSubmit = (e) => {
    e.preventDefault();
    dispatch(
      postEditUser(
        arr.id,
        first_name,
        last_name,
        email,
        password,
        address,
        phone,
        role,
        gender,
        city_id,
        selectedOptions,
        setLoad,
        setOpen
      )
    );
  };
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
        dispatch(emptySenior());
      }}
      center={false}
      classNames={{
        modal: "popAdd",
      }}
      focusTrapped={false}
    >
      <div className="popAddForm" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
        <h3 className={langmiamed == "ar" ? "textRight margin_top_20" : ""}>
          {t("editUser")}
        </h3>
        <form>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("fistName")}
                </span>
              </div>
              <input
                defaultValue={first_name}
                type="text"
                placeholder={t("fistName")}
                onChange={(e) => setfirst_name(e.target.value)}
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("lastName")}
                </span>
              </div>
              <input
                defaultValue={last_name}
                type="text"
                placeholder={t("lastName")}
                onChange={(e) => setlast_name(e.target.value)}
              />
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-envelope"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("email")}
                </span>
              </div>
              <input
                defaultValue={email}
                type="email"
                placeholder={t("email")}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-key"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("password")}
                </span>
              </div>
              <input
                type="password"
                placeholder={t("password")}
                onChange={(e) => setpassword(e.target.value)}
              />
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-map-marker"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("address")}
                </span>
              </div>
              <input
                defaultValue={address}
                type="text"
                placeholder={t("address")}
                onChange={(e) => setaddress(e.target.files[0])}
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-phone-volume"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("phone")}
                </span>
              </div>
              <input
                defaultValue={phone}
                type="number"
                placeholder={t("phone")}
                onChange={(e) => setphone(e.target.files[0])}
              />
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("gender")}
                </span>
              </div>
              <select
                value={gender}
                onChange={(e) => setgender(e.target.value)}
              >
                <option disabled="disabled" selected="selected">
                  {t("gender")}
                </option>
                <option>Male</option>
                <option>Female</option>
              </select>
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                {LoadSuper ? (
                  <ReactLoading
                    type="spinningBubbles"
                    color="#9699c2"
                    height={"5%"}
                    width={"5%"}
                  />
                ) : (
                  <i className="las la-user-tag"></i>
                )}

                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("role")}
                </span>
              </div>
              <select
                value={role}
                onChange={(e) => viewSenior_super(e.target.value)}
              >
                <option value="admin">Admin</option>
                <option value="markting_manager">Markting Manager</option>
                <option value="head_of_section">Head Of Section</option>
                <option value="senior_supervisor">Senior Supervisor</option>
                <option value="supervisor">Supervisor</option>
                <option value="senior_medical_rep">Senior Medical Rep</option>
              </select>
            </div>
          </div>
          <div className="input_row11">
            {otherrole == "senior_medical_rep" && (
              <div className="one_input_row">
                <div className="Gen_icon">
                  {LoadSuper ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#9699c2"
                      height={"5%"}
                      width={"5%"}
                    />
                  ) : (
                    <i
                      className={`las la-user-tag ${
                        langmiamed == "ar"
                          ? "margin_left_10"
                          : "margin_right_10"
                      }`}
                    ></i>
                  )}
                  <span>{t("responsible")}</span>
                  <span className="require">*</span>
                </div>
                <select
                  value={otherrole1}
                  onChange={(e) => viewSenior_super1(e.target.value)}
                >
                  <option value="admin">Admin</option>
                  <option value="markting_manager">Markting Manager</option>
                  <option value="head_of_section">Head Of Section</option>
                  <option value="senior_supervisor">Senior Supervisor</option>
                  <option value="supervisor">Supervisor</option>
                </select>
              </div>
            )}
            {otherrole == "supervisor" && (
              <div className="one_input_row">
                <div className="Gen_icon">
                  {LoadSuper ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#9699c2"
                      height={"5%"}
                      width={"5%"}
                    />
                  ) : (
                    <i
                      className={`las la-user-tag ${
                        langmiamed == "ar"
                          ? "margin_left_10"
                          : "margin_right_10"
                      }`}
                    ></i>
                  )}
                  <span>{t("responsible")}</span>
                  <span className="require">*</span>
                </div>
                <select
                  value={otherrole1}
                  onChange={(e) => viewSenior_super1(e.target.value)}
                >
                  <option value="admin">Admin</option>
                  <option value="markting_manager">Markting Manager</option>
                  <option value="head_of_section">Head Of Section</option>
                  <option value="senior_supervisor">Senior Supervisor</option>
                </select>
              </div>
            )}
            {otherrole == "senior_supervisor" && (
              <div className="one_input_row">
                <div className="Gen_icon">
                  {LoadSuper ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#9699c2"
                      height={"5%"}
                      width={"5%"}
                    />
                  ) : (
                    <i
                      className={`las la-user-tag ${
                        langmiamed == "ar"
                          ? "margin_left_10"
                          : "margin_right_10"
                      }`}
                    ></i>
                  )}
                  <span>{t("responsible")}</span>
                  <span className="require">*</span>
                </div>
                <select
                  value={otherrole1}
                  onChange={(e) => viewSenior_super1(e.target.value)}
                >
                  <option value="admin">Admin</option>
                  <option value="markting_manager">Markting Manager</option>
                  <option value="head_of_section">Head Of Section</option>
                </select>
              </div>
            )}
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-city"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {t("cities")}
                </span>
              </div>
              <SearchSelect
                openCity={openCity}
                setopenCity={setopenCity}
                searchValue1={searchValue1}
                handleInputChange={handleInputChange}
                filteredCategories={filtered}
                handleOptionClick={handleOptionClick}
                load={loadingCities}
                typeCss={true}
              />
            </div>
          </div>
          <div className="input_row11">
            {show != "" ? (
              <div className="one_input_row">
                <div className="Gen_icon">
                  <i
                    className={`las la-user-tag ${
                      langmiamed == "ar" ? "margin_left_10" : "margin_right_10"
                    }`}
                  ></i>
                  <span>{otherrole1}</span>
                  <span className="require">*</span>
                </div>
                <MultiSelectEdit
                  id={arr.id}
                  selectedOptions={selectedOptions}
                  testarr={senior_supervisor}
                  openRole={openRole}
                  setopenRole={setopenRole}
                  selectedOptionsName={selectedOptionsName}
                  toggleOption={toggleOption}
                  iswidth={true}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </form>
        <div className="div_btn">
          <button disabled={Load} onClick={FunSubmit}>
            {Load ? t("Loading ... ") : <>{t("edit")}</>}
          </button>
        </div>
      </div>
    </RModal>
  );
}

export default EditUser;
