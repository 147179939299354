import * as types from "../ActionType";

const initState = {
    cities: [],
    countriess: [],
    loadingCities: true,
    loadingCountries: true
}

const CountriesReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_ALL_CITIES:
            return { ...state, cities: action.payload, loadingCities: false }
        case types.ADD_COUNTRY:
            return { ...state, loadingCountries: true }
        case types.GET_ALL_COUNTRIES:
            return { ...state, countriess: action.payload, loadingCountries: false }
        case types.DELETE_COUNTRY:
            return { ...state, loadingCountries: true }
        case types.EDIT_COUNTRY:
            return { ...state, loadingCountries: true }
        case types.ADD_CITY_TO_COUNTRY:
            return { ...state, loadingCities: true }
        case types.DELETE_CITY:
            return { ...state, loadingCities: true }
        case types.EDIT_CITY:
            return { ...state, loadingCities: true }
        default:
            return state;
    }
};

export default CountriesReducer;
