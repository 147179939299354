import * as types from "../ActionType";

const initState = {
    WorkPlanPro: [],
    loadingWorkPlanPro: true,
    Unassign_product: [],
    loadingUnassign_product: true
}

const WorkPlan_Reducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_WORK_PLAN_PRODUCTS:
            return { ...state, WorkPlanPro: action.payload, loadingWorkPlanPro: false }
        case types.EMPTY_WORK_PLAN_PRODUCTS:
            return { ...state, WorkPlanPro: action.payload, loadingWorkPlanPro: true }
        case types.UNASSIGN_WORK_PLAN_PRODUCTS:
            return { ...state, loadingWorkPlanPro: true }
        case types.ASSIGN_WORK_PLAN_PRODUCTS:
            return { ...state, loadingWorkPlanPro: true }
        case types.GET_UNASSIGN_PRODUCTS:
            return { ...state, Unassign_product: action.payload, loadingUnassign_product: false }
        case types.EMPTY_UNASSIGN_PRODUCTS:
            return { ...state, Unassign_product: action.payload, loadingUnassign_product: true }
        default:
            return state;
    }
};

export default WorkPlan_Reducer;
