import { useState } from "react";

export const VariableAssign = () => {
    const [state, setState] = useState({
        search_value: '',
        medical_rep_name: '',
        Load: false,
        Load1: false,
        city_id: '',
        specialization_id: '',
        target_type_id: '',
        medical_rep_id: '',
        checkedIds: [],
        checkedIds1: [],
        selectAll: false,
        selectAll1: false
    });
    const handleChangeAssign = (variableName, value) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const handleCheckboxChange = (event, arrayName) => {
        const Id = parseInt(event.target.value);
        const isChecked = event.target.checked;
        
        if (isChecked) {
            const newArray = [...state[arrayName], Id];
            setState((prevState) => ({
                ...prevState,
                [arrayName]: newArray,
            }));
        } else {
            const newArray = state[arrayName].filter(id => id !== Id);
            setState((prevState) => ({
                ...prevState,
                [arrayName]: newArray,
            }));
        }
    };
    const handleSelectAll = (event, arrayName,selectedarr,arr) => {
        const isChecked = event.target.checked;
        setState((prevState) => ({
            ...prevState,
            [selectedarr]: isChecked,
        }));
        if (isChecked) {
            const allIds = arr.map(item => item.id);
            setState((prevState) => ({
                ...prevState,
                [arrayName]: allIds,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                [arrayName]: [],
            }));
        }
    };
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setLoad1 = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load1: value,
        }));
    };
    return {
        ...state,
        state,
        setState,
        handleChangeAssign,
        setLoad,
        handleCheckboxChange,
        setLoad1,
        handleSelectAll
    }
}