import React from 'react'
import GoogleMapReact from "google-map-react";
const AnyReactComponent = () => <div><i className='fa fa-map-marker map_marker' ></i></div>;

function Location({ latt, lngg }) {
    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBTe3zscwtZeS24s2izez3M1aj7LTJdUx0" }}
                center={{ lat: latt, lng: lngg }}
                defaultZoom={17}
                fullscreenControl={true}
            >
                <AnyReactComponent
                    lat={latt}
                    lng={lngg}
                />
            </GoogleMapReact>
        </>
    )
}

export default Location