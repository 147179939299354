import * as types from "../ActionType";

const initState = {
    specializations: [],
    loadingspecializations: true,
    classifications: [],
    loadingclassifications: true,
    target_types: [],
    loadingtarget_type: true,
    target_group: [],
    loadingTargetGroup: true
}

const TargetGroupReducer = (state = initState, action) => {
    switch (action.type) {
        case types.ADD_SPECIALIZATION:
            return { ...state, loadingspecializations: true }
        case types.DELETE_SPECIALIZATION:
            return { ...state, loadingspecializations: true }
        case types.GET_ALL_SPECIALIZATIONS:
            return { ...state, specializations: action.payload, loadingspecializations: false }
        case types.ADD_CLASSIFICATION:
            return { ...state, loadingclassifications: true }
        case types.DELETE_CLASSIFICATION:
            return { ...state, loadingclassifications: true }
        case types.GET_ALL_CLASSIFICATIONS:
            return { ...state, classifications: action.payload, loadingclassifications: false }
        case types.ADD_TARGET_TYPE:
            return { ...state, loadingtarget_type: true }
        case types.DELETE_TARGET_TYPE:
            return { ...state, loadingtarget_type: true }
        case types.GET_ALL_TARGET_TYPE:
            return { ...state, target_types: action.payload, loadingtarget_type: false }
        case types.ADD_TARGET_GROUP:
            return { ...state, loadingTargetGroup: true }
        case types.GET_ALL_TARGET_GROUP:
            return { ...state, target_group: action.payload, loadingTargetGroup: false }
        case types.EMPTY_ALL_TARGET_GROUP:
            return { ...state, target_group: action.payload, loadingTargetGroup: true }
        case types.DELETE_TARGET_GROUP:
            return { ...state, loadingTargetGroup: true }
        case types.EDIT_TARGET_GROUP:
            return { ...state, loadingTargetGroup: true }
        case types.FILTER_TARGET_GROUP:
            return { ...state, target_group: action.payload, loadingTargetGroup: true }
        case types.DOWNLOAD_TARGET_GROUP_WITHOUT_FILTER:
            return { ...state }
        case types.DOWNLOAD_TARGET_GROUP_WITH_FILTER:
            return { ...state }
        case types.GET_TARGETT_MEDICAL:
            return { ...state, target_group: action.payload, loadingTargetGroup: true }
        default:
            return state;
    }
};

export default TargetGroupReducer;

