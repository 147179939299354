import React, { useEffect } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { AbstructVar } from "../General/GeneralComponent/AbstructVar";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import { AbstructLang } from "../General/GeneralComponent/AbstructLang";
import { VariableProducts } from "./VariableProducts";
import { FunctionsLoading } from "../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../General/GeneralComponent/SelectWithSearch";
import SelectCreatableWithSearch from "../General/GeneralComponent/SelectCreatableWithSearch";
import { putProduct } from "../../Redux/Actions/ActionProduct";

function EditPro({ arr, open, setOpen }) {
  let { dispatch, langmiamed } = AbstructVar();
  const { measurement, loadingmeasurement, productCat, loadingproductCat } =
    useSelectorCustom();
  const { state, setState, handleChangeProducts, setLoad } = VariableProducts();
  const {
    LoadProductCatCustom,
    PostUnit_of_measureCustom,
    DeleteUnit_of_measureCustom,
    LoadMeasurementsCustom,
  } = FunctionsLoading();
  const { translate } = AbstructLang();
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      name: arr.name,
      color: arr.color,
      product_type: arr.product_type,
      internal_reference: arr.internal_reference,
      description: arr.description,
      product_category_id: arr.category_id,
      product_category_name: arr.category_name,
      measurement_unit_id: arr.measurement_unit_id,
      measurement_unit_name: arr.measurement_unit_name,
    }));
  }, []);
  const FunSubmit = (e) => {
    e.preventDefault();
    dispatch(
      putProduct(
        arr.id,
        state.name,
        state.description,
        state.internal_reference,
        state.product_category_id,
        state.measurement_unit_id,
        state.color,
        "medicine_samples",
        state.image,
        setLoad,
        setOpen
      )
    );
  };
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      center={true}
      classNames={{
        modal: "popAdd",
      }}
      focusTrapped={false}
    >
      <div className="popAddForm" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
        <h3 className={langmiamed == "ar" ? "textRight margin_top_20" : ""}>
          {translate("editProduct")}
        </h3>
        <form>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {translate("name")}
                </span>
                <span className="require">*</span>
              </div>
              <input
                defaultValue={state.name ? state.name :''}
                type="text"
                onChange={(e) => handleChangeProducts(e.target.value, "name")}
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {translate("description")}
                </span>
              </div>
              <input
                defaultValue={state.description ? state.description : ''}
                type="text"
                onChange={(e) =>
                  handleChangeProducts(e.target.value, "description")
                }
              />
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-pencil-alt"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {translate("productCode")}
                </span>
              </div>
              <input
                type="text"
                defaultValue={state.internal_reference ? state.internal_reference :''}
                onChange={(e) =>
                  handleChangeProducts(e.target.value, "internal_reference")
                }
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-hand-pointer"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {translate("product_cat")}
                </span>
                <span className="require">*</span>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <SelectWithSearch
                  load={loadingproductCat}
                  title={translate("product_cat")}
                  array={productCat}
                  setId={(value) =>
                    handleChangeProducts(value, "product_category_id")
                  }
                  funb={LoadProductCatCustom}
                  name={state.product_category_name}
                  previousID={state.product_category_id}
                  typeCss={true}
                />
              </div>
            </div>
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-hand-pointer"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {translate("unit")}
                </span>
                <span className="require">*</span>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <SelectCreatableWithSearch
                  load={loadingmeasurement}
                  options={measurement}
                  title={translate("unit")}
                  post={PostUnit_of_measureCustom}
                  deleteFunction={DeleteUnit_of_measureCustom}
                  funb={LoadMeasurementsCustom}
                  setIdd={(value) =>
                    handleChangeProducts(value, "measurement_unit_id")
                  }
                  name={state.measurement_unit_name}
                  previousID={state.measurement_unit_id}
                  typeCss={true}
                />
              </div>
            </div>
            {/* <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-hand-pointer' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('product_type')}</span>
                            </div>
                            <select value={state.product_type} onChange={(e) => handleChangeProducts(e.target.value, 'product_type')} >
                                <option value='marketing_tools' >Marketing Tools</option>
                                <option value='medicine_samples' >Free Samples</option>
                            </select>
                        </div> */}
          </div>
          <div className="input_row11">
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-palette"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {translate("color")}
                </span>
              </div>
              <input
                defaultValue={state.color}
                type="color"
                onChange={(e) => handleChangeProducts(e.target.value, "color")}
              />
            </div>
            <div className="one_input_row">
              <div className="Gen_icon">
                <i className="las la-image"></i>
                <span className={langmiamed == "ar" ? "textAlignRight" : ""}>
                  {translate("image")}
                </span>
              </div>
              <input
                type="file"
                onChange={(e) =>
                  handleChangeProducts(e.target.files[0], "image")
                }
              />
            </div>
          </div>
        </form>
        <div className="div_btn">
          <button
            disabled={
              state.product_category_id === null &&
              state.measurement_unit_id === null
            }
            onClick={FunSubmit}
          >
            {state.Load ? "Loading ... " : <>{translate("edit")}</>}
          </button>
        </div>
      </div>
    </RModal>
  );
}

export default EditPro;
