import { useState } from "react";

export const VariableReports = () => {
    const [state, setState] = useState({
        report_type_id: "",
        arr1: []
    });
    const handleEditReport= (event, arr) => {
        event.preventDefault();
        const formValues = {
            id: arr.id,
            name: arr.name,
            description: arr.description,
            service: arr.service
        };
        setState((prevState) => ({
            ...prevState,
            report_type_id: arr.id,
            arr1: formValues
        }));
    }
    const setreport_type_id = (value) => {
        setState((prevState) => ({
            ...prevState,
            report_type_id: value,
        }));
    };
    return{
        ...state,
        state,
        setState,
        handleEditReport,
        setreport_type_id
    }
}