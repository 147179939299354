import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Page_Header from "../../../General/Page_Header";
import { useParams } from "react-router-dom";
import {
  emptyOneTask,
  emptyTaskVisites,
  loadOneTask,
  loadTaskVisites,
} from "../../../../Redux/Actions/ActionTask";
import "./DetailsTask.css";
import det from "../../../../images/information1.png";
import Table2 from "../../../Table2/Table2";
import External_In_Map from "../../../External_Visites/External_In_Map";
import DetailsProducts from "./DetailsProducts";
import InnerLoader from "../../../General/InnerLoader";
import calendar from "../../../../images/icons/date_icon.svg";
import NoInternet from "../../../General/NotPermission/NoInternet";
import { CheckFont } from "../../../General/GeneralComponent/CheckLang";
import DetailsMarkiting from "./DetailsMarkiting";

function DetailsTask() {
  const [openInternet, setopenInternet] = useState(false);
  let dispatch = useDispatch();
  let langmiamed = localStorage.getItem("langmiamed");
  const oneTask = useSelector((state) => state.work_task.oneTask);
  const loadingOneTask = useSelector((state) => state.work_task.loadingOneTask);
  const taskVisites = useSelector((state) => state.work_task.taskVisites);
  const loadingTaskVisites = useSelector(
    (state) => state.work_task.loadingTaskVisites
  );
  const [openPro, setOpenPro] = useState(false);
  const [openMar, setOpenMar] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [lat, setlat] = useState(false);
  const [lng, setlng] = useState(false);
  const [t, i18n] = useTranslation();
  let { id_task } = useParams();
  const [productArr, setproductArr] = useState([]);
  const [markitingArr, setmarkitingArr] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem("langmiamed"));
    dispatch(loadOneTask(id_task));
    dispatch(loadTaskVisites(id_task, setopenInternet));
    return () => {
      dispatch(emptyTaskVisites());
      dispatch(emptyOneTask());
    };
  }, []);
  const storeMap = (lat, lng) => {
    setlat(lat);
    setlng(lng);
    setOpenMap(true);
  };
  const storeProduct = (product) => {
    setOpenPro(true);
    setproductArr(product);
  };
  const storeMarkiting = (markiting) => {
    setOpenMar(true);
    setmarkitingArr(markiting);
  };
  const columns1 = [
    {
      name: <span className="color_spann">{t("location")}</span>,
      sortable: true,
      cell: (row) => (
        <span>
          {row.is_site_match ? (
            <i className="las la-check checkmatch"></i>
          ) : (
            <i className="las la-times falsematch"></i>
          )}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{t("description")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.details) }}>
          {row["details"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{t("reportType")}</span>,
      sortable: true,
      cell: (row) => (
        <span style={{ fontFamily: CheckFont(row.report_type_name) }}>
          {row["report_type_name"]}
        </span>
      ),
    },
    {
      name: <span className="color_spann">{t("map")}</span>,
      cell: (row) => (
        <i
          className="las la-map-marked icon_edit_table"
          onClick={() => storeMap(row.lat, row.lng)}
        ></i>
      ),
    },
    {
      name: <span className="color_spann">{t("products")}</span>,
      sortable: true,
      cell: (row) => (
        <i
          className="las la-hand-pointer icon_edit_table"
          onClick={() => storeProduct(row.delivered_Products)}
        ></i>
      ),
    },
    {
      name: <span className="color_spann">{t("Marketing Tools")}</span>,
      sortable: true,
      cell: (row) => (
        <i
          className="las la-hand-pointer icon_edit_table"
          onClick={() => storeMarkiting(row.marketingTools)}
        ></i>
      ),
    },
  ];
  return (
    <>
      <div
        className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}
      >
        <main>
          <Page_Header
            langmiamed={langmiamed}
            header={t("taskDetails")}
            path={`${t("home")} / ${t("taskDetails")} `}
          />
        </main>
        <div className="page-content">
          {loadingOneTask == false && loadingTaskVisites == false ? (
            <>
              <div
                className="div_task_details"
                dir={langmiamed == "ar" ? "rtl" : "ltr"}
              >
                <img src={det} />
                <div className="div_task_details_info">
                  {oneTask.status == "is_done" ? (
                    <div className="row_div_task_details">
                      <i
                        className={`fas fa-check-circle ${
                          langmiamed == "ar"
                            ? "margin_left_20"
                            : "margin_right_20"
                        }`}
                        style={{ color: "#56ADE1", marginTop: "-10px" }}
                      ></i>
                      <h5>
                        {t("This_task")} {t("is_done")} (
                        {t(oneTask.priority_level)})
                      </h5>
                    </div>
                  ) : (
                    <div className="row_div_task_details">
                      <i
                        className={`fas fa-times-circle ${
                          langmiamed == "ar"
                            ? "margin_left_20"
                            : "margin_right_20"
                        }`}
                        style={{ color: "red" }}
                      ></i>
                      <h5>
                        {t("This_task")} {t("is_not_done")} (
                        {t(oneTask.priority_level)})
                      </h5>
                    </div>
                  )}
                  <div className="row_div_task_details">
                    <img
                      src={calendar}
                      className={
                        langmiamed == "ar"
                          ? "margin_left_20"
                          : "margin_right_20"
                      }
                    />
                    <span style={{ fontFamily: CheckFont(oneTask.date) }}>
                      {oneTask.date} / {oneTask.time}
                    </span>
                  </div>
                  <div className="row_div_task_details">
                    <i
                      class={`las la-comment ${
                        langmiamed == "ar"
                          ? "margin_left_20"
                          : "margin_right_20"
                      }`}
                      style={{ color: "#49B69C" }}
                    ></i>
                    <span style={{ fontFamily: CheckFont(oneTask.note) }}>
                      {oneTask.note}{" "}
                    </span>
                  </div>
                  <div className="row_div_task_details">
                    <i
                      className={`fas fa-user-circle ${
                        langmiamed == "ar"
                          ? "margin_left_20"
                          : "margin_right_20"
                      }`}
                      style={{ color: "#EA4D68" }}
                    ></i>
                    <span
                      style={{
                        fontFamily: CheckFont(oneTask.target_group_name),
                      }}
                    >
                      {oneTask.target_group_name}
                    </span>
                  </div>
                </div>
              </div>
              <br></br>
              <div
                className="newDetails"
                dir={langmiamed == "ar" ? "rtl" : "ltr"}
              >
                <Table2 col={columns1} roo={taskVisites} />
              </div>
            </>
          ) : (
            <div className="div_loadd">
              <InnerLoader />
            </div>
          )}
        </div>
      </div>
      {openMap && (
        <External_In_Map
          open={openMap}
          setOpen={setOpenMap}
          lat={lat}
          lng={lng}
        />
      )}
      {openPro && (
        <DetailsProducts
          productArr={productArr}
          langmiamed={langmiamed}
          t={t}
          open={openPro}
          setOpen={setOpenPro}
        />
      )}
      {openMar && (
        <DetailsMarkiting
        markitingArr={markitingArr}
          langmiamed={langmiamed}
          t={t}
          open={openMar}
          setOpen={setOpenMar}
        />
      )}
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
}

export default DetailsTask;
