import * as types from "../ActionType";

const initState = {
  reports: [],
  loadingReports: true,
  reportsPro: [],
  loadingReportsPro: true,
  generalReport: [],
  loadinggeneralReport: true,
  generalExReport: [],
  loadinggeneralExReport: true,
  reportTargetGroup: [],
  loagingreportTargetGroup: true,
  reportOrdersReqs: [],
  loagingreportOrdersReqs: true,
  objections: [],
  loagingobjections: true,
  orders: [],
  loagingorders: true,
  targetGroupsVisites: [],
  loagingTargetGroupsVisites: true,
  targetGroupsVisitesWithDate: [],
  loagingTargetGroupsVisitesWithDate: true,
  allVisits: [],
  loagingAllVisits: true,
  freeSamples: [],
  loagingFreeSamples: true,
  freeSamplesTarget: [],
  loagingFreeSamplesTarget: true,
  marketingTools: [],
  loagingMarketingTools: true,
  RepSales: [],
  loagingRepSales: true,
  monthlyProductsSales: [],
  loagingMonthlyProductsSales: true,
  medicalRepTarget: [],
  loagingMedicalRepTarget: true,
  hospital: [],
  loaginghospital: true,
};

const ReportReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_HOSPITAL_REPORTS:
      return { ...state, hospital: action.payload, loaginghospital: false };
    case types.EMPTY_HOSPITAL_REPORTS:
      return { ...state, hospital: action.payload, loaginghospital: true };
    case types.GET_ALL_REPORTS:
      return { ...state, reports: action.payload, loadingReports: false };
    case types.EMPTY_ALL_REPORTS:
      return { ...state, reports: action.payload, loadingReports: true };
    case types.GET_ALL_REPORTS_PRODUCTS:
      return { ...state, reportsPro: action.payload, loadingReportsPro: false };
    case types.EMPTY_ALL_REPORTS_PRODUCTS:
      return { ...state, reportsPro: action.payload, loadingReportsPro: true };
    case types.GET_GENERAL_REPORTS:
      return {
        ...state,
        generalReport: action.payload,
        loadinggeneralReport: false,
      };
    case types.EXPORT_GENERAL_REPORTS:
    case types.DOWNLOAD_VISITES_EXCEL:
    case types.DOWNLOAD_VISITES_PRODUCTS:
    case types.EXPORT_GENERALEX_REPORTS:
    case types.EXPORT_ORDERS:
    case types.EXPORT_All_Visits:
    case types.EXPORT_All_Visits_WITH_DATE:
    case types.EXPORT_TARGET_GROUP_VISITS:
    case types.EXPORT_FREE_SAMPLES:
    case types.EXPORT_FREE_SAMPLES_TARGET:
    case types.EXPORT_GENERAL_TARGET_GROUP:
    case types.EXPORT_ORDERS_REQS:
    case types.EXPORT_OBJECTIONS_REPORT:
    case types.EXPORT_REP_SALES_REPORT:
    case types.EXPORT_FREE_MARKETING_TOOLS:
    case types.EXPORT_MONTHLY_PRODUCTS:
    case types.EXPORT_MEDICAL_REP_TARGET:
      return { ...state };
    case types.GET_GENERALEX_REPORTS:
      return {
        ...state,
        generalExReport: action.payload,
        loadinggeneralExReport: false,
      };
    case types.GET_GENERAL_TARGET_GROUP:
      return {
        ...state,
        reportTargetGroup: action.payload,
        loagingreportTargetGroup: false,
      };
    case types.GET_ORDERS_REQS:
      return {
        ...state,
        reportOrdersReqs: action.payload,
        loagingreportOrdersReqs: false,
      };
    case types.GET_OBJECTIONS_REPORT:
      return { ...state, objections: action.payload, loagingobjections: false };
    case types.GET_ORDERS:
      return { ...state, orders: action.payload, loagingorders: false };
    case types.GET_TARGET_GROUP_VISITES:
      return {
        ...state,
        targetGroupsVisites: action.payload,
        loagingTargetGroupsVisites: false,
      };
    case types.GET_TARGET_GROUP_VISITES_WITH_DATE:
      return {
        ...state,
        targetGroupsVisitesWithDate: action.payload,
        loagingTargetGroupsVisitesWithDate: false,
      };
    case types.GET_ALL_VISITS:
      return { ...state, allVisits: action.payload, loagingAllVisits: false };
    case types.GET_FREE_SAMPLES:
      return {
        ...state,
        freeSamples: action.payload,
        loagingFreeSamples: false,
      };
    case types.GET_FREE_SAMPLES_Target:
      return {
        ...state,
        freeSamplesTarget: action.payload,
        loagingFreeSamplesTarget: false,
      };
    case types.GET_MARKETING_TOOLS:
      return {
        ...state,
        marketingTools: action.payload,
        loagingMarketingTools: false,
      };
    case types.GET_PRODUCTS_MONTHLY_SALES:
      return {
        ...state,
        monthlyProductsSales: action.payload,
        loagingMonthlyProductsSales: false,
      };
    case types.GET_MEDICAL_REP_TARGET:
      return {
        ...state,
        medicalRepTarget: action.payload,
        loagingMedicalRepTarget: false,
      };
    case types.GET_REP_SALES_REPORT:
      return { ...state, RepSales: action.payload, loagingRepSales: false };
    default:
      return state;
  }
};

export default ReportReducer;
