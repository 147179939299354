import * as types from "../ActionType";

const initState = {
    roles: [],
    loadingRoles: true,
    permission: [],
    loadingpermission: true
}

const RolesReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_ROLES:
            return { ...state, roles: action.payload, loadingRoles: false }
        case types.GET_PERMISSIONS:
            return { ...state, permission: action.payload, loadingpermission: false }
        case types.EMPTY_PERMISSIONS:
            return { ...state, permission: action.payload, loadingpermission: true }
        case types.EDIT_PERMISSIONS:
            return { ...state, loadingpermission: true }
        default:
            return state;
    }
};

export default RolesReducer;
