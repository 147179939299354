import React, { useEffect } from 'react'
import useClickOutside from '../../../useClickOutside';
import { useDispatch } from "react-redux";
import { emptytargetGroup } from '../../../Redux/Actions/ActionTargetGroup';
import { loadFilterTask, loadTask } from '../../../Redux/Actions/ActionTask';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';
import { FunctionsLoading } from '../../Main/Statistics/FunctionsLoading';
import SelectWithSearch from '../../General/GeneralComponent/SelectWithSearch';
import { VariableTask } from './VariableTask';

function FilterWorkTask({ setopenInternet, navigate, medical_rep_id, id, langmiamed, t, setfilterTask }) {
    let dispatch = useDispatch();
    const { state, handleChangeTask, setLoad } = VariableTask()
    const { TGbymedicalRep, loadingTG } = useSelectorCustom()
    const { LoadTargetByMedicalRepCustom } = FunctionsLoading()
    let domNode = useClickOutside(() => {
        setfilterTask(false);
    });
    useEffect(() => {
        return (() => {
            dispatch(emptytargetGroup())
            dispatch(loadTask(id, navigate, setopenInternet))
        })
    }, [])
    const filter = event => {
        event.preventDefault()
        dispatch(loadFilterTask(id, state.priority_level, state.target_group_id, state.status, setLoad))
    };
    return (
        <div ref={domNode} className={`task_filter filter_task ${langmiamed == 'ar' ? "padding_right_10 margin_left_5p" : "margin_left_15p"}`} dir={langmiamed == "ar" ? "rtl" : "ltr"}>
            <div className='oneFilter' >
                <SelectWithSearch
                    load={loadingTG}
                    array={TGbymedicalRep}
                    setId={(value) => handleChangeTask(value, 'target_group_id')}
                    func={LoadTargetByMedicalRepCustom}
                    loadID={medical_rep_id}
                    typeCss={true}
                />
            </div>
            <div className='oneFilter' >
                <select onChange={(e) => handleChangeTask(e.target.value, 'priority_level')}>
                    <option disabled="disabled" selected="selected">{t('priority')}</option>
                    <option value='high' >{t('high')}</option>
                    <option value='medium' >{t('Medium')}</option>
                    <option value='low' >{t('low')}</option>
                </select>
            </div>
            <div className='oneFilter' >
                <select onChange={(e) => handleChangeTask(e.target.value, 'status')}>
                    <option disabled="disabled" selected="selected">{t('status')}</option>
                    <option value='is_done' >{t('done')}</option>
                    <option value='not_done'>{t('notdone')}</option>
                </select>
            </div>
            <button onClick={filter} className='targetfilter_button'  >
                {
                    state.Load ? "Loading ..." : `${t('filter')}`
                }
            </button>
        </div>
    );
}

export default FilterWorkTask