import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import { postReportType } from '../../../Redux/Actions/ActionReportType'

function Reports({ type,t, langmiamed,nameTitle }) {
    let dispatch = useDispatch();
    const [name, setName] = useState('')
    const [description, setdescription] = useState('')
    const [Load, setLoad] = useState(false)
    const submit = (event) => {
        event.preventDefault();
        dispatch(postReportType(name, description, type, setLoad))
    }
    return (
        <div className={`set1 nonOver ${langmiamed == 'ar' ? 'margin_left_50' : "margin_right_50"}`} >
            <h5 className={langmiamed == 'ar' ? "textRight" : ""}>{t('addReports')} / {nameTitle}</h5>
            <input type='text' placeholder={t('name')} onChange={(e) => setName(e.target.value)} />
            <textarea type='text' placeholder={t('description')} onChange={(e) => setdescription(e.target.value)} />
            <button onClick={submit} disabled >
                {
                    Load ? "Loading ..." : `${t('save')}`
                }
            </button>
        </div>
    )
}

export default Reports