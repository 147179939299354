import { useState } from "react"
import { CheckFont } from "../General/GeneralComponent/CheckLang";

export const VariableUser = () => {
    const [state, setState] = useState({
        open: false,
        openEdit: false,
        arr: [],
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        address: "",
        phone: "",
        role: "",
        otherrole: "",
        gender: "",
        city_id: "",
        city_name: "",
        Load: false,
        LoadSuper: false,
        show: '',
        openRole: false,
        selectedOptions: [],
        selectedOptionsName: []
    })
    const setLoad = (value) => {
        setState((prevState) => ({
            ...prevState,
            Load: value,
        }));
    };
    const setOpen = (value) => {
        setState((prevState) => ({
            ...prevState,
            open: value,
        }));
    };
    const setLoadSuper = (value) => {
        setState((prevState) => ({
            ...prevState,
            LoadSuper: value,
        }));
    };
    const setotherrole = (value) => {
        setState((prevState) => ({
            ...prevState,
            otherrole: value,
        }));
    };
    const setrole = (value) => {
        setState((prevState) => ({
            ...prevState,
            role: value,
        }));
    };
    const setShow = (value) => {
        setState((prevState) => ({
            ...prevState,
            show: value,
        }));
    };
    const setOpenEdit = (value) => {
        setState((prevState) => ({
            ...prevState,
            openEdit: value,
        }));
    };
    const handleEditUser = (arr1) => {
        const formValues = {
            id: arr1.id,
            first_name: arr1.first_name,
            last_name: arr1.last_name,
            address: arr1.address,
            phone: arr1.phone,
            role: arr1.role,
            gender: arr1.gender,
            email: arr1.email,
            city_id: arr1.city_id,
            city_name: arr1.city_name,
            relatedUsers: arr1.relatedUsers,
        };
        setState((prevState) => ({
            ...prevState,
            arr: formValues,
            openEdit: true
        }));
    }
    const openAddNew = () => {
        setState((prevState) => ({
            ...prevState,
            open: true,
        }));
    }
    const handleChangeUser = (value, variableName) => {
        setState((prevState) => ({
            ...prevState,
            [variableName]: value,
        }));
    };
    const viewSenior_super = (ser) => {
        setState((prevState) => ({
            ...prevState,
            role: ser,
        }));
        if (ser == "senior_medical_rep") {
            setState((prevState) => ({
                ...prevState,
                otherrole: "senior_medical_rep",
            }));
        }
        if (ser == "supervisor") {
            setState((prevState) => ({
                ...prevState,
                otherrole: "supervisor",
            }));
        }
        if (ser == "senior_supervisor") {
            setState((prevState) => ({
                ...prevState,
                otherrole: "senior_supervisor",
            }));
        }
        if (ser == "markting_manager") {
            setState((prevState) => ({
                ...prevState,
                otherrole: "",
            }));
        }
        if (ser == "head_of_section") {
            setState((prevState) => ({
                ...prevState,
                otherrole: "",
            }));
        }
        if (ser == "admin") {
            setState((prevState) => ({
                ...prevState,
                otherrole: "",
            }));
        }
    }
    return {
        ...state,
        state,
        setState,
        handleEditUser,
        setOpen,
        setOpenEdit,
        openAddNew,
        handleChangeUser,
        setotherrole,
        setrole,
        setShow,
        setLoadSuper,
        setLoad,
        viewSenior_super
    }
}

export const ColumnUser =(translate,handleEdit,warning)=>{
    const columnsUser = [
        {
            name: <span className="color_spann">{translate('name')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.first_name)}}>{row['first_name']} {row['last_name']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('email')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.email)}}>{row['email']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('address')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.address)}}>{row['address']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('city')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.city_name)}}>{row['city_name']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('phone')}</span>, sortable: true,
            cell: (row) => (
                <span  style={{fontFamily: CheckFont(row.phone)}}>{row['phone']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('role')}</span>, sortable: true,
            cell: (row) => (
                <span style={{fontFamily: CheckFont(row.role)}}>{row['role']}</span>
            )
        }
        ,
        {
            name: <span className="color_spann">{translate('responsible')}</span>, sortable: true,
            cell: (row) => (
                <div className="permissions-wrapper">
                    {row.relatedUsers.map((per) => {
                        return (
                            <div className='empPer' >
                                <span style={{fontFamily: CheckFont(per.first_name)}}>{per.first_name} {per.last_name}</span>
                            </div>
                        )
                    })}
                </div>
            )
        },
        {
            name: <span className="color_spann">{translate('gender')}</span>, sortable: true,
            cell: (row) => (
                <span  style={{fontFamily: CheckFont(row.gender)}}>{row['gender']}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('actions')}</span>,
            cell: (row) => (
                <>
                    <i class="las la-pen icon_edit_table" onClick={() => handleEdit(row)} ></i>
                    <i class="las la-times-circle icon_del_table"  onClick={() => warning(row)} ></i>
                </>
            )
        }
    ]
    return {
        columnsUser
    }
}