import React, { useState, useEffect } from 'react'
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Switch from "react-switch";
import { putWorkPlan } from '../../Redux/Actions/ActionWorkPlans';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { FunctionsLoading } from '../Main/Statistics/FunctionsLoading';
import { VariableWorkPlans } from './VariableWorkPlans';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import SelectWithSearch from '../General/GeneralComponent/SelectWithSearch';

function EditPlan({ arr, open1, setOpen }) {
    let { dispatch, langmiamed } = AbstructVar()
    const { medicalRep, loadingmedicalRep } = useSelectorCustom()
    const { LoadMedicalRepCustom } = FunctionsLoading()
    const { state, setState, handleChangeWorkPlans, setLoad } = VariableWorkPlans()
    const { translate } = AbstructLang()
    const [is_in_service, setis_in_service] = useState(arr.is_in_service === true ? 1 : 0)
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            startDate: arr.start_date,
            endDate: arr.end_date,
            note: arr.note,
            days_duration: arr.days_duration,
            medical_rep_id: arr.medical_rep_id,
            medical_rep_name: arr.medical_rep_name,
        }));
    }, [])
    const activee = () => {
        setis_in_service(prevValue => (prevValue === 1 ? 0 : 1));
    };
    const FunSubmit = (e) => {
        e.preventDefault()
        dispatch(putWorkPlan(arr.id, state.note, state.startDate, state.endDate, is_in_service, state.days_duration, state.medical_rep_id, setLoad))
    }
    return (
        <RModal
            open={open1}
            onClose={() => {
                setOpen(false);
            }}
            center={true}
            classNames={{
                modal: "popAdd",
            }}
            focusTrapped={false}
        >
            <div className='popAddForm' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                <h3 className={langmiamed == 'ar' ? "textRight margin_top_20" : ""}>{translate('editWorkPlan')}</h3>
                <form>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-calendar' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('startDate')}</span>
                            </div>
                            <input defaultValue={state.startDate} type="date" onChange={(e) => handleChangeWorkPlans(e.target.value, 'startDate')} />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-calendar' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('endDate')}</span>
                            </div>
                            <input defaultValue={state.endDate} type="date" onChange={(e) => handleChangeWorkPlans(e.target.value, 'endDate')} />
                        </div>
                    </div>
                    <div className='input_row11' >
                        <div className='one_input_row'>
                            <div className='Gen_icon'>
                                <i className='las la-sort-numeric-up-alt' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('dayDuration')}</span>
                            </div>
                            <input type='number' defaultValue={state.days_duration} onChange={(e) => handleChangeWorkPlans(e.target.value, 'days_duration')} />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-note' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('note')}</span>
                            </div>
                            <textarea value={state.note} onChange={(e) => handleChangeWorkPlans(e.target.value, 'note')} />
                        </div>
                    </div>
                    <div className='input_row11' >
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-user' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('medicalRep')}</span>
                            </div>
                            <SelectWithSearch
                                load={loadingmedicalRep}
                                array={medicalRep}
                                setId={(value) => handleChangeWorkPlans(value, 'medical_rep_id')}
                                funb={LoadMedicalRepCustom}
                                typeCss={true}
                                name={state.medical_rep_name}
                                previousID={state.medical_rep_id}
                            />
                        </div>
                        <div className='one_input_row' >
                            <div className='Gen_icon'>
                                <i className='las la-hand-pointer' ></i>
                                <span className={langmiamed == 'ar' ? "textAlignRight" : ""} >{translate('is_in_service')}</span>
                            </div>
                            <Switch onColor='#4285C5' checkedIcon={false} uncheckedIcon={false} height={20} width={40} handleDiameter={15} checked={is_in_service} onChange={activee} />
                        </div>
                    </div>
                </form>
                <div className='div_btn' ><button onClick={FunSubmit} >
                    {
                        state.Load ? "Loading ... " :
                            <>{translate('edit')}</>
                    }
                </button></div>
            </div>
        </RModal>
    )
}

export default EditPlan