import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyWorkPlanMar,
  loadWorkPlanMar,
  unassignWorkPlanMarr,
} from "../../../Redux/Actions/ActionWorkPlanMarketing";
import NoInternet from "../../General/NotPermission/NoInternet";
import NoData from "../../General/NoData/NoData";
import InnerLoader from "../../General/InnerLoader";
import SinglePagenation from "../../General/Pagination/SinglePagenation";
import "./MarketingAssign.css";
import MultiSelectStock from "./MultiSelectStock";

export const MarketingAssign = ({ langmiamed, t }) => {
  const [openInternet, setopenInternet] = useState(false);
  const [open, setopen] = useState(false);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let { id } = useParams();
  const [Load, setLoad] = useState(false);
  const [selectedMarketings, setSelectedMarketings] = useState([]);
  const WorkPlanMar = useSelector(
    (state) => state?.Marketing_WorkPlan.WorkPlanMar
  );
  const loadingWorkPlanMar = useSelector(
    (state) => state?.Marketing_WorkPlan.loadingWorkPlanMar
  );
  useEffect(() => {
    dispatch(loadWorkPlanMar(id, navigate));
    return () => {
      dispatch(emptyWorkPlanMar());
    };
  }, []);
  const [pageNumber, setPageNumber] = useState(0);
  const productPerPage = 12;
  const pagesVisited = pageNumber * productPerPage;
  const displayproduct = WorkPlanMar.slice(
    pagesVisited,
    pagesVisited + productPerPage
  );

  const pageCount = Math.ceil(WorkPlanMar.length / productPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleCheckboxChange = (e, productId) => {
    const isChecked = e.target.checked;
    setSelectedMarketings((prevFeatures) => {
      if (isChecked) {
        return [...prevFeatures, { id: productId }];
      } else {
        return prevFeatures.filter((proId) => proId.id !== productId);
      }
    });
  };
  const print = (event) => {
    event.preventDefault();
    dispatch(
      unassignWorkPlanMarr(
        id,
        selectedMarketings,
        navigate,
        setopenInternet,
        setLoad
      )
    );
    setSelectedMarketings([]);
  };
  const openAddNew = () => {
    setopen(true);
  };
  return (
    <>
      {open && (
        <MultiSelectStock
          work_plan_id={id}
          t={t}
          langmiamed={langmiamed}
          open={open}
          setOpen={setopen}
        />
      )}
      <div className="btn_product_assign">
        <div onClick={print} className="btn_assign1">
          {Load ? t("Loading ...") : `${t("unassign")}`}
        </div>
        <div className="btn_assign1" onClick={openAddNew}>
          {t("assign")}
        </div>
      </div>
      <div className="allproducts" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
        {loadingWorkPlanMar == false ? (
          displayproduct
            .slice()
            .reverse()
            .map((pp) => {
              return (
                <div
                  key={pp.id}
                  className="oneproduct"
                  dir={langmiamed == "ar" ? "rtl" : "ltr"}
                >
                  {pp.media_urls.length != 0 ? (
                    <>
                      <div
                        className="img_product"
                        style={{
                          backgroundImage:
                            " linear-gradient(rgba(53, 37, 37, 0.0), rgba(0, 0, 0, 0.0)), url(" +
                            pp.media_urls[0].url +
                            ")",
                        }}
                      >
                        <input
                          className="checkbox_pro"
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, pp.id)}
                        />
                      </div>
                      <div className="product_info">
                        <div className="withQuantity">
                          <h5 style={{ color: pp.color || "black" }}>
                            {pp.name}
                          </h5>
                          <span
                            style={{ backgroundColor: pp.color || "black" }}
                            className={`quantityPro ${
                              langmiamed == "ar"
                                ? "margin_right_10"
                                : "margin_left_10"
                            }`}
                          >
                            {pp.pivot.quantity}
                          </span>
                        </div>
                        <span>{pp.description}</span>
                        <span>{pp.internal_reference}</span>
                        <span>
                          {pp.category_name} / {pp.product_type}
                        </span>
                        <span>
                          Total Of quantity /
                          <span
                            style={{ backgroundColor: pp.color || "black" }}
                            className={`quantityPro ${
                              langmiamed == "ar"
                                ? "margin_right_10"
                                : "margin_left_10"
                            }`}
                          >
                            {pp.pivot.old_quantity}
                          </span>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="img_product"
                        style={{ backgroundColor: `${pp.color}` }}
                      >
                        <input
                          className="checkbox_pro"
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, pp.id)}
                        />
                      </div>
                      <div className="product_info">
                        <h5 style={{ color: pp.color || "black" }}>
                          {pp.name}
                        </h5>
                        <span
                          style={{ backgroundColor: pp.color || "black" }}
                          className={`quantityPro ${
                            langmiamed == "ar"
                              ? "margin_right_10"
                              : "margin_left_10"
                          }`}
                        >
                          {pp.pivot.quantity}
                        </span>
                        <span>{pp.description}</span>
                        <span>{pp.color}</span>
                        <span>{pp.internal_reference}</span>
                        <span>
                          {pp.category_name} / {pp.product_type}
                        </span>
                        <span className="mt-2">
                          Total Of quantity / {pp.pivot.old_quantity}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              );
            })
        ) : (
          <div className="div_loadd">
            <InnerLoader />
          </div>
        )}
        {loadingWorkPlanMar == false && WorkPlanMar.length == 0 && <NoData />}
        <SinglePagenation pageCount={pageCount} changePage={changePage} />
      </div>
      {openInternet && (
        <NoInternet open={openInternet} setOpen={setopenInternet} />
      )}
    </>
  );
};
