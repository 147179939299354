import * as types from "../ActionType";

const initState = {
    Vacation: [],
    loadingVacation: true,
}

const VacationReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_VACATION:
            return { ...state, Vacation: action.payload, loadingVacation: false }
        case types.EDIT_VACATION:
            return { ...state, loadingVacation: true }
        case types.EDIT_VACATION_INFORMATION:
            return { ...state, loadingVacation: true }
        default:
            return state;
    }
};

export default VacationReducer;
