import React, { useEffect } from 'react'
import './Settings.css'
import Page_Header from '../General/Page_Header';
import { loadClassifications, loadSpecializations } from '../../Redux/Actions/ActionTargetGroup';
import LinkSpecWithClass from './ClassificationsAndSpecializations/LinkSpecWithClass';
import Reports from './Reports/Reports';
import ShowReports from './Reports/ShowReports';
import { AbstructVar } from '../General/GeneralComponent/AbstructVar';
import { useSelectorCustom } from '../General/GeneralComponent/useSelectorCustom';
import { AbstructLang } from '../General/GeneralComponent/AbstructLang';
import ClassificationsCard from './ClassificationsAndSpecializations/ClassificationsCard';
import SpecializationsCard from './ClassificationsAndSpecializations/SpecializationsCard';

function Settings() {
    let {dispatch ,langmiamed } = AbstructVar()
    const {classifications,loadingclassifications,specializations,loadingspecializations} = useSelectorCustom()
    const {translate} = AbstructLang()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadClassifications())
        dispatch(loadSpecializations())
    }, []);
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={translate('settings')} path={`${translate('home')} / ${translate('settings')} `} />
                </main>
                <div className="page-content" >
                    <div className='allsetting' dir={langmiamed == "ar" ? "rtl" : "ltr"}  >
                        <div className={`div_tit_set ${langmiamed === 'ar' ? "textRight" : ""}`} ><h5 className='tit_set' >{translate('classifications')} / {translate('specializations')}</h5></div>
                        <div className='onesetting' >
                            <LinkSpecWithClass t={translate} langmiamed={langmiamed} />
                            <ClassificationsCard langmiamed={langmiamed} translate={translate} classifications={classifications} loadingclassifications={loadingclassifications} />
                            <SpecializationsCard langmiamed={langmiamed} translate={translate} specializations={specializations} loadingspecializations={loadingspecializations} />
                        </div>
                        <div className='line' ></div>
                        <div className={`div_tit_set ${langmiamed === 'ar' ? "textRight" : ""}`} ><h5 className='tit_set'>{translate('reportType')}</h5></div>
                        <div className='onesetting' >
                            <Reports t={translate} langmiamed={langmiamed} type="WorkPlan" nameTitle={translate('work_plans')} />
                            <ShowReports t={translate} langmiamed={langmiamed} type="WorkPlan" />
                        </div>
                        <div className='line' ></div>
                        <div className='onesetting' >
                            <Reports t={translate} langmiamed={langmiamed} type="ScientificOffice" nameTitle={translate('external_visites')} />
                            <ShowReports t={translate} langmiamed={langmiamed} type="ScientificOffice" />
                        </div>
                        <div className='line' ></div>
                        <div className='onesetting' >
                            <Reports t={translate} langmiamed={langmiamed} type="WorkPlanOrder" nameTitle={translate('WorkPlanOrder')} />
                            <ShowReports t={translate} langmiamed={langmiamed} type="WorkPlanOrder" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings