import React, { useEffect, useState } from "react";
import './Warning.css'
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import war from '../../../images/warning.png'
import { useTranslation } from "react-i18next";

function Warning({ loadDel,arr, open, setOpen, handleDelete }) {
    let langmiamed = localStorage.getItem('langmiamed');
    const [t, i18n] = useTranslation();
    const [varLoad, setVarLoad] = useState(false)
    let testt = false ;

    const mainDelete = async () => {
        try {
            await handleDelete(arr.id ,arr.product_type);
        } catch (error) {
        } finally {
        }
    }

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('langmiamed'));
    }, []);

    return (
        <RModal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            center
            classNames={{
                modal: "popupWarning",
            }}
            focusTrapped={false}
        >
            <div className="warning">
                <img src={war} alt="Warning" />
                <span>{t('sure')}</span>
                <div className="btn_warning">
                    <button onClick={mainDelete} className="yes" >
                        {
                            loadDel ? " Loading ..." : `${t('yes')}`
                        }
                    </button>
                    <button onClick={() => setOpen(false)} className="no" >{t('cancel')}</button>
                </div>
            </div>
        </RModal>
    )
}

export default Warning;