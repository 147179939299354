import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
  notifyErrorNetWork,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const sendTargetOrdersAcion = () => {
  return {
    type: types.SEND_TARGET_ORDERS,
  };
};

export const sendTargetOrders = (
  formData,
  setOpen,
  setLoad,
  // setTargetOrders,

  // setSelectedOption,
  // setTargetValues

) => {
  return function (dispatch) {
    setLoad(true);

    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(`${base_url}api/external-orders/set-target-settings`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          notifysuccess(res?.data?.message);
          // setSelectedProduct(null);
          // setTargetOrders(null);

          setOpen(false); //close dialog
          setLoad(false);
          // setSelectedOption(null);
          // setTargetValues([]);
          // window.location.reload()


        }
      })
      .catch((err) => {
        notifyError(err.message);
        setLoad(false);
      });
  };
};
