import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Loader from "./Components/General/Loader";
import AllLayout from "./Components/General/AllLayout";
import useRout from "./Components/General/Routes";
import { useDispatch } from "react-redux";
import { loadNotification } from "./Redux/Actions/ActionAuth";
import { useSelectorCustom } from "./Components/General/GeneralComponent/useSelectorCustom";

const App = () => {
  let token = localStorage.getItem("tokenMiamed");
  const [isLoading, setIsLoading] = useState(true);
  const [notifyNumber, setNotifyNumber] = useState(null);
  const dispatch = useDispatch(); // Initialize dispatch
  const { notify, loadingNotify } = useSelectorCustom();

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000);
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(loadNotification()).then(() => {
  //       const currentNotifyNumber = notify.length;
  //       if (currentNotifyNumber > notifyNumber) {
  //         alert("New notifications available");
  //       }
  //       setNotifyNumber(currentNotifyNumber);
  //     });
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [dispatch]);

  const routes = useRout(token);
  return (
    <DndProvider backend={HTML5Backend}>
      <body>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {token != null && <AllLayout />}
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </>
        )}
      </body>
    </DndProvider>
  );
};

export default App;
