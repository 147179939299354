    import excel_icon from "../src/images/icons/excel.png";
import plus_icon from "../src/images/icons/plus.png";
import pointer_icon from "../src/images/icons/pointer.png";
import home from "./images/new side icon/home.png";
import target from "./images/new side icon/people.png";
import box from "./images/new side icon/cube.png";
import user from "./images/new side icon/user_mov.png";
import flow from "./images/new side icon/flow.png";
import medrep from "./images/new side icon/profile-user.png";
import plan from "./images/new side icon/cal.png";
import tree from "./images/icon_sidebar/tree.png";
import external from "./images/new side icon/external-link.png";
import loc from "./images/new side icon/pin.png";
import userset from "./images/new side icon/userr.png";
import set from "./images/new side icon/gear.png";
import assign from "./images/new side icon/assign.png";
import report from "./images/new side icon/profit-report.png";
import homewhite from "./images/icon_white/home.png";
import targetwhite from "./images/icon_white/people.png";
import boxwhite from "./images/icon_white/cube.png";
import userwhite from "./images/icon_white/user_mov.png";
import flowwhite from "./images/icon_white/flow.png";
import medrepwhite from "./images/icon_white/profile-user.png";
import planwhite from "./images/icon_white/cal.png";
import treewhite from "./images/icon_white/vacation_icon.svg";
import externalwhite from "./images/icon_white/external-link.png";
import locwhite from "./images/icon_white/pin.png";
import usersetwhite from "./images/icon_white/userr.png";
import setwhite from "./images/icon_white/gear.png";
import assignwhite from "./images/icon_white/assign.png";
import reportwhite from "./images/icon_white/profit-report.png";
import Politic from "./images/new side icon/privacy.png";
import PoliticWhit from "./images/icon_white/privacyWhite.png";
import request from "./images/new side icon/paper-plane.png";
import requestWhite from "./images/icon_white/paper-plane-white.png";

export const array_action = [
  {
    id: 1,
    icon: excel_icon,
    name: " Export Excel",
    name_ar: "تصدير إكسل",

  },
  {
    id: 2,
    icon: pointer_icon,
    name: "Production Lines",
    name_ar: "خطوط الانتاج",

  },
  {
    id: 3,
    icon: plus_icon,
    name: "Add Product",
    name_ar: "إضافة منتج",

  },
  {
    id: 4,
    icon: plus_icon,
    name: "Add Marketing Tools",
    name_ar: "إضافة أداة تسويق",
    type:'marketingTools'
  },
  {
    id: 5,
    icon: plus_icon,
    name: "Add New Target",
    name_ar: "إضافة فئة مستهدفة",
    type:'target'
  },
  {
    id: 6,
    icon: plus_icon,
    name: "Add User",
    name_ar: "إضافة  مستخدم",
    type:'user'
  },
  {
    id: 7,
    icon: plus_icon,
    name: "Add Medical Rep.",
    name_ar: "إضافة  مندوب",
    type:'delegate'
  },
  {
    id: 8,
    icon: plus_icon,
    name: "Add Work Plan",
    name_ar: "إضافة  خطة عمل",
    type:'workplan'
  },
];
export const sidebar_choise = [
  {
    id: 1,
    icon: home,
    icon_white: homewhite,
    name: "Dashboard",
    name_ar: "لوحة التحكم",
    link: "/",
  },
  {
    id: 2,
    icon: report,
    icon_white: reportwhite,
    name: "Reports",
    name_ar: "التقارير",
    link: "/reports",
  },
  {
    id: 3,
    icon: target,
    icon_white: targetwhite,
    name: "Target Information",
    name_ar: "بيانات الفئات المستهدفة",
    link: "/target-group",
  },
  {
    id: 4,
    icon: box,
    icon_white: boxwhite,
    name: "Products",
    name_ar: "المنتجات",
    link: "/product",
  },
  {
    id: 18,
    icon: box,
    icon_white: boxwhite,
    name: "Marketing Tools",
    name_ar: "أدوات التسويق",
    link: "/marketingTools",
  },
  {
    id: 5,
    icon: user,
    icon_white: userwhite,
    name: "Users",
    name_ar: "المستخدمين",
    link: "/users",
  },
  {
    id: 6,
    icon: flow,
    icon_white: flowwhite,
    name: "Work Flow",
    name_ar: "خطة سير العمل",
    link: "/work-flow",
  },
  {
    id: 7,
    icon: medrep,
    icon_white: medrepwhite,
    name: "Medical Representative",
    name_ar: "المندوبون",
    link: "/medical-representative",
  },
  {
    id: 8,
    icon: assign,
    icon_white: assignwhite,
    name: "Assign",
    name_ar: "إسناد",
    link: "/assign",
  },
  {
    id: 9,
    icon: plan,
    icon_white: planwhite,
    name: "Work Plans",
    name_ar: "خطط العمل",
    link: "/work-plans",
  },
  {
    id: 10,
    icon: tree,
    icon_white: treewhite,
    name: "Vacations",
    name_ar: "الإجازات",
    link: "/vacation",
  },
  {
    id: 11,
    icon: request,
    icon_white: requestWhite,
    name: "Request",
    name_ar: "طلب",
    link: "/request",
  },
  {
    id: 12,
    icon: external,
    icon_white: externalwhite,
    name: "Other Task",
    name_ar: "مهمات أخرى",
    link: "/external-visites",
  },
  {
    id: 13,
    icon: loc,
    icon_white: locwhite,
    name: "Country And City",
    name_ar: "المناطق والمدن",
    link: "/country",
  },
  {
    id: 14,
    icon: userset,
    icon_white: usersetwhite,
    name: "Roles",
    name_ar: "الأدوار",
    link: "/roles",
  },
  {
    id: 15,
    icon: Politic,
    icon_white: PoliticWhit,
    name: "Politics",
    name_ar: "السياسة",
    link: "/politics",
  },

  {
    id: 16,
    icon: set,
    icon_white: setwhite,
    name: "Settings",
    name_ar: "الاعدادات",
    link: "/settings",
  },
  {
    id: 17,
    icon: Politic,
    icon_white: PoliticWhit,
    name: "Target Orders",
    name_ar: "الطلبات المستهدفة",
    link: "/targetorders",
  },
];
