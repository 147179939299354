import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
  notifyErrorNetWork,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";

const getCities = (cities) => {
  return {
    type: types.GET_ALL_CITIES,
    payload: cities,
  };
};
const getCountries = (countriess) => {
  return {
    type: types.GET_ALL_COUNTRIES,
    payload: countriess,
  };
};
const addCountry = () => {
  return {
    type: types.ADD_COUNTRY,
  };
};
const addCityToCountry = () => {
  return {
    type: types.ADD_CITY_TO_COUNTRY,
  };
};
const delCountry = () => {
  return {
    type: types.DELETE_COUNTRY,
  };
};
const editCountry = () => {
  return {
    type: types.EDIT_COUNTRY,
  };
};
const delCity = () => {
  return {
    type: types.DELETE_CITY,
  };
};
const editCity = () => {
  return {
    type: types.EDIT_CITY,
  };
};

export const loadCities = () => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/countries`, {
        //by default damascus
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getCities(res?.data?.data));
      })
      .catch((err) => {
      });
  };
};
export const loadCitiesById = (id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/countries/${id }`, {
        //by default damascus
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getCities(res?.data?.data?.cities));
      })
      .catch((err) => {
      });
  };
};
export const loadCitiesbyCountry = (id, setloadcityy) => {
  return function (dispatch) {
    setloadcityy(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/countries/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getCities(res.data.data.cities));
        setloadcityy(false);
      })
      .catch((err) => {
        setloadcityy(false);
      });
  };
};

export const loadCountries = (setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/countries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getCountries(res.data.data));
      })
      .catch((err) => {
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};

export const postCountry = (name, setLoad,setOpen) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/countries`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addCountry());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadCountries());
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};
export const deleteCountry = (id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/countries/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delCountry());
          dispatch(loadCountries());
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        setdeletee(false);
        notifyError(err.response.data.message);
        setloadDel(false);
      });
  };
};
export const putCountry = (id, name, setLoad,setOpen) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .put(
        `${base_url}api/countries/${id}`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(editCountry());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadCountries());
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};
export const postCityToCountry = (name, country_id, setLoad, show_id,setOpen) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/cities`,
        {
          name,
          country_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addCityToCountry());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          if (show_id == null) {
            dispatch(loadCities());
          } else {
            dispatch(loadCitiesbyCountry(country_id, setLoad));
          }
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const deleteCity = (city_id, setdeletee, setloadDel, id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/cities/${city_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delCity());
          if (id == null) {
            dispatch(loadCities());
          } else {
            dispatch(loadCitiesbyCountry(id, setloadDel));
          }
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        setdeletee(false);
        notifyError(err.response.data.message);
        setloadDel(false);
      });
  };
};
export const putCity = (
  country_id1,
  id,
  name,
  country_id,
  setLoad,
  setcity_id
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .put(
        `${base_url}api/cities/${id}`,
        {
          name,
          country_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(editCity());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          if (country_id1 == null) {
            dispatch(loadCities());
          } else {
            dispatch(loadCitiesbyCountry(country_id1, setcity_id));
          }
          setLoad(false);
          setcity_id(null);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
        setcity_id(null);
      });
  };
};
