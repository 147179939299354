import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import { hasRout } from "../../Components/General/UseRout";

const getWorkPlanMar = (WorkPlanMarketing) => {
    return ({
        type: types.GET_WORK_PLAN_MARKETING,
        payload: WorkPlanMarketing
    })
}
const getUnassignMar = (Unassign_marketing) => {
    return ({
        type: types.GET_UNASSIGN_MARKETING,
        payload: Unassign_marketing
    })
}

const unassignWorkPlanMar = () => {
    return ({
        type: types.UNASSIGN_WORK_PLAN_MARKETING
    })
}

const assignWorkPlanMarr = () => {
    return ({
        type: types.ASSIGN_WORK_PLAN_MARKETING
    })
}
const emptyGeneral = (empty,typee) => {
    return ({
        type: typee,
        payload: empty
    })
}



export const loadWorkPlanMar = (id,navigate,setopenInternet) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/work-plan/${id}/marketing`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(getWorkPlanMar(res.data.data))
        }).catch((err) => {
            hasRout(err.response.status,navigate)
            // if(err.message == "Network Error" || err.message){
            //     setopenInternet(true)
            // }
        })
    }
}
export const loadUnassignMar = (id) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/work-plan/${id}/unassign-marketing`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(getUnassignMar(res.data.data))
        }).catch((err) => {
        })
    }
}

export const unassignWorkPlanMarr = (id,marketings,navigate,setopenInternet,setLoad) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        setLoad(true)
        axios.post(`${base_url}api/work-plan/${id}/unassign-marketing`,{
            marketings
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(unassignWorkPlanMar())
            dispatch(loadWorkPlanMar(id,navigate,setopenInternet))
            dispatch(loadUnassignMar(id))
            setLoad(false)
        }).catch((err) => {
            setLoad(false)
        })
    }
}

export const assignWorkPlanMar = (id,marketings,navigate,setopenInternet,setLoad) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        setLoad(true)
        axios.post(`${base_url}api/work-plan/${id}/assign-marketing`,{
            marketings
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(unassignWorkPlanMar())
            dispatch(loadWorkPlanMar(id,navigate,setopenInternet))
            dispatch(loadUnassignMar(id))
            setLoad(false)
        }).catch((err) => {
            setLoad(false)
        })
    }
}



export const emptyWorkPlanMar = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_WORK_PLAN_MARKETING ))
    }
}

export const emptyUnassignMar = () => {
    return function (dispatch) {
        let emptyy = []
        dispatch(emptyGeneral(emptyy , types.EMPTY_UNASSIGN_MARKETING ))
    }
}

