import * as types from "../ActionType";

const initState = {
    politics: {},
    loadingpolitics: true,
}

const PoliticsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.GET_POLITICS:
            return { ...state, politics: action.payload, loadingpolitics: false }
        case types.EDIT_PRIVACY:
            return { ...state, loadingpolitics: true }
        case types.EDIT_ABOUT:
            return { ...state, loadingpolitics: true }
        default:
            return state;
    }
};

export default PoliticsReducer;
