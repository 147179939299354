import * as types from "../ActionType";

const initState = {
    workplans: [],
    loadingWorkplans: true,
    onePlan: {},
    LoadingOnePlan: true
}

const WorkPlansReducer = (state = initState, action) => {
    switch (action.type) {
        case types.ADD_WORK_PLAN:
            return { ...state, loadingWorkplans: true }
        case types.GET_WORK_PLANS:
            return { ...state, workplans: action.payload, loadingWorkplans: false }
        case types.DELETE_WORK_PLAN:
            return { ...state, loadingWorkplans: true }
        case types.EDIT_WORK_PLAN:
            return { ...state, loadingWorkplans: true }
        case types.GET_ONE_PLAN:
            return { ...state, onePlan: action.payload, loadingWorkplans: false }
        case types.EMPTY_ONE_PLAN:
            return { ...state, onePlan: action.payload, loadingWorkplans: true }
        case types.REFRESH_WORK_PLANE:
            return { ...state,loadingWorkplans: true }
        default:
            return state;
    }
};

export default WorkPlansReducer;
