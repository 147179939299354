import React, { useEffect, useState } from 'react'
import Page_Header from '../../General/Page_Header';
import InnerLoader from '../../General/InnerLoader';
import Table2 from '../../Table2/Table2';
import { loadRoles } from '../../../Redux/Actions/ActionRoles';
import moment from 'moment';
import NoInternet from '../../General/NotPermission/NoInternet';
import { AbstructVar } from '../../General/GeneralComponent/AbstructVar';
import { AbstructLang } from '../../General/GeneralComponent/AbstructLang';
import { useSelectorCustom } from '../../General/GeneralComponent/useSelectorCustom';

function Roles() {
    const [openInternet, setopenInternet] = useState(false)
    let {dispatch,langmiamed,navigate} = AbstructVar()
    const {translate} = AbstructLang()
    const {roles,loadingRoles} = useSelectorCustom()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(loadRoles(navigate,setopenInternet))
    }, []);
    const gotoPer = (id,name) => {
        navigate(`/roles/${id}/${name}/permission`)
    }
    function replaceUnderscoreWithSpace(text) {
        return text.replace(/_/g, ' ');
      }
    const columns1 = [
        {
            name: <span className="color_spann">{translate('role')}</span>, sortable: true,
            cell: (row) => (
                <span>{replaceUnderscoreWithSpace(row['name'])}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('date')}</span>, sortable: true,
            cell: (row) => (
                <span>{moment(row['created_at']).format("YYYY-MM-DD")}</span>
            )
        },
        {
            name: <span className="color_spann">{translate('editper')}</span>,
            cell: (row) => (
                <i className="las la-edit icon_edit_table" onClick={() => gotoPer(row.id,row.name)} ></i>
            )
        }
    ]
    return (
        <>
            <div className={langmiamed == "ar" ? "main-content_ar" : "main-content_en"}>
                <main>
                    <Page_Header langmiamed={langmiamed} header={translate('roles')} path={`${translate('home')} / ${translate('roles')} `} />
                </main>
                <div className="page-content" >
                    <div className='users' dir={langmiamed == "ar" ? "rtl" : "ltr"} >
                        {
                            loadingRoles == false ? (
                                <Table2 col={columns1} roo={roles} />
                            ) : <div className='div_loadd' ><InnerLoader /></div>
                        }
                    </div>
                </div>
            </div>
            {
                openInternet && (
                    <NoInternet open={openInternet} setOpen={setopenInternet} />
                )
            }
        </>
    )
}

export default Roles