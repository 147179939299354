import React from "react";
import Table2 from "../Table2/Table2";
import select from "../../images/icons/pointer.png";
import External_In_Map from "../External_Visites/External_In_Map";
import FilterStatistics from "../Main/Statistics/FilterStatistics";
import { useSelectorCustom } from "../General/GeneralComponent/useSelectorCustom";
import {  ColumnArrayReportRequests, VariableReports } from "./VariableReports";

function OrdersReqsReport({ langmiamed, t, navigate, setopenInternet }) {
  const { reportOrdersReqs, loagingreportOrdersReqs } = useSelectorCustom();
  const reversedgeneralExReport = reportOrdersReqs?.reverse();
  const { state, storeMap, setIsOpen } = VariableReports();
  const { OrdersReqsColumn } = ColumnArrayReportRequests(t, storeMap);
  return (
    <>
      <FilterStatistics
        t={t}
        langmiamed={langmiamed}
        params="monthly_orders_request_report"
        excel="monthly_orders_request_report"
      />
      <div className="users" dir={langmiamed == "ar" ? "ltr" : "ltr"}>
        {loagingreportOrdersReqs == false ? (
          <Table2 col={OrdersReqsColumn} roo={reversedgeneralExReport} />
        ) : (
          <div className="alternative">
            <img src={select} />
            <br></br>
            <p>{t("filterr")}</p>
          </div>
        )}
      </div>
      {state.openMap && (
        <External_In_Map
          open={state.openMap}
          setOpen={setIsOpen}
          lat={state.lat}
          lng={state.lng}
        />
      )}
    </>
  );
}

export default OrdersReqsReport;
