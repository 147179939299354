import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess,notifyErrorNetWork } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";

const getGovernorates = (governorates) => {
    return ({
        type: types.GET_Governorate,
        payload: governorates
    })
}


export const loadGovernorates = (id) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/countries/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(getGovernorates(res.data.data.cities))
        }).catch((err) => {
        })
    }
}
