import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "./Table2.css";
import NoData from "../General/NoData/NoData";

function Table2({ border, col, roo }) {
  

  const handleSelectedRowsChange = () => {};

  const customStyles = {
    table: {
      style: {
        backgroundColor: 'white',
      },
    },
    rows: {
      style: {
        backgroundColor: 'white',
        color: 'gray',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    headCells: {
      style: {
        backgroundColor: 'white',
        color: '#606280',
        fontSize: '13px',
      },
    },
    pagination: {
      style: localStorage.getItem("langmiamed") === 'en'
        ? {
            backgroundColor: 'white',
            color: 'var(--title-color)',
            borderTop: '1px solid white',
            direction: 'ltr !important',
          }
        : {
            backgroundColor: 'white',
            color: 'var(--title-color)',
            borderTop: '1px solid white',
            direction: 'rtl !important',
          },
    },
  };

  const paginationOptionsAr = {
    rowsPerPageText: "عدد الصفوف في كل صفحة:",
    rangeSeparatorText: "من",
    selectAllRowsItem: true,
    selectAllRowsItemText: "اختر الكل",
  };

  const paginationOptionsEn = {
    rowsPerPageText: "Rows per pages:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "all",
  };

  return (
    <div className="table2">
      <DataTable
        columns={col}
        data={roo}
        pagination
        paginationComponentOptions={localStorage.getItem("langmiamed") === "en" ? paginationOptionsEn : paginationOptionsAr}
        onSelectedRowsChange={handleSelectedRowsChange}
        customStyles={customStyles}
        noDataComponent={<NoData />}
        className={border ? "custom-table-border" : "custom-table"}
        noHeader
        paginationPerPage={9}
      />
    </div>
  );
}

export default Table2;
