import * as types from "../ActionType";

const initState = {
    taskss: [],
    loadingTasks: true,
    taskVisites: [],
    loadingTaskVisites: true,
    oneTask: {},
    loadingOneTask: true
}

const TaskReducer = (state = initState, action) => {
    switch (action.type) {
        case types.ADD_TASK:
            return { ...state, loadingWorkplans: true }
        case types.GET_TASKS:
            return { ...state, taskss: action.payload, loadingTasks: false }
        case types.EMPTY_TASK:
            return { ...state, taskss: action.payload, loadingTasks: true }
        case types.DELETE_TASK:
            return { ...state, loadingWorkplans: true }
        case types.EDIT_TASK:
            return { ...state, loadingWorkplans: true }
        case types.GET_TASK_VISITES:
            return { ...state, taskVisites: action.payload, loadingTaskVisites: false }
        case types.EMPTY_TASK_VISITES:
            return { ...state, taskVisites: action.payload, loadingTaskVisites: true }
        case types.ASSIGN_TASK_TO_DATE:
            return { ...state, }
        case types.GET_ONE_TASK:
            return { ...state, oneTask: action.payload, loadingOneTask: false }
        case types.EMTY_ONE_TASK:
            return { ...state, oneTask: action.payload, loadingOneTask: true }
        default:
            return state;
    }
};

export default TaskReducer;
