import React, { useState } from 'react'
import InnerLoader from '../../General/InnerLoader'
import ViewClassBySpec from '../ViewClassbySpec'
import { CheckFont } from '../../General/GeneralComponent/CheckLang'

function SpecializationsCard({ specializations, loadingspecializations, translate, langmiamed }) {
    const [specId, setSpecId] = useState('')
    const [openClass, setClass] = useState(false)
    const viewClass = (id) => {
        setSpecId(id)
        setClass(true)
    }
    return (
        <>
            <div className={`set1 ${langmiamed == 'ar' ? 'margin_left_50' : "margin_right_50"}`} >
                <div className={`div_tit_set ${langmiamed === 'ar' ? "textRight" : ""}`} ><h5 className={langmiamed == 'ar' ? "textRight" : ""}>{translate('specializations')}</h5></div>
                {
                    loadingspecializations == false ? (
                        specializations.slice().reverse().map((cc) => {
                            return (
                                <div className='spec_class' >
                                    <div className='setting_icon' >
                                        <i className={`las la-caret-square-right ${langmiamed == 'ar' ? "margin_left_10" : "margin_right_10"} `}></i>
                                        <span className='name_spec' style={{fontFamily: CheckFont(cc.name)}} >{cc.name}</span>
                                    </div>
                                    <span className={`seespec ${langmiamed == 'ar' ? "textRight" : ""} `} onClick={() => viewClass(cc.id)} >{translate('See_classifications')}</span>
                                </div>
                            )
                        })
                    ) : <div className='loadSetting' ><InnerLoader /></div>
                }

            </div>
            {
                openClass && (
                    <ViewClassBySpec specId={specId} open={openClass} setOpen={setClass} />
                )
            }
        </>
    )
}

export default SpecializationsCard