import * as types from "../ActionType";
import axios from "axios";
import { notifyError, notifysuccess } from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import moment from 'moment';
import { hasRout } from "../../Components/General/UseRout";

const getvacation = (Vacation) => {
    return ({
        type: types.GET_VACATION,
        payload: Vacation
    })
}
const editvacation = () => {
    return ({
        type: types.EDIT_VACATION
    })
}

const editvacationInfo = () => {
    return ({
        type: types.EDIT_VACATION_INFORMATION
    })
}

export const loadVacation = (navigate, setopenInternet) => {
    return function (dispatch) {
        let token = localStorage.getItem("tokenMiamed");
        axios.get(`${base_url}api/vacations`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(getvacation(res.data.data))
        }).catch((err) => {
            hasRout(err.response.status, navigate)
            if (err.message == "Network Error" || err.message) {
                setopenInternet(true);
            }
        })
    }
}

export const statusVacation = (id, status, setLoad) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        axios.post(`${base_url}api/vacations/status/${id}`, {
            status
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(editvacation());
            if (res.data.status == "Success") {
                notifysuccess(res.data.message);
                dispatch(loadVacation())
                setLoad(false)
            }
        }).catch((err) => {
            notifyError(err.response.data.message)
            setLoad(false)
        })
    }
}

export const loadFilterVacation = (medical_rep_id, start_date1, end_date1, type, setLoad) => {
    return function (dispatch) {
        if (start_date1 == undefined || end_date1 == undefined) {
            setLoad(true)
            let token = localStorage.getItem("tokenMiamed");
            axios.get(`${base_url}api/vacations?filter[medical_rep_id]=${medical_rep_id}&filter[type]=${type}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res) => {
                    dispatch(getvacation(res.data.data))
                setLoad(false)
            }).catch((err) => {
                    setLoad(false)
            })
        } else {
            let start_date = moment(start_date1).format('YYYY-MM-DD')
            let end_date = moment(end_date1).format('YYYY-MM-DD')
          
            setLoad(true)
            let token = localStorage.getItem("tokenMiamed");
            axios.get(`${base_url}api/vacations?filter[medical_rep_id]=${medical_rep_id}&filter[start_date]=${start_date}&filter[end_date]=${end_date}&filter[type]=${type}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res) => {
                    dispatch(getvacation(res.data.data))
                setLoad(false)
            }).catch((err) => {
                    setLoad(false)
            })
        }

    }
}

export const putVacation = (id, start, end, type, reason, medical_rep_id, setLoad,setOpen) => {
    return function (dispatch) {
        setLoad(true)
        let token = localStorage.getItem("tokenMiamed");
        if (end === null) {
            axios.put(`${base_url}api/vacations/${id}`, {
                start, type, reason, medical_rep_id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res) => {
                    dispatch(editvacationInfo());
                if (res.data.status == "Success") {
                    notifysuccess(res.data.message);
                    dispatch(loadVacation())
                    setLoad(false)
                    setOpen(false)
                }
            }).catch((err) => {
                    notifyError(err.response.data.message)
                setLoad(false)
            })
        } else {
            axios.put(`${base_url}api/vacations/${id}`, {
                start, end, type, reason, medical_rep_id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res) => {
                    dispatch(editvacationInfo());
                if (res.data.status == "Success") {
                    notifysuccess(res.data.message);
                    dispatch(loadVacation())
                    setLoad(false)
                }
            }).catch((err) => {
                    notifyError(err.response.data.message)
                setLoad(false)
            })
        }
    }
}