import * as types from "../ActionType";
import axios from "axios";
import {
  notifyError,
  notifysuccess,
} from "../../Components/General/Notification";
import { base_url } from "../../Components/General/Notification";
import moment from "moment";
import { hasRout } from "../../Components/General/UseRout";

const addTask = () => {
  return {
    type: types.ADD_TASK,
  };
};
const getTask = (taskss) => {
  return {
    type: types.GET_TASKS,
    payload: taskss,
  };
};
const getOneTask = (oneTask) => {
  return {
    type: types.GET_ONE_TASK,
    payload: oneTask,
  };
};
const delTask = () => {
  return {
    type: types.DELETE_TASK,
  };
};
const editTask = () => {
  return {
    type: types.EDIT_TASK,
  };
};
const emptyGeneral = (empty, typee) => {
  return {
    type: typee,
    payload: empty,
  };
};
const getTaskVisites = (taskVisites) => {
  return {
    type: types.GET_TASK_VISITES,
    payload: taskVisites,
  };
};
const assignTask = () => {
  return {
    type: types.ASSIGN_TASK_TO_DATE,
  };
};
export const loadTask = (id, navigate, setopenInternet) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/work-plan/${id}/tasks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getTask(res.data.data));
      })
      .catch((err) => {
        hasRout(err.response.status, navigate);
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};
export const emptyTask = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_TASK));
  };
};

export const postTask = (
  work_plan_id,
  note,
  priority_level,
  time,
  date,
  status,
  target_group_id,
  setLoad,
  setOpen,
  setState,
  state
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .post(
        `${base_url}api/plan-tasks`,
        {
          work_plan_id,
          note,
          priority_level,
          time,
          date,
          status,
          target_group_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(addTask());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadTask(work_plan_id));
          setLoad(false);
          console.log("stateaftersub" , state);
          console.log("stateaftersub" , state);
          setState({
            ...state,
            note:'',
            time:'',
            priority_level:'',
            status:"",
            target_group_id:''
          })
          

       
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
        setState({
          ...state,
          note:''
        })
      });
  };
};
export const deleteTask = (id, work_plan_id, setdeletee, setloadDel) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    setloadDel(true);
    axios
      .delete(`${base_url}api/plan-tasks/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(delTask());
          dispatch(loadTask(work_plan_id));
          setdeletee(false);
          setloadDel(false);
        }
      })
      .catch((err) => {
        setdeletee(false);
        setloadDel(false);
        notifyError(err.response.data.message);
      });
  };
};

export const putTask = (
  task_id,
  work_plan_id,
  note,
  priority_level,
  time,
  date,
  status,
  target_group_id,
  setLoad,
  setOpen
) => {
  return function (dispatch) {
    setLoad(true);
  
    let token = localStorage.getItem("tokenMiamed");
    axios
      .put(
        `${base_url}api/plan-tasks/${task_id}`,
        {
          work_plan_id,
          note,
          priority_level,
          time,
          date,
          status,
          target_group_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(editTask());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
          dispatch(loadTask(work_plan_id));
          setLoad(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setLoad(false);
      });
  };
};

export const loadFilterTask = (
  id,
  priority_level,
  target_group_id,
  status,
  setLoad
) => {
  return function (dispatch) {
    setLoad(true);
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(
        `${base_url}api/work-plan/${id}/tasks?filter[priority_level]=${priority_level}&filter[target_group_id]=${target_group_id}&filter[status]=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(getTask(res.data.data));
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };
};

export const loadTaskVisites = (id, setopenInternet) => {
  return function (dispatch) {

    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/plan-task/${id}/visits`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getTaskVisites(res.data.data));
      })
      .catch((err) => {
        if (err.message == "Network Error" || err.message) {
          setopenInternet(true);
        }
      });
  };
};
export const emptyTaskVisites = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMPTY_TASK_VISITES));
  };
};

export const assignTaskToDate = (id, date, work_plan_id) => {
  return function (dispatch) {

    let token = localStorage.getItem("tokenMiamed");
    axios
      .put(
        `${base_url}api/plan-tasks/assignTaskToDate/${id}`,
        {
          date,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(assignTask());
        if (res.data.status == "Success") {
          notifysuccess(res.data.message);
        }
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        if (err.response.data.status == "Error") {
          dispatch(loadTask(work_plan_id));
        }
      });
  };
};

export const loadOneTask = (id) => {
  return function (dispatch) {
    let token = localStorage.getItem("tokenMiamed");
    axios
      .get(`${base_url}api/plan-tasks/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getOneTask(res.data.data));
      })
      .catch((err) => {
      });
  };
};
export const emptyOneTask = () => {
  return function (dispatch) {
    let emptyy = [];
    dispatch(emptyGeneral(emptyy, types.EMTY_ONE_TASK));
  };
};
