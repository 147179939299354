import React, { useState, useEffect } from "react";
import { Modal as RModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch } from "react-redux";
import SearchSelect from "../../General/SelectSearch";
import { putTask } from "../../../Redux/Actions/ActionTask";
import { useSelectorCustom } from "../../General/GeneralComponent/useSelectorCustom";
import { VariableTask } from "./VariableTask";
import { FunctionsLoading } from "../../Main/Statistics/FunctionsLoading";
import SelectWithSearch from "../../General/GeneralComponent/SelectWithSearch";

function EditTask({ medical_rep_id, t, langmiamed, arr, open, setOpen }) {
  let dispatch = useDispatch();
  const { TGbymedicalRep, loadingTG } = useSelectorCustom();
  const { state, setState, handleChangeTask, setLoad } = VariableTask();
  const { LoadTargetByMedicalRepCustom } = FunctionsLoading();
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      date: arr.date,
      time: arr.time.replace(/\s*([AaPp][Mm])\s*/, ""),
      priority_level: arr.priority_level,
      status: arr.status,
      target_group_id: arr.target_group_id,
      target_group_name: arr.target_group_name,
      note: arr.note,
    }));
  }, []);
  const submit = (e) => {
    e.preventDefault();
    dispatch(
      putTask(
        arr.id,
        arr.work_plan_id,
        state.note,
        state.priority_level,
        state.time,
        state.date,
        state.status,
        state.target_group_id,
        setLoad,
        setOpen
      )
    );
  };
  return (
    <RModal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      center
      classNames={{
        modal: "addPop",
      }}
      focusTrapped={false}
    >
      <div className="div_addPop" dir={langmiamed == "ar" ? "rtl" : "ltr"}>
        <h3 className={langmiamed == "ar" ? "textRight" : ""}>
          {t("editTask")}
        </h3>
        <div className={langmiamed == "ar" ? "textRight" : ""}>
          <span>{t("time")}</span>
        </div>
        <input
          value={state.time}
          type="time"
          onChange={(e) => handleChangeTask(e.target.value, "time")}
        />
        <div className={langmiamed == "ar" ? "textRight" : ""}>
          <span>{t("priority")}: </span>
        </div>
        <select
          value={state.priority_level}
          onChange={(e) => handleChangeTask(e.target.value, "priority_level")}
        >
          <option disabled="disabled" selected="selected">
            Priority
          </option>
          <option value="high">{t("high")}</option>
          <option value="medium">{t("Medium")}</option>
          <option value="low">{t("Medium")}</option>
        </select>
        <div className={langmiamed == "ar" ? "textRight" : ""}>
          {t("target_group")}:{" "}
        </div>
        <div style={{ marginLeft: "10px" }}>
          <SelectWithSearch
            load={loadingTG}
            array={TGbymedicalRep}
            setId={(value) => handleChangeTask(value, "target_group_id")}
            func={LoadTargetByMedicalRepCustom}
            loadID={medical_rep_id}
            left={true}
            typeCss={true}
            previousID={state.target_group_id}
            name={state.target_group_name}
          />
        </div>
        <div className={langmiamed == "ar" ? "textRight" : ""}>
          {t("note")}:{" "}
        </div>
        <textarea
          value={state.note}
          onChange={(e) => handleChangeTask(e.target.value, "note")}
        />
        <div className={langmiamed == "ar" ? "textRight" : ""}>
          {t("status")}:{" "}
        </div>
        <select
          value={state.status}
          onChange={(e) => handleChangeTask(e.target.value, "status")}
        >
          <option disabled="disabled" selected="selected">
            {t("status")}
          </option>
          <option value="is_done">{t("done")}</option>
          <option value="not_done">{t("notdone")}</option>
        </select>
        <button disabled={state.Load} onClick={submit}>
          {state.Load ? t("Loading ...") : `${t("edit")}`}
        </button>
      </div>
    </RModal>
  );
}

export default EditTask;
